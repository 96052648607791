import { Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Wrapper = styled.div`
  scrollbar-width: auto;
`;

export const TopSection = styled(Stack).attrs({
  justifyContent: "flex-end",
  flex: "1",
  pr: "M",
})`
  position: relative;
  width: 100%;
  max-height: ${Spacing.S}px;
  top: ${Spacing.S}px;
`;

export const Content = styled(Stack).attrs({
  direction: "column",
  alignItems: "center",
})`
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: auto;
  height: calc(100vh - 214px);
  padding-bottom: 160px;
  background-color: ${Palette.Navy750};
`;
