import { Box, Button, LoadingIndicator, Stack, Text, Title } from "@secuis/ccp-react-components";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Content } from "../styled";
import { SliderSettings } from "./SliderSettings";
import { Card, CustomSeparator, LoadingWrapper, SettingsContent } from "./styled";
import { NotificationTypes } from "../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../context/notifications/NotificationsContext";
import { AppsEnum, IntercomTopic, useIntercom } from "../../hooks/useIntercom";
import { useTagsAndRegions } from "../../hooks/useTagsAndRegions";
import { useUserData } from "../../hooks/useUserData";
import { updatePrefs } from "../../utilities/userUtils";
import { DropdownSettings } from "./DropdownSettings";
import { useRoles } from "../../hooks/useRoles";
import { useReportTypes } from "../../hooks/useReportTypes";
import { LanguageDropdown } from "./LanguageDropdown";
import { LANGUAGE_KEY } from "../../constants/generalSettings";

export interface SettingsValue {
  value?: string;
  type?: string;
}

export const Settings = () => {
  const { t } = useTranslation();
  const { toast } = useContext(NotificationsContext);
  const { reportsTypes } = useReportTypes();
  const { initRegions } = useTagsAndRegions();
  const { postMessage } = useIntercom();
  const { userToken, userId, userData, preferences, setPreferences, setLang } = useUserData();
  const [shouldShowUserManagementLink, setShouldShowUserManagementLink] = useState(false);
  const { shouldShowEmails, isAdmin } = useRoles();

  const updatePreferences = async (key: string, content: SettingsValue, value: string | boolean) => {
    try {
      setPreferences({
        ...preferences,
        [key]: value,
      });

      await updatePrefs(userId, userToken, content, key);

      if (key === LANGUAGE_KEY) {
        setLang(value.toString());
        toast({
          title: t("toast.languageChanged.title"),
          message: t("toast.languageChanged.description"),
          type: NotificationTypes.Confirmation,
          icon: "Success",
        });
      } else {
        toast({ title: t("settings.notification.success"), type: NotificationTypes.Confirmation, icon: "Success" });
      }
    } catch (e) {
      toast({ title: t("settings.notification.failed"), type: NotificationTypes.Error, icon: "Error" });
    }
  };

  useEffect(() => {
    if (userData.roles?.length > 0) {
      const isEmbed = userData.isEmbedded || userData.isEmbeddedInMobile;
      setShouldShowUserManagementLink(isAdmin && isEmbed);
    }
  }, [userData]);

  return (
    <Content>
      <Title>{t("Settings.title")}</Title>
      <Text>{t("Settings.subtitle")}</Text>
      {preferences ? (
        <SettingsContent>
          <div>
            <Card>
              <Box pb="S">
                <Text uppercase micro color="secondary">
                  {t("Settings.notification.column.title")}
                </Text>
              </Box>
              <Text small>{t("Settings.notification.column.subdescription")}</Text>

              {reportsTypes?.map((elem) => (
                <>
                  <CustomSeparator />
                  <SliderSettings
                    title={`reports.${elem}.title`}
                    description={`reports.${elem}.description`}
                    key={`ric.bronze.reports.${elem}`}
                    elemKey={`ric.bronze.reports.${elem}`}
                    valueEmail={preferences[`ric.bronze.reports.${elem}.email`]}
                    valuePush={preferences[`ric.bronze.reports.${elem}.inapppush`]}
                    updatePreferences={updatePreferences}
                    shouldShowEmails={shouldShowEmails}
                  ></SliderSettings>
                </>
              ))}
            </Card>
          </div>
          <div>
            <Card>
              <Box pb="S">
                <Text uppercase micro color="secondary">
                  {t("Settings.specialNotification.column.title")}
                </Text>
              </Box>
              <Text small>{t("Settings.specialNotification.column.subdescription")}</Text>

              <Stack direction="column" gap="M" pt="S">
                {initRegions.map((elem) => (
                  <DropdownSettings key={`dropdown-${elem.region}`} title={elem.region} preferences={preferences} updatePreferences={updatePreferences} />
                ))}
              </Stack>
            </Card>
          </div>
          <div>
            <Card>
              <Box pb="S">
                <Text uppercase micro color="secondary">
                  {t("Settings.otherSettings.column.LanguageTitle")}
                </Text>
              </Box>
              <Text small bold>
                {t("Settings.otherSettings.language")}
              </Text>
              <Text small>{t("Settings.otherSettings.description")}</Text>

              <LanguageDropdown updatePreferences={updatePreferences} key={LANGUAGE_KEY} keyName={LANGUAGE_KEY} initValue={preferences[LANGUAGE_KEY]} />

              {shouldShowUserManagementLink && (
                <>
                  <CustomSeparator />
                  <Box pt="XS" pb="S">
                    <Text small bold>
                      {t("Settings.otherSettings.column.OtherTitle")}
                    </Text>

                    <Text small>{t("Settings.otherSettings.userManagment")}</Text>
                  </Box>
                  <Button
                    mode="contained"
                    onClick={() => {
                      postMessage(IntercomTopic.REDIRECT, AppsEnum.UserManagement);
                    }}
                  >
                    {t("Settings.button")}
                  </Button>
                </>
              )}
            </Card>
          </div>
        </SettingsContent>
      ) : (
        <LoadingWrapper>
          <LoadingIndicator size="L" />
        </LoadingWrapper>
      )}
    </Content>
  );
};
