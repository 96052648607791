import React, { useEffect } from "react";
import SeenIcon from "../../../../assets/icons/SeenIcon";
import { useUserData } from "../../../../hooks/useUserData";
import { useAppSelector } from "../../../../store";
import { selectReportUserInteractions } from "../../../../store/reports/ReportsSelectors";
import { Wrapper } from "./SeenItem.styles";
import { useSetInteractionsMutation } from "../../../../store/user/UserApi";

export const SeenItem = ({ report, selected }) => {
  const { userId } = useUserData();
  const { viewed, flagged } = useAppSelector(selectReportUserInteractions(report.report_id))
  const [setInteractions] = useSetInteractionsMutation();

  useEffect(() => {
    if (!viewed && selected && userId) {
      setInteractions({ userId, entity_id: report.report_id, entity_type: 'report', viewed: true, flagged });
    }
  }, [userId, selected, report.report_id, viewed, flagged]);

  return (
    <Wrapper>
      <SeenIcon isSeen={viewed} />
    </Wrapper>
  );
};