import { ButtonIcon, Spacing, Theme } from "@secuis/ccp-react-components";
import styled from "styled-components";
import { Wrapper } from "./ImageUpload.styles";

export const RelativeWrapper = styled(Wrapper)`
  position: relative;
  height: fit-content;
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const DeleteButton = styled(ButtonIcon)`
  position: absolute;
  top: ${Spacing.M}px;
  right: ${Spacing.M}px;

  background-color: ${Theme.error};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${Theme.hover.error};
    }
  }

  :active {
    background-color: ${Theme.pressed.error};
  }
`;
