import { useEffect, useRef } from "react";

interface UseIntervalProps {
  (callback: () => void, delay: number): void;
}

export const useInterval: UseIntervalProps = (callback, delay) => {
  const savedCallback = useRef<(() => void) | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const tick = () => {
    if (savedCallback.current) {
      savedCallback.current();
    }
  };

  useEffect(() => {
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
