import { Icon, Stack, Text, Tooltip } from "@secuis/ccp-react-components";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { selectIsDraft } from "../../../store/item/ItemSelectors";
import { ItemSaveStatusWrapper } from "./styled";

export const ItemSaveStatusIndicator = () => {
  const { t } = useTranslation();
  const { itemId } = useParams();
  const isDraft = useAppSelector(selectIsDraft);
  const {
    formState: { isDirty: isFormDirty },
  } = useFormContext();
  const notCreated = !itemId;

  return (
    <ItemSaveStatusWrapper>
      <Stack gap="M">
        {notCreated ? (
          <Tooltip message={t("Item.fillDateAndTitle")} place="bottom">
            <Stack gap="XS">
              <Icon size="L" variant="Info" />
              <Text>{t("Item.notCreated")}</Text>
            </Stack>
          </Tooltip>
        ) : (
          <>
            {isDraft && (
              <Tooltip message={t("Item.fillRequiredFields")} place="bottom">
                <Stack gap="XS">
                  <Icon size="L" variant="Info" />
                  <Text uppercase>{t("Item.draft")}</Text>
                </Stack>
              </Tooltip>
            )}
            {isFormDirty ? (
              <>
                <Text> {t("Item.editing")}</Text>
                <Icon size="L" variant="StatusInProgress" />
              </>
            ) : (
              <>
                <Text> {t("Item.saved")}</Text>
                <Icon color="success" size="L" variant="StatusDone" />
              </>
            )}
          </>
        )}
      </Stack>
    </ItemSaveStatusWrapper>
  );
};
