import { Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const LocationWrapper = styled(Stack).attrs({
  gap: "M",
})`
  div {
    width: 100%;
    div {
      width: auto;
      flex: none;
    }
  }
`;
