import React, { useState } from "react";
import { Breakpoints, Button, ButtonCompact, Segment, SegmentedControl, Stack, Title, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectItems, selectItemsCollection, selectViewMode } from "../../../store/items/ItemsSelectors";
import { itemsActions } from "../../../store/items/ItemsSlice";
import { Buttons, Header } from "./ItemsListHeader.styles";
import { FilterMobileButton } from "../../Navigation/Filter/content/FilterMobileButton";
import { ItemsFiltersContent } from "../../Navigation/Filter/content/ItemsFiltersContent";

export const ItemsListHeader = ({ openCollectItemsModal, openExportModal, fetchItems }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const viewMode = useAppSelector(selectViewMode);
  const items = useAppSelector(selectItems);
  const collectedItems = useAppSelector(selectItemsCollection);
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const [toggleFilters, setToggleFilters] = useState(false);

  const onChangeViewMode = (value) => {
    dispatch(itemsActions.setViewMode(value));
  }

  return (
    <Header>
      <Stack mb={ isMobile ? "0": "XXS" }>
        {isMobile && (
          <>
            <FilterMobileButton isExpanded={toggleFilters} toggleExpanded={setToggleFilters} />
            <ItemsFiltersContent isExpanded={toggleFilters} toggleExpanded={setToggleFilters} />
          </>
        )}
        <Stack flex={1}>
          <Title>{t("Items.listTitle")}</Title>
        </Stack>
        {!isMobile && <Buttons>
          <Button
            mode="contained"
            onClick={openCollectItemsModal}
            fitContent
            disabled={!collectedItems.length}
          >
            {t("Items.collection.button.title", { itemsLength: collectedItems.length })}
          </Button>
          <Button
            data-testid="exportItems"
            mode="contained"
            onClick={openExportModal}
            icon="Export"
            fitContent
            disabled={!items.length}
          >
            {t("Items.exportItems")}
          </Button>
          <Button
            data-testid="createItem"
            mode="contained"
            onClick={() => {
              navigate("/createItem", { replace: true });
            }}
            icon="Plus"
            fitContent
          >
            {t("Items.createNewItem")}
          </Button>
        </Buttons>}
      </Stack>
      <Stack alignItems="center" justifyContent="space-between" flex="1" gap="S" pt="S">
        <ButtonCompact mode="outlined" onClick={fetchItems} icon="Reload">
          {t("reports.list.refresh")}
        </ButtonCompact>
        {items.length > 0 && (
          <SegmentedControl fitContent onChange={onChangeViewMode} value={viewMode}>
            <Segment title={t("Items.viewMode.longList")} icon="AppSwitcher" />
            <Segment title={t("Items.viewMode.shortList")} icon="ShortView" />
            <Segment title={t("Items.viewMode.map")} icon="Location" />
          </SegmentedControl>
        )}
      </Stack>
    </Header>
  );
}