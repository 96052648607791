import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 256px;

    .mapboxgl-ctrl-bottom-left,
    .mapboxgl-ctrl-bottom-right {
        display: none;
    }
`;