import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef } from "react";
import { Item } from "../../../models/ItemModel";
import { locationMarkerSrc } from "./Assets";
import { Container } from "./ItemMap.styles";
import { ITEMS_SOURCE, LOCATION_MARKER, mapToGeoJson } from "./Map.helpers";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

interface ItemMapProps {
  item: Item;
}

const ItemMap: React.FC<ItemMapProps> = ({ item }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) return; // initialize map only once
    const locationMarkerImage = document.createElement("img");
    locationMarkerImage.src = locationMarkerSrc;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/guarding-map-feature/cliit6t6q00au01qv7jg1hhyz",
      center: [item.geolocation.longitude, item.geolocation.latitude],
      zoom: 6,
      projection: "mercator",
    });

    mapRef.current.on("load", () => {
      if (!mapRef.current?.getSource(ITEMS_SOURCE)) {
        mapRef.current?.addImage(LOCATION_MARKER, locationMarkerImage);

        mapRef.current?.addSource(ITEMS_SOURCE, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: mapToGeoJson([item]),
          },
        });

        mapRef.current?.addLayer({
          id: "markers",
          type: "symbol",
          source: ITEMS_SOURCE,
          layout: {
            "icon-image": LOCATION_MARKER,
          },
        });
      }
    });

    return () => {
      mapRef.current?.remove();
      mapRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Container ref={mapContainerRef} />;
};

export default ItemMap;
