import { Text, Toggle, Tooltip } from "@secuis/ccp-react-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NotificationsContext } from "../../../../context/notifications/NotificationsContext";
import { useUserData } from "../../../../hooks/useUserData";
import { updateSection } from "../../../../utilities/userUtils";
import { ToggleReadyWrapper } from "./styled";
import { NotificationTypes } from "../../../../context/notifications/NotificationMessage";

const SectionToggle = ({ isChanged, setIsReady, isReady, elemSectionId, savedReport, noTags }) => {
  const { t } = useTranslation();
  const { userToken } = useUserData();
  const { toast } = useContext(NotificationsContext);

  const getContent = () => {
    return (
        <Toggle
          data-testid="markSectionAsReady"
          disabled={isChanged || noTags}
          id="markApproved"
          checked={isReady}
          onClick={function noRefCheck() {}}
          onChange={async () => {
            try {
              await updateSection(userToken, savedReport, elemSectionId, { is_done: !isReady });
              setIsReady(!isReady);
              toast({
                title: !isReady ? t("Section.tootlip.readySection") : t("Section.tootlip.notReadySection"),
                type: NotificationTypes.Confirmation,
                icon: "Success",
              });
            } catch (e) {
              toast({ title: t("Section.tootlip.updateFailed"), type: NotificationTypes.Error, icon: "Error" });
            }
          }}
        />
    )
  }
  return <ToggleReadyWrapper>{
    noTags ? 
      <Tooltip message={t("CreateReport.tooltipSelectTags")} place="top-start">{
        getContent()
      }</Tooltip>
    : 
      getContent()
      }
    <Text>{t("Section.markAsReady")}</Text>
  </ToggleReadyWrapper>;
};

export default SectionToggle;
