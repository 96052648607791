import { Breakpoints, Title, useHasMaxWidth } from "@secuis/ccp-react-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRoles } from "../../../hooks/useRoles";
import { FilterMobileButton } from "../../Navigation/Filter/content/FilterMobileButton";
import { FiltersContent } from "../../Navigation/Filter/content/FiltersContent";
import { CreateReportDropdown } from "../Create/CreateReportDropdown";
import { ReportsList } from "./ReportsList";
import { useReportTypes } from "../../../hooks/useReportTypes";
import { TitleWrapper, Wrapper } from "./Reports.styles";

export const Reports = () => {
  const { t } = useTranslation();
  const { couldCreateReport } = useRoles();
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const [toggleFilters, setToggleFilters] = useState(false);
  const { reportsTypes } = useReportTypes();

  return (
    <Wrapper>
      {isMobile && reportsTypes && (
        <>
          <FilterMobileButton isExpanded={toggleFilters} toggleExpanded={setToggleFilters} data-testid="FilterMobileButton" />
          <FiltersContent isExpanded={toggleFilters} toggleExpanded={setToggleFilters} data-testid="FilterMobileButton" />
        </>
      )}
      <TitleWrapper>
        <Title>{t("Reports.title")}</Title>
        {couldCreateReport && !isMobile && (
          <div>
            <CreateReportDropdown />
          </div>
        )}
      </TitleWrapper>
      {reportsTypes && <ReportsList couldCreateReport={couldCreateReport} />}
    </Wrapper>
  );
};
