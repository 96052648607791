import { Background, Theme } from "@secuis/ccp-react-components";

const locationMarkerSvg = `
<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8 2C9.9 2 5 6.8 5 12.8C5 15.6 5.9 18.3 7.5 20.5L15 29.6C15.2 29.8 15.5 30 15.8 30C16.1 30 16.4 29.9 16.6 29.6L24.1 20.4C25.7 18.2 26.6 15.5 26.6 12.7C26.6 6.8 21.7 2 15.8 2ZM22.5 19.2L15.8 27.4L9.1 19.3C7.8 17.4 7 15.1 7 12.8C7 8 10.9 4 15.8 4C20.7 4 24.6 7.9 24.6 12.8C24.6 15.1 23.8 17.4 22.5 19.2Z" fill="${Theme.primary}"/>
    <path d="M15.8 9C13.6 9 11.8 10.8 11.8 13C11.8 15.2 13.6 17 15.8 17C18 17 19.8 15.2 19.8 13C19.8 10.8 18 9 15.8 9ZM15.8 15C14.7 15 13.8 14.1 13.8 13C13.8 11.9 14.7 11 15.8 11C16.9 11 17.8 11.9 17.8 13C17.8 14.1 16.9 15 15.8 15Z" fill="${Theme.primary}"/>
</svg>
`;

const locationMarkerActiveSvg = `
<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8 2C9.9 2 5 6.8 5 12.8C5 15.6 5.9 18.3 7.5 20.5L15 29.6C15.2 29.8 15.5 30 15.8 30C16.1 30 16.4 29.9 16.6 29.6L24.1 20.4C25.7 18.2 26.6 15.5 26.6 12.7C26.6 6.8 21.7 2 15.8 2ZM22.5 19.2L15.8 27.4L9.1 19.3C7.8 17.4 7 15.1 7 12.8C7 8 10.9 4 15.8 4C20.7 4 24.6 7.9 24.6 12.8C24.6 15.1 23.8 17.4 22.5 19.2Z" fill="${Theme.accent}"/>
    <path d="M15.8 9C13.6 9 11.8 10.8 11.8 13C11.8 15.2 13.6 17 15.8 17C18 17 19.8 15.2 19.8 13C19.8 10.8 18 9 15.8 9ZM15.8 15C14.7 15 13.8 14.1 13.8 13C13.8 11.9 14.7 11 15.8 11C16.9 11 17.8 11.9 17.8 13C17.8 14.1 16.9 15 15.8 15Z" fill="${Theme.accent}"/>
</svg>
`;

const plusControlSvg = `
<svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 11.25H12.75V3C12.75 2.80109 12.671 2.61032 12.5303 2.46967C12.3897 2.32902 12.1989 2.25 12 2.25C11.8011 2.25 11.6103 2.32902 11.4697 2.46967C11.329 2.61032 11.25 2.80109 11.25 3V11.25H3C2.80109 11.25 2.61033 11.329 2.46968 11.4697C2.32903 11.6103 2.25 11.8011 2.25 12C2.25 12.1989 2.32903 12.3897 2.46968 12.5303C2.61033 12.671 2.80109 12.75 3 12.75H11.25V21C11.25 21.1989 11.329 21.3897 11.4697 21.5303C11.6103 21.671 11.8011 21.75 12 21.75C12.1989 21.75 12.3897 21.671 12.5303 21.5303C12.671 21.3897 12.75 21.1989 12.75 21V12.75H21C21.1989 12.75 21.3897 12.671 21.5303 12.5303C21.671 12.3897 21.75 12.1989 21.75 12C21.75 11.8011 21.671 11.6103 21.5303 11.4697C21.3897 11.329 21.1989 11.25 21 11.25Z" fill="${Background.scrollbar}"/>
</svg>
`;

const minusControlSvg = `
<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 11.25C9.83445 11.25 14.6767 11.25 3 11.25C2.80109 11.25 2.61033 11.329 2.46968 11.4697C2.32903 11.6103 2.25 11.8011 2.25 12C2.25 12.1989 2.32903 12.3897 2.46968 12.5303C2.61033 12.671 2.80109 12.75 3 12.75C14.6767 12.75 9.83445 12.75 21 12.75C21.1989 12.75 21.3897 12.671 21.5303 12.5303C21.671 12.3897 21.75 12.1989 21.75 12C21.75 11.8011 21.671 11.6103 21.5303 11.4697C21.3897 11.329 21.1989 11.25 21 11.25Z" fill="${Background.scrollbar}"/>
</svg>
`;

export const locationMarkerSrc = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(locationMarkerSvg)}`;
export const locationMarkerActiveSrc = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(locationMarkerActiveSvg)}`;
export const plusControlSrc = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(plusControlSvg)}`;
export const minusControlSrc = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(minusControlSvg)}`;

