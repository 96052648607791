import { Background, Box, Breakpoints, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Wrapper = styled(Stack).attrs({
  direction: "column",
  gap: "XS",
  p: ["M", "M", "S"],
})`
  background-color: ${Background.contentLight};
  border-radius: 4px;
`;

export const WrapperKey = styled(Box).attrs({
  p: "XS",
})`
  white-space: nowrap;

  @media (max-width: ${Breakpoints.M}) {
    white-space: unset;
  }
`;

export const WrapperValue = styled(Box).attrs({
  p: "XS",
})`
  overflow: hidden;
  white-space: nowrap;
`;
