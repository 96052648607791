import { Stack, Text, Toggle } from "@secuis/ccp-react-components";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { SliderComponent, ToggleWrapper } from "./styled";

interface SliderSettingsProps {
  title: string;
  description: string;
  elemKey: string;
  valuePush: boolean;
  valueEmail: boolean;
  updatePreferences: (key: string, value: { value: string; type: string }, checked: boolean) => Promise<void>;
  shouldShowEmails: boolean;

}

export const SliderSettings: FC<SliderSettingsProps> = ({ title, description, elemKey, valuePush, valueEmail, updatePreferences, shouldShowEmails }) => {
  const { t } = useTranslation();
  const [checkPush, setCheckPush] = useState(valuePush);
  const [checkEmail, setCheckEmail] = useState(valueEmail);

  const onChangeEmailHandler = async () => {
    setCheckEmail(!checkEmail);
    const stringValue = !checkEmail ? "true" : "false";
    await updatePreferences(`${elemKey}.email`, { value: stringValue, type: "bool" }, !checkEmail);
  };

  const onChangePushHandler = async () => {
    setCheckPush(!checkPush);
    const stringValue = !checkPush ? "true" : "false";
    await updatePreferences(`${elemKey}.inapppush`, { value: stringValue, type: "bool" }, !checkPush);
  };

  return (
    <SliderComponent>
      <div>
        <Text bold small>
          {t(title)}
        </Text>
        <Text small>{t(description)}</Text>
      </div>
      <Stack direction="column" gap="XS">
        {shouldShowEmails && (
          <ToggleWrapper>
            <Toggle
              id={`${elemKey} - email`}
              key={`${elemKey} - email`}
              data-testid={`${elemKey} - email`}
              checked={checkEmail}
              onChange={onChangeEmailHandler}
            ></Toggle>
            <Text bold>{t("Settings.email")}</Text>
          </ToggleWrapper>
        )}

        <ToggleWrapper>
          <Toggle
            id={`${elemKey} - push`}
            key={`${elemKey} - push`}
            data-testid={`${elemKey} - push`}
            checked={checkPush}
            onChange={onChangePushHandler}
          ></Toggle>
          <Text bold>{t("Settings.push")}</Text>
        </ToggleWrapper>
      </Stack>
    </SliderComponent>
  );
};
