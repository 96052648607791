import { Background, Palette, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

interface DragAndDropProps {
  isDragging?: boolean;
}

export const Wrapper = styled(Stack).attrs({
  gap: "XS",
  direction: "column",
  justifyContent: "center",
  alignItems: "center",
})<DragAndDropProps>`
  height: 186px;
  border-radius: 4px;
  background-color: ${Background.content};

  ${({ isDragging }) =>
    isDragging &&
    css`
      background-color: ${Palette.Navy800};
      border: 2px dashed ${Palette.Navy300};
    `}
`;

export const File = styled.input.attrs({ type: "file", accept: "image/*" })`
  display: none;
`;
