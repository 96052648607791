import { ButtonCompact, Modal } from "@secuis/ccp-react-components";
import React, { FC, useState } from "react";
import { Wrapper } from "../Create/Preview/PreviewModal.styles";
import { WrappedPreview } from "./WrappedPreview";
import { ShareReportModal } from "../List/ReportActions/ShareReportModal";
import { useTranslation } from "react-i18next";
import { useRoles } from "../../../hooks/useRoles";
import { Report } from "../../../models/ReportModel";

interface PreviewModalForReportListProps {
  showPreview: boolean;
  setShowPreview: (value: boolean) => void;
  report: Report;
}

export const PreviewModalForReportList: FC<PreviewModalForReportListProps> = ({ showPreview, setShowPreview, report }) => {
  const { t } = useTranslation();
  const { canShareReport } = useRoles();
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

  const handleShareReport = () => {
    setShareModalOpen(!shareModalOpen);
  };

  return (
    <Wrapper>
      <Modal
        isOpen={showPreview}
        size="L"
        title={
          <ButtonCompact
            icon="Share"
            mode="outlined"
            title={t("shareReport.title")}
            onClick={handleShareReport}
            style={canShareReport ? {} : { display: "none" }}
          >
            {t("shareReport.title")}
          </ButtonCompact>
        }
        onClose={() => setShowPreview(false)}
        data-testid="PreviewModalForReportList"
      >
        <WrappedPreview report={report} isFromReportList={false} />
      </Modal>
      <ShareReportModal report={report} shareModalOpened={shareModalOpen} setShareModalOpen={setShareModalOpen} />
    </Wrapper>
  );
};
