import {
  Box,
  Breakpoints,
  Button,
  ButtonText,
  Checkbox,
  DropdownCheckbox,
  Headline,
  Separator,
  Stack,
  Text,
  useHasMaxWidth,
} from "@secuis/ccp-react-components";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateNavigator } from "../../../DatePicker/DateNavigator";
import { transformNames } from "../../../../utilities/reportUtils";
import { useTagsAndRegions } from "../../../../hooks/useTagsAndRegions";
import { useFilters } from "../../../../hooks/useFilters";
import { useReportTypes } from "../../../../hooks/useReportTypes";
import { getTranslatedTag } from "../../../../utilities/tagsUtils";
import { createNewFilters, deepEqual } from "../../../../utilities/filtersUtils";
import { useRoles } from "../../../../hooks/useRoles";
import { useAppDispatch } from "../../../../store";
import { reportsActions } from "../../../../store/reports/ReportsSlice";
import { Wrapper, DropdownWrapper, MainLine, ResetButtonWrapper, StickyWrapper, UnselectAllLink, DropdownWrapperFixed } from "./FiltersContent.styles";
import { FiltersButton } from "./FiltersButton";

export interface IFiltersContentProps {
  isExpanded?: boolean;
  toggleExpanded?: Function;
}
export type CheckItem = {
  [key: string]: boolean;
};

type Option = {
  value: string;
  title: string;
};

export const FiltersContent: FC<IFiltersContentProps> = ({ isExpanded, toggleExpanded }) => {
  const { t } = useTranslation();
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const { tagNames } = useTagsAndRegions();
  const { couldCreateReport } = useRoles();
  const { readableReportsTypes } = useReportTypes();
  const { filters, updateFilters, resetFilters } = useFilters();
  const dispatch = useAppDispatch();
  const [isChanged, setIsChanged] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [onlyFlagged, setOnlyFlagged] = useState(filters?.only_flagged);
  const [onlyUnpublished, setOnlyUnpublished] = useState(filters?.only_unpublished);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [checkedItems, setCheckedItems] = useState<CheckItem>({});

  useEffect(() => {
    if (readableReportsTypes.length > 0) {
      const initialCheckedState = {};
      readableReportsTypes?.forEach((option) => {
        initialCheckedState[option] = filters.report_types.includes(option);
      });
      setCheckedItems(initialCheckedState);
    }
  }, [readableReportsTypes]);

  const handleCheckboxChange = (option) => {
    const elem = {
      ...checkedItems,
      [option]: !checkedItems[option],
    };
    setCheckedItems(elem);
  };

  const handleUnselectAllReports = () => {
    const selectStatus = Object.values(checkedItems).some((item) => !item);
    const initialCheckedState = {};
    readableReportsTypes?.forEach((option) => {
      initialCheckedState[option] = selectStatus;
    });
    setCheckedItems(initialCheckedState);
  };

  const handleUnselectAllTags = () => {
    setDropdown(dropdown.length === tagNames.length ? [] : tagNames);
  };

  useEffect(() => {
    if (tagNames.length > 0) {
      setOptions(
        tagNames?.map((elem) => ({
          value: elem,
          title: getTranslatedTag(elem),
        }))
      );
      setDropdown(filters.tags);
    }
  }, [tagNames]);

  const applyFilters = () => {
    const newFilters = createNewFilters({ startDate, endDate, dropdown, onlyFlagged, onlyUnpublished, checkedItems });
    dispatch(reportsActions.setPage(1));
    updateFilters(newFilters);
  };

  const resetFiltersHandler = () => {
    resetFilters();
    setOnlyFlagged(false);
    setOnlyUnpublished(false);
    setStartDate(null);
    setEndDate(null);
    dispatch(reportsActions.setPage(1));
    const initialCheckedState = {};
    readableReportsTypes.forEach((option) => {
      initialCheckedState[option] = false;
    });
    setCheckedItems(initialCheckedState);
    setDropdown([]);
  };

  useEffect(() => {
    const newFilters = createNewFilters({ startDate, endDate, dropdown, onlyFlagged, onlyUnpublished, checkedItems });
    setIsChanged(!deepEqual(filters, newFilters));
  }, [startDate, endDate, dropdown, onlyFlagged, onlyUnpublished, checkedItems, filters]);

  return (
    <Wrapper isExpanded={isExpanded} isMobile={isMobile}>
      {(!isMobile || (isMobile && isExpanded)) && (
        <StickyWrapper>
          <MainLine>
            <Headline bold>{t("Filter.title")}</Headline>
            <FiltersButton isExpanded={isExpanded} toggleExpanded={toggleExpanded} />
          </MainLine>
        </StickyWrapper>
      )}
      {isExpanded && (
        <Stack direction="column" justifyContent="space-between">
          <Separator mv="S" mh="0" />
          <Stack direction="column" alignItems="flex-start">
            {couldCreateReport && (
              <Stack alignItems="center" mb="S">
                <Checkbox
                  data-testid="unpublished-only"
                  checked={onlyUnpublished}
                  id="unpublished-only"
                  onChange={() => setOnlyUnpublished(!onlyUnpublished)}
                />
                <Text>{t("Filter.showUnpublishedOnly")}</Text>
              </Stack>
            )}
            <Stack alignItems="center">
              <Checkbox data-testid="show-only-flagged" checked={onlyFlagged} id="show-only-flagged" onChange={() => setOnlyFlagged(!onlyFlagged)} />
              <Text>{t("Filter.showOnlyFlagged")}</Text>
            </Stack>
          </Stack>
          <Separator mv="S" mh="0" />
          <Stack justifyContent="space-between" pb="S">
            <Text bold>{t("Filter.reports")}</Text>
            <UnselectAllLink data-testid={`report-type-unselect`} onClick={handleUnselectAllReports}>
              {Object.values(checkedItems).some((item) => !item) ? t("reports.selectAll") : t("reports.unselectAll")}
            </UnselectAllLink>
          </Stack>
          <Stack direction="column" gap="S">
            {readableReportsTypes.map((elem) => (
              <Checkbox
                data-testid={`report-type-${elem}`}
                key={`report-type-${elem}`}
                checked={checkedItems[elem]}
                bold={false}
                id={`filter-checkbox-${elem}`}
                label={t(`reports.${transformNames(elem)}.title`)}
                onChange={() => handleCheckboxChange(elem)}
              />
            ))}
          </Stack>
          <DropdownWrapperFixed>
            <Separator mv="S" mh="0" />
            <Stack justifyContent="space-between" pb="S">
              <Text bold>{t("Filter.tagsAndCategories")}</Text>
              <UnselectAllLink data-testid={`tags-unselect`} onClick={handleUnselectAllTags}>
                {dropdown.length === tagNames.length ? t("reports.unselectAll") : t("reports.selectAll")}
              </UnselectAllLink>
            </Stack>
            <DropdownCheckbox
              onChange={(val) => setDropdown(val)}
              options={options}
              sheetCancelLabel="Cancel"
              values={dropdown}
              placeholder={t("filter.selectCategories")}
            />
          </DropdownWrapperFixed>
          <DropdownWrapper>
            <Separator mv="S" mh="0" />
            <Box pb="S">
              <Text bold>{t("Filter.date")}</Text>
            </Box>
            <DateNavigator
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              placeholder={t("CreateReport.schedule.selectDate")}
              setEndDate={setEndDate}
            />
          </DropdownWrapper>
          <ResetButtonWrapper>
            <Button mode="contained" color="accent" disabled={!isChanged} onClick={applyFilters}>
              {t("filter.applyFilter")}
            </Button>
            <ButtonText color="accent" onClick={resetFiltersHandler}>
              {t("Filter.reset")}
            </ButtonText>
          </ResetButtonWrapper>
        </Stack>
      )}
    </Wrapper>
  );
};
