import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BriefFiltersModel } from "../../models/BriefFiltersModel";
import { Briefs } from "../../models/BriefModel";
import { FetchStatus } from "../../models/ReportModel";
import { categorizeDates } from "../../utilities/dateUtils";
import { briefsApi } from "./BriefsApi";
import { userApi } from "../user/UserApi";

export type BriefsState = {
  data: Briefs;
  status: FetchStatus;
  page: number;
  showMore: boolean;
  filters?: BriefFiltersModel;
};

export const initialState: BriefsState = {
  data: {
    filteredBriefs: [],
    categorizedDates: {},
  },
  status: "idle",
  page: 1,
  showMore: false,
  filters: {},
};

export const briefsSlice = createSlice({
  name: "briefs",
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setFilter: (state, action: PayloadAction<BriefFiltersModel>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(briefsApi.endpoints.getBriefs.matchPending, (state, action) => {
        state.status = action.meta.arg.originalArgs.page === 1 ? action.meta.requestStatus : state.status;
      })
      .addMatcher(briefsApi.endpoints.getBriefs.matchRejected, (state, action) => {
        state.status = action.meta.requestStatus;
        state.data = action.meta.arg.originalArgs.page === 1 ? initialState.data : state.data;
      })
      .addMatcher(briefsApi.endpoints.getBriefs.matchFulfilled, (state, action) => {
        if (action.payload?.data) {
          const filteredBriefs = action.meta.arg.originalArgs.page === 1 ? action.payload.data : [...state.data.filteredBriefs, ...action.payload.data];
          const categorizedDates = categorizeDates(filteredBriefs, "start_date_of_event", "id");
          state.data = {
            filteredBriefs,
            categorizedDates,
          };
          state.page = action.payload.meta.page ?? state.page;
          state.showMore = action.payload.meta.count ? action.payload.meta.count === action.payload.meta.limit : false;
          state.status = action.meta.requestStatus;
        }
      })
      .addMatcher(userApi.endpoints.setInteractions.matchFulfilled, (state, action) => {
        if(action.payload.entity_type === "item") {
          state.data.filteredBriefs = state.data.filteredBriefs.map((brief) => 
            action.payload.entity_id === brief.id
              ? { ...brief, user_interaction: { ...brief.user_interaction, viewed: action.payload.viewed, flagged: action.payload.flagged } }
              : brief
          );
        }
      });
  },
});

export const briefsActions = briefsSlice.actions;
