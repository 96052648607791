import { Box, Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

export const TagsWrapper = styled(Stack).attrs({
  alignItems: "center",
  flexWrap: "wrap",
  gap: "XS",
})`
  width: 100%;
  grid-column: 2 / 6; // TODO: is this needed?
`;

export const ListItemWrapped = styled.span`
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

interface WrapperProps {
  selected: boolean;
}

export const Wrapper = styled(Box).attrs({
  p: "S",
  mr: "XS",
})<WrapperProps>`
  background-color: ${Palette.Navy800};
  border-radius: 4px;

  display: grid;
  grid-template-columns: 40px 5fr 4fr 2fr 2fr 2fr 3fr 170px;
  grid-template-rows: auto auto;
  gap: ${Spacing.S}px;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${Palette.Navy750};
    }
  }

  @media (max-width: 1600px) {
    grid-template-columns: 30px 1fr 1fr 1fr 1fr 20px 2fr 150px;
  }

  @media (max-width: ${Breakpoints.L}) {
    grid-template-columns: 10px 2fr 2fr;
  }

  @media (max-width: ${Breakpoints.S}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  ${({ selected }) =>
    selected &&
    css`
      border: ${Palette.Purple300} 3px solid;
      background-color: ${Palette.Navy750};
    `};
`;
