import React, { FC } from "react";
import { Text } from "@secuis/ccp-react-components";
import { useSection } from "../../../hooks/useSection";

interface DraftStatusProps {
  reportType: string;
  sections?: any;
}

export const ApprovedSections : FC<DraftStatusProps> = ({ reportType, sections: draftSections = [] }) => {
  const { getSections } = useSection();
  const allSectionsCount = getSections(reportType).length;

  return (
    allSectionsCount > 0 && (
      <Text small color="secondary">{`${draftSections.filter((s) => s.is_approved || s.is_done).length} of ${allSectionsCount} ready`}</Text>
    )
  );
};
