import { createSelector } from "@reduxjs/toolkit";
import { IRootState } from "../../store";

export const selectViewMode = ({ items: { viewMode } }: IRootState) => viewMode;

export const selectItems = ({ items: { data } }: IRootState) => data;

export const selectItemsStatus = ({ items: { status } }: IRootState) => status;

export const selectItemsShowMore = ({ items: { showMore } }: IRootState) => showMore;

export const selectItemsPage = ({ items: { page } }: IRootState) => page;

export const selectItemsFilters = ({ items: { filters } }: IRootState) => filters;

export const selectItemPreview = ({ items: { preview } }: IRootState) => preview;

export const selectItemsCollection = ({ items: { collection } }: IRootState) => collection;

export const selectItemsCollectionSelected = createSelector(selectItemsCollection, (collection) => collection.filter(({ selected }) => selected));

export const selectMetadata = ({ items: { metadata } }: IRootState) => metadata;
