import React, { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonText, ChipFilter, Input, Stack, Tooltip } from "@secuis/ccp-react-components";
import { ChipsStack } from "../../Reports/List/ReportActions/styled";
import { NotificationTypes } from "../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../context/notifications/NotificationsContext";

const MAX_LINK_LENGTH = 50;

interface SourceLinksComponentProps {
  links: string[];
  setLinks: (links: string[]) => void;
}

export const SourceLinksComponent: FC<SourceLinksComponentProps> = ({ links, setLinks }) => {
  const { t } = useTranslation();
  const { toast } = useContext(NotificationsContext);
  const [url, setUrl] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleIconClick = () => {
    if (url !== "") {
      setError(null);
      setUrl("");
      setLinks(Array.from(new Set([...links, url])));
    } else {
      setError(t("Items.incorrectUrl"));
    }
  };

  const handleChange = (event) => {
    setUrl(event.target.value);
  };

  const removeSourceLink = (link) => {
    const newList = links.filter((e) => e !== link);
    setLinks(newList);
  };

  const clearAll = () => {
    setLinks([]);
    setUrl("");
    setError(null);
  };

  const copyLink = (link: string) => {
    navigator.clipboard.writeText(link)
      .then(() => {
        toast({
          title: t("Item.source.copied"),
          type: NotificationTypes.Confirmation,
          icon: "Success",
        });
      })
      .catch(() => {
        toast({
          title: t("Item.source.notCopied"),
          type: NotificationTypes.Error,
          icon: "Error",
        });
      })
  }

  return (
    <>
      <Input icon="Add" placeholder={t("Items.linkToSource")} error={error} value={url} onIconClick={handleIconClick} onChange={handleChange} />
      {links.length > 0 && (
        <ChipsStack $chipsNumber={links.length} gap="XS" flexWrap="wrap" data-testid="stack-chips">
          {links.map((link) => (
            <Tooltip message={t("Item.source.copyInstruction")} place="top" key={link}>
              <ChipFilter
                title={link}
                label={(link.length > MAX_LINK_LENGTH) ? link.slice(0, MAX_LINK_LENGTH-1) + '...' : link}
                onRemove={(e) => {
                  e.target?.toString()?.toLowerCase()?.includes("svg")
                    ? removeSourceLink(link)
                    : copyLink(link);
                }}
              />
            </Tooltip>
          ))}
          <Stack alignItems="center">
            <ButtonText type="button" color="accent" onClick={clearAll}>
              {t("shareReport.buttonClearAll")}
            </ButtonText>
          </Stack>
        </ChipsStack>
      )}
    </>
  );
};
