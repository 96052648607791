import { Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Wrapper = styled(Stack).attrs({
    direction: "column",
})`
    position: relative;
    max-width: 200px;
    overflow: hidden;
`;
