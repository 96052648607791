import { format, isSameDay } from "date-fns";
import { Item } from "../models/ItemModel";
import { getDateWithoutTimezone } from "./dateUtils";

export const getEndDate = (item: Partial<Item>) => {
  return !isSameDay(getDateWithoutTimezone(item.start_date_of_event), getDateWithoutTimezone(item.end_date_of_event))
    ? format(getDateWithoutTimezone(item.end_date_of_event), "iii, d MMM yyyy")
    : ``;
};

export const getDateOfEvent = (item: Partial<Item>) => {
  const endDate = getEndDate(item);
  const startDate = format(getDateWithoutTimezone(item.start_date_of_event),"iii, d MMM yyyy");

  return [startDate, endDate].filter((date) => date).join(" - ");
};
