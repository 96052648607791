import { Breakpoints } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const NoPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${Breakpoints.XS}) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;