import React from "react";
import { Provider } from 'react-redux';
import { store } from './store';
import { createRoot } from "react-dom/client";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";

const container = document.getElementById("root");
const root = createRoot(container);

// initailize MixPanel
// window.mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
//     api_host: 'https://api-eu.mixpanel.com'
// });

root.render(
  <Provider store={store}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      responseType="id_token"
      scope="openid profile email"
    >
      <App />
    </Auth0Provider>
  </Provider>
);
