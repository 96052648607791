import { Box, ButtonIcon, ButtonText, Stack, Toggle } from "@secuis/ccp-react-components";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NotificationTypes } from "../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../context/notifications/NotificationsContext";
import { ITEM_STATUS } from "../../../models/ItemModel";
import { usePatchItemStatusMutation } from "../../../store/items/ItemsApi";
import { CancelModalSections } from "../../Reports/Create/Sections/CancelModalSections";
import { ItemSaveStatusIndicator } from "./ItemSaveStatusIndicator";
import { TitleAnimated, Wrapper } from "./StickyHeader.styles";
import { useRoles } from "../../../hooks/useRoles";

interface StickyHeaderProps {
  stickyScroll: boolean;
  itemId: string;
}

export const StickyHeader: FC<StickyHeaderProps> = ({ stickyScroll, itemId }) => {
  const { t } = useTranslation();
  const { canApprove: isApprover } = useRoles();
  const navigate = useNavigate();
  const { toast } = useContext(NotificationsContext);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [patchItemStatus, { isLoading }] = usePatchItemStatusMutation();
  const {
    formState: { isValid, isDirty: isFormDirty },
    setValue,
  } = useFormContext();
  const itemStatus = useWatch({ name: "item_status" });

  const canMarkAsReady = (itemStatus === ITEM_STATUS.ONGOING || itemStatus === ITEM_STATUS.READY) && isValid && !isLoading && !isFormDirty;
  const canApprove = (itemStatus === ITEM_STATUS.READY || itemStatus === ITEM_STATUS.APPROVED) && isValid && !isLoading && !isFormDirty && isApprover;
  const navigateToItems = () => navigate("/items", { replace: true });

  const updateStatus = (newStatus: ITEM_STATUS, title: string) => {
    patchItemStatus({ itemId, item_status: newStatus })
      .unwrap()
      .then(() => {
        toast({
          title,
          type: NotificationTypes.Confirmation,
          icon: "Success",
        });
        setValue("item_status", newStatus);
      })
      .catch(() => true);
  };

  const setReadyStatus = (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.checked ? updateStatus(ITEM_STATUS.READY, t("Item.form.ready")) : updateStatus(ITEM_STATUS.ONGOING, t("Item.form.notready"));

  const setApprovedStatus = (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.checked ? updateStatus(ITEM_STATUS.APPROVED, t("Item.form.approved")) : updateStatus(ITEM_STATUS.READY, t("Item.form.ready"));

  useEffect(() => {
    if (isFormDirty && itemStatus !== ITEM_STATUS.DRAFT) {
      setValue("item_status", ITEM_STATUS.ONGOING);
    }
  }, [isFormDirty, itemStatus, setValue]);

  const handleCancel = useCallback(() => {
    if (isFormDirty) {
      setIsCancelModalOpen(true);
    } else {
      navigateToItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormDirty]);

  return (
    <>
      <Wrapper>
        <Stack justifyContent="space-between">
          <Box>
            {!stickyScroll && (
              <ButtonText icon="BackNavigation" onClick={handleCancel}>
                {t("Common.back")}
              </ButtonText>
            )}
            <Stack pv="S" direction="row" alignItems="center" gap="S">
              {stickyScroll && <ButtonIcon onClick={handleCancel} icon="BackNavigation" mode="stateless" />}
              <TitleAnimated $stickyScroll={stickyScroll}>{itemId ? t("Item.editItem") : t("Items.createNewItem")}</TitleAnimated>
            </Stack>
          </Box>
          <Stack alignItems="center" gap={"L"}>
            <ItemSaveStatusIndicator />
            <Toggle
              checked={itemStatus === ITEM_STATUS.APPROVED}
              id="approveItem"
              label={t("Item.approved")}
              direction="row-reverse"
              disabled={!canApprove}
              onChange={setApprovedStatus}
            />
            <Toggle
              checked={itemStatus === ITEM_STATUS.READY || itemStatus === ITEM_STATUS.APPROVED}
              id="itemReady"
              label={t("Report.status.Ready")}
              direction="row-reverse"
              disabled={!canMarkAsReady}
              onChange={setReadyStatus}
            />
          </Stack>
        </Stack>
      </Wrapper>
      <CancelModalSections
        description={t("Item.cancel.description")}
        isOpen={isCancelModalOpen}
        setIsOpen={setIsCancelModalOpen}
        navigateTo={navigateToItems}
      />
    </>
  );
};
