import { Palette, Spacing, Title } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: ${Palette.Navy900};
`;

interface TitleAnimatedProps {
  $stickyScroll: boolean;
}

export const TitleAnimated = styled(Title)<TitleAnimatedProps>`
  transition: 0.2s;

  ${({ $stickyScroll }) =>
    $stickyScroll &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}
`;

interface SaveComponentProps {
  $stickyScroll: boolean;
}

export const ButtonWrapper = styled.div<SaveComponentProps>`
  padding-left: ${Spacing.M}px;
  padding-right: ${Spacing.M}px;

  ${({ $stickyScroll }) =>
    !$stickyScroll &&
    css`
      button {
        transition: 0.2s;
        padding-left: ${Spacing.M}px;
        padding-right: ${Spacing.M}px;
      }
    `}
`;
