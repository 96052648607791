import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const EditorContainer = styled.div`
  display: grid;
  width: 100%;
  gap: ${Spacing.M}px;
  grid-template-columns: 3fr 2fr;

  @media (max-width: ${Breakpoints.S}) {
    display: flex;
    flex-direction: column;
  }

  .tox-statusbar {
    display: none !important;
  }

  [role="menubar"] {
    display: none !important;
  }

  .tox-editor-header {
    border-bottom: none !important;
    background-color: ${Palette.Navy750} !important;
  }

  [role="group"] {
    background-color: ${Palette.Navy750} !important;
  }

  [role="application"] {
    min-width: 600px;

    background-color: ${Palette.Navy750} !important;
  }

  scrollbar-width: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${Palette.Navy850};
  }

  iframe {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: ${Palette.Navy850};
    }
  }
`;
