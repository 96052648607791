import { Box, Breakpoints, ButtonCompact, EmptyState, LoadingIndicator, Stack, Title, useHasMaxWidth } from "@secuis/ccp-react-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppSelector } from "../../store";
import { selectBriefs, selectBriefsFilters, selectBriefsPage, selectBriefsShowMore, selectBriefsStatus } from "../../store/briefs/BriefsSelectors";
import { CenteredWrapper, ItemsWrapper, LoadingWrapper } from "../Items/List/styled";
import ItemPreviewModal from "../Items/Preview/ItemPreviewModal";
import { FilterMobileButton } from "../Navigation/Filter/content/FilterMobileButton";
import { ItemsFiltersContent } from "../Navigation/Filter/content/ItemsFiltersContent";
import { ListContainer } from "../Reports/List/ReportsList.styles";
import { Brief } from "./Brief";
import { useLazyGetBriefsQuery } from "../../store/briefs/BriefsApi";

export const BriefsList = () => {
  const { t } = useTranslation();
  const { filteredBriefs } = useAppSelector(selectBriefs);
  const briefsStatus = useAppSelector(selectBriefsStatus);
  const showMore = useAppSelector(selectBriefsShowMore);
  const page = useAppSelector(selectBriefsPage);
  const filters = useAppSelector(selectBriefsFilters);
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const [toggleFilters, setToggleFilters] = useState(false);
  const [getBriefs] = useLazyGetBriefsQuery();

  const fetchBriefs = useCallback(
    (page: number) => {
      getBriefs({ page, filters });
    },
    [getBriefs, filters]
  );

  useEffect(() => {
    fetchBriefs(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ItemsWrapper>
      {isMobile && (
        <>
          <FilterMobileButton isExpanded={toggleFilters} toggleExpanded={setToggleFilters} />
          <ItemsFiltersContent isExpanded={toggleFilters} toggleExpanded={setToggleFilters} storeType="briefs" />
        </>
      )}
      <Stack mb="S" >
        <Title>{t("Briefs.list.Title")}</Title>
      </Stack>
      <Stack >
        <ButtonCompact mode="outlined" onClick={() => fetchBriefs(1)} icon="Reload">
          {t("reports.list.refresh")}
        </ButtonCompact>
      </Stack>
        {briefsStatus === "fulfilled" && filteredBriefs.length > 0 && (
          <ListContainer id="scrollableItemsDiv" mt="XS">
            <InfiniteScroll
              dataLength={filteredBriefs.length}
              next={() => fetchBriefs(page + 1)}
              hasMore={showMore}
              loader={
                <LoadingWrapper>
                  <LoadingIndicator size="L" />
                </LoadingWrapper>
              }
              scrollableTarget="scrollableItemsDiv"
            >
              {filteredBriefs.map((brief) => (
                <Brief key={brief.id} briefData={brief} />
              ))}
            </InfiniteScroll>
          </ListContainer>
        )}
        {briefsStatus === "pending" && (
          <CenteredWrapper>
            <LoadingIndicator size="L" />
          </CenteredWrapper>
        )}
        {briefsStatus === "fulfilled" && !filteredBriefs.length && (
          <CenteredWrapper>
            <EmptyState icon="Risk" title={t("Items.list.emptyState")} />
          </CenteredWrapper>
        )}
        {briefsStatus === "rejected" && !filteredBriefs.length && (
          <CenteredWrapper>
            <EmptyState icon="Warning" title={t("Items.list.errorState")} />
          </CenteredWrapper>
        )}
      <ItemPreviewModal isBrief />
    </ItemsWrapper>
  );
};
