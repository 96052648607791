import { Palette, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Wrapper = styled.div`
  label {
    background-color: indigo; // TODO: what is this?
    color: ${Palette.White};
    padding: 0.5rem; // TODO: why rem units?
    border-radius: 0.3rem;
    font-family: sans-serif; // TODO: why sans-serif? and not Securitas Sans?
    cursor: pointer;
    margin-top: 1rem;
  }
`;

export const UploadedFileSections = styled(Stack).attrs({
  justifyContent: "space-between",
  mr: "XS",
  p: "XS",
})`
  background-color: ${Palette.Navy750};
  border: 2px solid ${Palette.Navy300};
  border-radius: 4px;
  min-width: 398px;
`;

export const FileName = styled.div`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  min-width: 0;
  max-width: 400px;
`;

export const ButtonsWrapper = styled.div`
  padding: 0;

  button {
    min-width: 400px;
  }
`;
