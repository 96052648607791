import { Palette } from "@secuis/ccp-react-components";
import React from "react";

const DesktopIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" name="DesktopIcon">
    <path
      d="M13 2.5H3C2.60218 2.5 2.22064 2.65804 1.93934 2.93934C1.65804 3.22064 1.5 3.60218 1.5 4V11C1.5 11.3978 1.65804 11.7794 1.93934 12.0607C2.22064 12.342 2.60218 12.5 3 12.5H7.5V13.5H6C5.86739 13.5 5.74021 13.5527 5.64645 13.6464C5.55268 13.7402 5.5 13.8674 5.5 14C5.5 14.1326 5.55268 14.2598 5.64645 14.3536C5.74021 14.4473 5.86739 14.5 6 14.5H10C10.1326 14.5 10.2598 14.4473 10.3536 14.3536C10.4473 14.2598 10.5 14.1326 10.5 14C10.5 13.8674 10.4473 13.7402 10.3536 13.6464C10.2598 13.5527 10.1326 13.5 10 13.5H8.5V12.5H13C13.3978 12.5 13.7794 12.342 14.0607 12.0607C14.342 11.7794 14.5 11.3978 14.5 11V4C14.5 3.60218 14.342 3.22064 14.0607 2.93934C13.7794 2.65804 13.3978 2.5 13 2.5ZM3 3.5H13C13.1326 3.5 13.2598 3.55268 13.3536 3.64645C13.4473 3.74021 13.5 3.86739 13.5 4V9H2.5V4C2.5 3.86739 2.55268 3.74021 2.64645 3.64645C2.74021 3.55268 2.86739 3.5 3 3.5ZM13 11.5H3C2.86739 11.5 2.74021 11.4473 2.64645 11.3536C2.55268 11.2598 2.5 11.1326 2.5 11V10H13.5V11C13.5 11.1326 13.4473 11.2598 13.3536 11.3536C13.2598 11.4473 13.1326 11.5 13 11.5Z"
      fill={Palette.Gray100}
    />
  </svg>
);

export default DesktopIcon;
