export enum IntercomTopic {
  REDIRECT = "REDIRECT",
}

export enum AppsEnum {
  UserManagement = "UserManagement",
  Settings = "Settings",
}

export interface IntercomPayload {
  topic: IntercomTopic;
  timestamp: Date;
  message?: string;
}

export interface IntercomReceivedMessage {
  origin: string;
  data: IntercomPayload;
}

export const useIntercom = () => {
  const postMessage = (topic: IntercomTopic, message: string) => {
    const targetOrigin = "*";
    const payload = {
      topic,
      message,
      timestamp: new Date(),
    };
    window.parent.postMessage(payload, targetOrigin);
  };

  const postFile = (base64: string, fileName: string) => {
    const topic = "FILE_SAVE";
    const message = null;
    const payload = {
      topic,
      message,
      file: { base64, fileName: fileName, type: "application/pdf" },
      timestamp: new Date(),
    };
    window.parent.postMessage(payload);
  };

  return { postMessage, postFile };
};
