import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Header = styled(Stack).attrs({
  direction: "column",
  justifyContent: "space-between",
  mr: "S",
})`
  div[value="PLACEHOLDER"] {
    display: none;
  }
`;

export const Buttons = styled(Stack).attrs({
  gap: "M"
})`
  align-items: center;

  @media (max-width: ${Breakpoints.XS}) {
    flex-direction: column;
    align-items: flex-end;
    gap: ${Spacing.XXS}px;
  }
`;