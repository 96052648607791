import { ChipChoice } from "@secuis/ccp-react-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const ItemTagChip = ({ elem, selectChanged, init }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(init);
  
  useEffect(() => {
    setSelected(init);
  }, [init]);

  return <ChipChoice data-testid="chipChoice" label={t(`tag.${elem}.name`)} key={elem} selected={selected} onSelect={() => selectChanged(elem, !selected)} />;
};
