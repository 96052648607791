import { Box } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

interface WrapperProps {
  hideTabs?: boolean;
  shouldHideAll?: boolean;
}

export const Wrapper = styled(Box).attrs({
  mt: "L",
})<WrapperProps>`
  ${({ hideTabs }) =>
    hideTabs &&
    css`
      div[role="tab"] {
        display: none;
      }
      div[role="separator"] {
        display: none;
      }
    `}

  ${({ shouldHideAll }) =>
    shouldHideAll &&
    css`
      display: none;
    `}
`;
