import { Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

export const ContentElem = styled.div`
  margin-bottom: 65pt; // TODO: why pt units are used here?
`;

interface WrapperProps {
  $isMobile: boolean;
  isFromReportList: boolean;
}

export const Wrapper = styled(Stack).attrs({
  direction: "column",
})<WrapperProps>`
  scrollbar-width: auto;

  img {
    object-fit: contain;
    height: 100%;
    max-width: 100%;
  }

  ${({ isFromReportList }) =>
    isFromReportList &&
    css`
      max-width: 600px;
      min-width: 600px;
    `}

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      max-width: 350px;
    `}
`;
