import { Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components/macro";

export const Wrapper = styled(Stack)`
  background-color: ${Palette.Navy800};
  z-index: 1;

  [role="listbox"] {
    padding-bottom: ${Spacing.XL}px;
    overflow-y: scroll;
  }
`;
