import { Separator, Stack, Text } from "@secuis/ccp-react-components";
import React from "react";

export interface IExportModalFilterProps {
  label: string;
  value: string;
}

export const ExportModalFilter = ({ label, value }: IExportModalFilterProps) => {
  return (
    <>
      <Stack direction="column" gap="XS">
        <Text uppercase color="neutral" small>
          {label}
        </Text>
        <Stack alignItems="center" gap="XXS">
          <Text color="white" title={value}>
            {value}
          </Text>
        </Stack>
      </Stack>
      <Separator />
    </>
  );
};
