import React from "react";
import { Outlet, Navigate } from "react-router";

interface ProtectedRouteProps {
  hasAccess: boolean | null
};

export const ProtectedRoute = ({ hasAccess }: ProtectedRouteProps) => {
  if(hasAccess === null) return;

  return !hasAccess ? <Navigate to="/" replace /> : <Outlet />;
}