import { useStore } from "react-context-hook";

export const useSection = () => {
  const [section, setSection] = useStore("section", null);
  const [sections, setSections] = useStore("sections", null);

  const getSections = (type: string) => {
    return sections[type];
  };

  return { section, setSection, getSections, setSections, sections };
};
