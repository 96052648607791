import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import React, { FC, useEffect, useState } from "react";
import { Wrapper } from "./FilterToolbar.styles";
import { FiltersButton } from "./FiltersButton";
import { FiltersContent } from "./FiltersContent";
import { ItemsFiltersContent } from "./ItemsFiltersContent";
import { useLocation } from "react-router-dom";
import { useReportTypes } from "../../../../hooks/useReportTypes";

interface FilterToolbarProps {
  forItems?: boolean;
  storeType?: "items" | "briefs";
}

export const FilterToolbar: FC<FilterToolbarProps> = () => {
  const location = useLocation();
  const { reportsTypes } = useReportTypes();
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const [toggleFilters, setToggleFilters] = useState(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [forItems, setForItems] = useState<boolean>(false);
  const [storeType, setStoreType] = useState<"items" | "briefs">("items");

  useEffect(() => {
    if(location.pathname) {
      if(["/", "/reports"].includes(location.pathname) && reportsTypes) {
        setForItems(false);
        setShowFilters(true);
      } else if(["/items", "/briefs"].includes(location.pathname)) {
        setForItems(true);
        setShowFilters(true);
        setStoreType(location.pathname === "/items" ? "items" : "briefs");
      } else {
        setShowFilters(false);
      }
    }
  }, [location, reportsTypes])

  return !isMobile && showFilters ? (
    <Wrapper data-testid="filters-toolbar">
      <FiltersButton isExpanded={toggleFilters} toggleExpanded={setToggleFilters} />
      {forItems ? (
        <ItemsFiltersContent isExpanded={toggleFilters} toggleExpanded={setToggleFilters} storeType={storeType} />
      ) : (
        <FiltersContent isExpanded={toggleFilters} toggleExpanded={setToggleFilters} />
      )}
    </Wrapper>
  ) : null;
};
