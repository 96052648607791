import { Editor } from "@tinymce/tinymce-react";
import React, { FC, useRef } from "react";
import { WEEKLY_GLOBAL, overridedStyles, DAILY_GLOBAL, INTREP, SITREP, INTSUM, THREAT_FORECAST } from "../../../config/templates";

interface EditorWrapperProps {
  setHtmlContent: (content: string) => void;
  setLoading: (loading: boolean) => void;
  value: string;
}

export const EditorWrapper: FC<EditorWrapperProps> = ({ setHtmlContent, setLoading, value }) => {
  const editorRef = useRef(null);

  const onInitHandler = (_, editor) => {
    setLoading(false);
    editorRef.current = editor;
    editor.focus();
    editor.selection.select(editor.getBody(), true);
    editor.selection.collapse(false);
  };

  return (
    <Editor
      apiKey={process.env.REACT_APP_TEXT_EDITOR}
      onInit={onInitHandler}
      onEditorChange={setHtmlContent}
      value={value}
      init={{
        skin: "oxide-dark",
        content_css: "dark",
        content_style: overridedStyles,
        font_family_formats: "Arial=arial",
        font_formats: "Arial=arial sans-serif;",
        entity_encoding: "raw",
        element_format: "xhtml",
        height: "800",
        menubar: true,
        plugins: [
          "a11ychecker",
          "advlist",
          "advcode",
          "advtable",
          "autolink",
          "checklist",
          "export",
          "lists",
          "link",
          "image",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "powerpaste",
          "fullscreen",
          "formatpainter",
          "insertdatetime",
          "media",
          "template",
          "table",
        ],
        toolbar: "template bold italic underline | alignleft alignright alignjustify aligncenter table  | h1 h2 h3  | undo redo  ",
        templates: [
          { title: "Daily global", description: "Daily global template", content: DAILY_GLOBAL },
          { title: "Weekly global", description: "Weekly global template", content: WEEKLY_GLOBAL },
          { title: "INTREP", description: "INTREP template", content: INTREP },
          { title: "SITREP", description: "SITREP template", content: SITREP },
          { title: "INTSUM", description: "INTSUM template", content: INTSUM },
          { title: "Threat Forecast", description: "Threat Forecast template", content: THREAT_FORECAST },
        ],
      }}
    />
  );
};
