import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

export const OngoingReports = styled(Stack).attrs({
  mt: "S",
})`
  @media (max-width: ${Breakpoints.S}) {
    flex-direction: column;
  }
`;

interface ListWrapperProps {
  couldCreateReport: boolean;
}

export const ListWrapper = styled.div<ListWrapperProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${Spacing.M}px;

  ${({ couldCreateReport }) =>
    couldCreateReport &&
    css`
      display: flex;
      width: 100%;
    `};

  @media (max-width: ${Breakpoints.L}) {
    display: flex;
    flex-direction: column;
  }
`;

interface ListContainerProps {
  $draftHeight?: number;
}

export const ListContainer = styled(Stack).attrs({
  direction: "column",
  gap: "XS",
  mt: "M",
})<ListContainerProps>`
  height: ${({ $draftHeight }) => `calc(100vh - ${$draftHeight}px - 150px)`};
  width: 100%;
  overflow-y: auto;
  scrollbar-width: auto;

  @media (max-width: ${Breakpoints.XS}) {
    padding-bottom: 200px;
  }

  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    gap: ${Spacing.XS}px;
    overflow: hidden !important;
  }
`;
