import {
  Breakpoints,
  ButtonCompact,
  ButtonText,
  ContextMenu,
  ContextMenuOption,
  GraphIcon,
  Spacing,
  Stack,
  useHasMaxWidth,
} from "@secuis/ccp-react-components";
import React, { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NotificationTypes } from "../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../context/notifications/NotificationsContext";
import { ITEM_STATUS } from "../../../models/ItemModel";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useDeleteItemMutation } from "../../../store/items/ItemsApi";
import { selectItemsCollection } from "../../../store/items/ItemsSelectors";
import { itemsActions } from "../../../store/items/ItemsSlice";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { Buttons, DeleteButtonWrapper } from "./styled";

interface ItemButtonsProps {
  id: string;
  last: boolean;
  status: ITEM_STATUS;
  internalOnly: boolean;
}

export const ItemButtons: FC<ItemButtonsProps> = ({ id, last, status, internalOnly }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isCollected = useAppSelector(selectItemsCollection).some((c) => c.id === id) ?? false;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { toast } = useContext(NotificationsContext);
  const isMobile = useHasMaxWidth(Breakpoints.M);
  const [deleteItem] = useDeleteItemMutation();

  const addCollectItem = () => {
    dispatch(itemsActions.collectItem(id));
  };

  const removeCollectItem = () => {
    dispatch(itemsActions.releaseItem(id));
  };

  const deleteItemHandle = () => {
    deleteItem({ id })
      .unwrap()
      .then(() => {
        toast({ title: t("Items.buttons.delete.success"), type: NotificationTypes.Confirmation, icon: "Success" });
      })
      .catch(() => true);
  };

  const previewItem = () => {
    dispatch(itemsActions.setPreview({ itemId: id }));
  };

  const collectActions = {
    add: {
      label: t("Items.collection.collect"),
      icon: "Add",
      onClick: () => addCollectItem(),
      disabled: status !== ITEM_STATUS.APPROVED || internalOnly,
    } as ContextMenuOption,
    remove: {
      label: t("Items.collection.release"),
      icon: "Remove",
      onClick: () => removeCollectItem(),
    } as ContextMenuOption,
  };

  return (
    <Buttons>
      {isMobile ? (
        <ButtonCompact mode="contained" color="accent" onClick={() => setIsOpen(true)}>
          {t("Common.delete")}
        </ButtonCompact>
      ) : (
        <ContextMenu
          align="right"
          options={[
            !isCollected ? collectActions.add : collectActions.remove,
            {
              icon: "Delete",
              label: t("Common.delete"),
              onClick: () => setIsOpen(true),
              disabled: isCollected,
            },
          ]}
          sheetCancelLabel={t("Common.cancel")}
          sheetTitle={t("Common.cancel")}
          style={last ? { top: `-${Spacing.XL * 2}px` } : {}}
        >
          <DeleteButtonWrapper>
            <ButtonText icon="Options" />
          </DeleteButtonWrapper>
        </ContextMenu>
      )}
      {!isMobile && 
        <ButtonCompact mode="contained" color="accent" onClick={() => navigate(`/editItem/${id}`)} style={!isMobile ? { padding: "0 20px" } : {}}>
          {t("Common.edit")}
        </ButtonCompact>
      }
      <ButtonCompact mode="contained" color="accent" onClick={previewItem}>
        {t("Items.preview")}
      </ButtonCompact>
      {isCollected && (
        <Stack alignItems="center" style={{ marginLeft: "-18px" }}>
          <GraphIcon color="dataSeries5" variant="GraphTotal" />
        </Stack>
      )}
      <ConfirmationModal isOpen={isOpen} setIsOpen={setIsOpen} action={deleteItemHandle} title={t("Item.buttons.delete.confirmation")} />
    </Buttons>
  );
};
