import styled from "styled-components";
import { Stack, Text } from "@secuis/ccp-react-components";

export const UserInfoText = styled(Text)`
  margin-right: 10px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
`;

export const AttachmentWrapper = styled(Stack).attrs({
  justifyContent: "flex-end",
  pt: "XS",
})``;
