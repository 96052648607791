import { useEffect } from "react";
import { EMPTY_USER_ID } from "../../constants/userIds";
import { useAppSelector } from "../../store";
import { getUserName } from "../../utilities/utils";
import { useLazyGetUserQuery } from "./UserApi";
import { selectUser } from "./UserSelectors";

export const useUser = (userId: string) => {
  const user = useAppSelector(selectUser(userId));
  const [fetchUser, { isLoading, isError }] = useLazyGetUserQuery();

  useEffect(() => {
    if (!user && userId && userId !== EMPTY_USER_ID) {
      fetchUser(userId);
    }
  }, [user, userId, fetchUser]);

  return {
    user,
    userName: getUserName(user),
    isLoading,
    isError,
  };
};
