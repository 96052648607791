import { Breakpoints, Link, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components/macro";

interface WrapperProps {
  isExpanded?: boolean;
  isMobile?: boolean; // TODO: Remove this prop, use @media query to detect mobile resolution
}

export const Wrapper = styled.div<WrapperProps>`
  padding: 0 ${Spacing.M}px ${Spacing.M}px;
  margin-top: ${Spacing.M}px;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: auto;

  @media (max-width: ${Breakpoints.XS}) {
    padding: 0 ${Spacing.S}px 0;
    margin-top: ${Spacing.S}px;
    ${({ isExpanded }) =>
      !isExpanded &&
      css`
        margin-top: 0;
        padding: 0;
        margin: 0;
      `};
  }

  ${(props) =>
    props.isExpanded &&
    css`
      opacity: 1;
      width: 320px;

      @media (max-width: ${Breakpoints.XS}) {
        width: 100%;
        height: 100%;

        position: absolute;
        top: -${Spacing.S}px;
        left: 0;
        z-index: 10;
        background: ${Palette.Navy800};
      }
    `};

  ${(props) =>
    !props.isExpanded &&
    !props.isMobile &&
    css`
      opacity: 0;
      width: 72px;
    `};

  ${(props) =>
    !props.isExpanded &&
    props.isMobile &&
    css`
      display: none;
    `};
`;

export const MainLine = styled(Stack).attrs({
  justifyContent: "space-between",
  alignItems: "center",
})`
  padding-bottom: ${Spacing.S}px;

  @media (max-width: ${Breakpoints.XS}) {
    padding-top: ${Spacing.S}px;
  }

  > :nth-child(2) {
    display: none;

    @media (max-width: ${Breakpoints.XS}) {
      display: block;
    }
  }
`;

export const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: ${Palette.Navy800};
  z-index: 2;
`;

export const DropdownWrapper = styled.div`
  div[role="listbox"] {
    scrollbar-width: auto;
    div {
      word-break: keep-all;
    }
  }
`;

export const DropdownWrapperFixed = styled.div`
  @media (max-width: ${Breakpoints.XS}) {
    div[role="group"] {
      > div:nth-child(2) {
        position: fixed;
      }
    }
  }
  div[role="listbox"] {
    scrollbar-width: auto;
    div {
      word-break: keep-all;
    }
  }
`;

export const ResetButtonWrapper = styled(Stack).attrs({
  direction: "column",
  alignItems: "center",
  gap: "S",
})`
  margin-top: ${Spacing.XXL}px;

  @media (max-width: ${Breakpoints.XS}) {
    margin-top: ${Spacing.S}px;
  }
`;

export const UnselectAllLink = styled(Link)`
  text-decoration: none;
`;
