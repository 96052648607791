import { Badge, Box, Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { InternalIcon } from "../../../assets/icons/InternalIcon";
import { Item } from "../../../models/ItemModel";
import { getDateOfEvent } from "../../../utilities/Item.helpers";
import { getColor } from "../../../utilities/sectionUtils";
import { getTranslatedTag } from "../../../utilities/tagsUtils";
import { ItemButtons } from "./ItemButtons";
import { Parameter } from "./Parameter";
import { ButtonsBox, ItemContainer, ItemParameters, ItemTags, ParametersWrapper } from "./styled";

export const ItemShort = ({ itemData, last }: { itemData: Item; last: boolean }) => {
  const { t } = useTranslation();
  const isMobile = useHasMaxWidth(Breakpoints.M);

  const location = `${itemData?.settlement ?? "-"}, ${itemData?.country ?? "-"} `;

  return (
    <ItemContainer data-testid="short-item-element" direction={isMobile ? "column" : "row"}>
      <ParametersWrapper>
        <ItemParameters>
          <Parameter label={t("Item.dateOfEvent")} icon="Calendar" large item>
            {getDateOfEvent(itemData)}
          </Parameter>
          <Parameter label={t("Items.location")} icon="Location" item>
            {location}
          </Parameter>
          <Parameter label={t("Item.title")} icon="Image" disabled={!itemData.image_url} defWidth={false} truncate={isMobile} item>
            {itemData.title}
          </Parameter>
        </ItemParameters>
        <ItemTags>
          {[...new Set(itemData.threat_risk_categories)].map((tag) => (
            <Badge key={`${itemData.id}-${tag}`} label={getTranslatedTag(tag)} />
          ))}
          {itemData.item_status && (
            <Box marginLeft={"S"}>
              <Badge label={t(`Section.status.${itemData.item_status}`)} mode="outlined" color={getColor(itemData.item_status)} />
            </Box>
          )}
          <Box marginLeft={"L"}>{itemData.is_internal && <InternalIcon />}</Box>
        </ItemTags>
      </ParametersWrapper>
      <ButtonsBox>
        <ItemButtons id={itemData.id} last={last} status={itemData.item_status} internalOnly={itemData.is_internal} />
      </ButtonsBox>
    </ItemContainer>
  );
};
