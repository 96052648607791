import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, ButtonText, Text, Box } from "@secuis/ccp-react-components";
import { ContactUs } from "./ContactUs";
import { ContactUsStack, ContactUsWrapper } from "./Navigation.styles";

interface ContactUsButtonProps {
  isSmallScreen: boolean;
  displayLabel: boolean;
}

const ContactUsButton: FC<ContactUsButtonProps> = ({ isSmallScreen, displayLabel }) => {
  const { t } = useTranslation();
  const [showContactUs, setShowContactUs] = useState(false);
  const onClickHandler = () => setShowContactUs(true);

  return (
    <ContactUsWrapper>
      {isSmallScreen ? (
        <ContactUsStack direction="column" alignItems="center" gap="XS" onClick={onClickHandler} mv="S">
          <Icon variant="Message" size="L" />
          <Text micro bold>
            {t("Menu.item4")}
          </Text>
        </ContactUsStack>
      ) : (
        <Box mv="S">
          <ButtonText iconSize="L" icon="Message" onClick={onClickHandler}>
            {displayLabel && t("Menu.item4")}
          </ButtonText>
        </Box>
      )}
      <ContactUs showContactUs={showContactUs} setShowContactUs={setShowContactUs} />
    </ContactUsWrapper>
  );
};

export default ContactUsButton;
