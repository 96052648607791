import { useStore } from "react-context-hook";
import { getRegionsArray } from "../utilities/tagsUtils";
import { useTags } from "./useTags";
import { Tag } from "../models/model";
import { Region } from "../models/RegionModel";

export const useTagsAndRegions = () => {
  const [tagsAndRegions, setTagsAndRegions] = useStore("tagsAndRegions", null);
  const [initRegions, setInitRegions] = useStore("initRegions", null);
  const [tagNames, setTagNames] = useStore("tagNames", [] as string[]);

  const { setTags } = useTags();
  const uploadTags = (data: Tag[]) => {
    const initTags: Region[] = getRegionsArray(data);
    const allTagNames: string[] = data.map((e) => e.tagName.toLocaleLowerCase());
    const noDuplicatedTagNames: string[] = [...new Set(allTagNames)];
    setTagNames(noDuplicatedTagNames);
    setTags(initTags);
    setInitRegions(JSON.parse(JSON.stringify(initTags)));
  };

  const resetTags = () => {
    if (initRegions) {
      setTags(JSON.parse(JSON.stringify(initRegions)));
    }
  };

  return { tagsAndRegions, setTagsAndRegions, uploadTags, tagNames, resetTags, initRegions };
};
