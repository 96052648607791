import styled from "styled-components";
import { Background, Box } from "@secuis/ccp-react-components";
import { minusControlSrc, plusControlSrc } from "./Assets";

export const Container = styled(Box).attrs({
  mt: "M",
})`
  position: relative;
  width: 100%;
  height: 100%;

  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    display: none;
  }

  .mapboxgl-popup-content {
    background: ${Background.contentLight};
  }

  .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: ${Background.contentLight};
  }

  .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: ${Background.contentLight};
  }

  .mapboxgl-popup-anchor-top,
  .mapboxgl-popup-anchor-top-left,
  .mapboxgl-popup-anchor-top-right {
    .mapboxgl-popup-tip {
      border-bottom-color: ${Background.contentLight};
    }
  }

  .mapboxgl-popup-anchor-bottom,
  .mapboxgl-popup-anchor-bottom-left,
  .mapboxgl-popup-anchor-bottom-right {
    .mapboxgl-popup-tip {
      border-top-color: ${Background.contentLight};
    }
  }

  .mapboxgl-ctrl-group {
    background: ${Background.contentLight};
  }

  .mapboxgl-ctrl-group button + button {
    border-color: ${Background.scrollbar};
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url(${plusControlSrc});
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url(${minusControlSrc});
  }
`;
