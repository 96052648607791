import { useStore } from "react-context-hook";
import { transformNames } from "../utilities/reportUtils";
import { useSection } from "./useSection";
import { getInitSectionsList } from "../utilities/sectionUtils";
import { ReportType } from "../models/ReportTypeModel";

export const useReportTypes = () => {
  const [reportsTypes, setReportsTypes] = useStore("reportsTypes", []);
  const [readableReportsTypes, setReadableReportsTypes] = useStore("readableReportsTypes", []);
  const { setSections } = useSection();

  const uploadReportsTypes = (schedules: ReportType[]) => {
    setSections(getInitSectionsList(schedules));
    const types: string[] = schedules?.map((e: ReportType) => transformNames(e.name));
    setReadableReportsTypes(schedules?.map((e: ReportType) => e.name));
    setReportsTypes(types);
  };

  return { reportsTypes, uploadReportsTypes, readableReportsTypes };
};
