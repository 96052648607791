import { createApi } from "@reduxjs/toolkit/query/react";
import { ItemFiltersModel } from "../../models/ItemFiltersModel";
import { ItemMetadataModel } from "../../models/ItemMetadataModel";
import { Item, ITEM_STATUS } from "../../models/ItemModel";
import { baseQuery } from "../../utilities/baseQuery";

export interface FetchItemsParams {
  page?: number;
  limit?: number;
  filters?: ItemFiltersModel;
}

export interface FetchItemsMetaResponse {
  meta: {
    count: number;
    limit: number;
    page: number;
  };
}

export const itemsApi = createApi({
  reducerPath: "itemsApi",
  baseQuery: baseQuery(),
  tagTypes: ["Item"],
  endpoints: (builder) => ({
    getItems: builder.query<{ data: Item[] } & FetchItemsMetaResponse, FetchItemsParams>({
      query: ({ page, limit = 20, filters }) => ({
        url: "items",
        params: {
          page,
          limit,
          ...filters,
        },
      }),
    }),
    exportItems: builder.mutation<void, ItemFiltersModel>({
      query: (params) => ({
        url: "items/export",
        method: "POST",
        params,
        body: { item_ids: [] },
      }),
    }),
    getItem: builder.query<Item, string>({
      query: (itemId) => `items/${itemId}`,
      providesTags: ["Item"],
    }),
    deleteItem: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `items/${id}`,
        method: "DELETE",
      }),
    }),
    postItem: builder.mutation<Item, Partial<Item>>({
      query: (item) => ({
        url: "items",
        method: "POST",
        body: item,
      }),
    }),
    patchItem: builder.mutation<Item, { id: string; item: Partial<Item> }>({
      query: ({ id, item }) => ({
        url: `items/${id}`,
        method: "PATCH",
        body: item,
      }),
    }),
    patchItemStatus: builder.mutation<void, { itemId: string; item_status: ITEM_STATUS }>({
      query: ({ itemId, item_status }) => ({
        url: `items/${itemId}/update-status`,
        method: "PATCH",
        body: {
          item_status,
        },
      }),
      invalidatesTags: ["Item"],
    }),
    putImage: builder.mutation<{ item_id: string; image_url: string }, { itemId: string; data: string; type: string }>({
      query: ({ itemId, data, type }) => ({
        url: `/items/${itemId}/images`,
        method: "PUT",
        body: {
          data,
          type,
        },
      }),
    }),
    deleteImage: builder.mutation<void, { itemId: string }>({
      query: ({ itemId }) => ({
        url: `/items/${itemId}/images`,
        method: "DELETE",
      }),
    }),
    getMetadata: builder.query<Partial<ItemMetadataModel>, string>({
      query: (property) => ({
        url: `/items/meta-data/${property}`,
      }),
    }),
  }),
});

export const {
  useLazyGetItemsQuery,
  useExportItemsMutation,
  useGetItemQuery,
  useLazyGetItemQuery,
  useDeleteItemMutation,
  usePostItemMutation,
  usePatchItemMutation,
  usePatchItemStatusMutation,
  usePutImageMutation,
  useDeleteImageMutation,
  useLazyGetMetadataQuery,
} = itemsApi;
