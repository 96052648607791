import { useEffect } from "react";
import { useStore } from "react-context-hook";
import { useTagsAndRegions } from "./useTagsAndRegions";
import { useReportTypes } from "./useReportTypes";
import { createUrl } from "../utilities/filtersUtils";
import { useRoles } from "./useRoles";

export const useFilters = () => {
  const { couldCreateReport } = useRoles();
  const [url, setUrl] = useStore<string>("url", "");
  const [filters, setFilters] = useStore<any>("filters", {
    only_flagged: false,
    only_unpublished: false,
    tags: [],
    report_types: [],
    dates: [],
  });

  const { tagNames } = useTagsAndRegions();
  const { reportsTypes } = useReportTypes();

  useEffect(() => {
    if (couldCreateReport !== null) {
      const url = createUrl({ filters, tagNames, reportsTypes, couldCreateReport });
      setUrl(url);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, couldCreateReport]);

  const updateFilters = (elem) => {
    setFilters({
      ...filters,
      ...elem,
    });
  };

  const resetFilters = () => {
    setFilters({
      only_flagged: false,
      only_unpublished: false,
      tags: [],
      report_types: [],
      dates: [],
    });
  };

  return { filters, setFilters, updateFilters, resetFilters, url };
};
