import { Region } from "./RegionModel";

export enum SECTION_STATUS {
  EMPTY = "empty",
  ONGOING = "ongoing",
  READY = "ready",
  APPROVED = "approved",
}

export interface Section {
  id?: string;
  name?: string;
  content?: string;
  lang?: string;
  tags?: Region[];
  order?: number;
  author?: string;
  is_done?: boolean;
  is_approved?: boolean;
  status?: SECTION_STATUS;
}
