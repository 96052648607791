import { Button, Theme } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  h3 + button {
    z-index: 10000;
  }
  scrollbar-width: auto;
  div {
    scrollbar-width: auto;
  }
`;

interface ButtonStyledProps {
  isActive: boolean;
  isDesktop?: boolean;
}

export const ButtonStyled = styled(Button)<ButtonStyledProps>`
  width: 42px;
  height: 42px;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${Theme.pressed.accent};
    `}

  div {
    max-height: 19px;
    width: 17px;
  }

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      div {
        max-height: 17px;
      }
    `}
`;
