import { Palette } from '@secuis/ccp-react-components';
import styled from 'styled-components';

export const FilterWrapper = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;

    div {
        position: absolute;
        z-index: 3;
        top: -8px;
        right: -4px;
        background-color: ${Palette.Purple300};
        border-color: ${Palette.Navy750};
        color: ${Palette.Navy750};
    }
`;
