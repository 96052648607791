import { LoadingIndicator } from "@secuis/ccp-react-components";
import React from "react";
import { Item } from "../../../models/ItemModel";
import { useDeleteImageMutation, useLazyGetItemQuery } from "../../../store/items/ItemsApi";
import { DeleteButton, Image, RelativeWrapper } from "./ImagePreview.styles";
import { Wrapper } from "./ImageUpload.styles";
import { useFormContext } from "react-hook-form";

interface ImagePreviewProps {
  item: Item;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ item }) => {
  const {
    formState: { isDirty },
  } = useFormContext();
  const [fetchItem] = useLazyGetItemQuery();
  const [deleteImage, { isLoading }] = useDeleteImageMutation();

  const onDelete = () => {
    deleteImage({ itemId: item.id })
      .unwrap()
      .then(() => {
        fetchItem(item.id); // refresh item in RTK cache by reloading it
      })
      .catch(() => true);
  };

  return isLoading ? (
    <Wrapper>
      <LoadingIndicator size="L" />
    </Wrapper>
  ) : (
    <RelativeWrapper>
      <Image src={item.image_url} alt={item.title} />
      <DeleteButton mode="contained" icon="Delete" onClick={onDelete} disabled={isDirty} />
    </RelativeWrapper>
  );
};

export default ImagePreview;
