import { Modal, Stack } from "@secuis/ccp-react-components";
import React, { FC, useState } from "react";
import DesktopIcon from "../../../../assets/icons/DesktopIcon";
import DeviceMobileSpeaker from "../../../../assets/icons/DeviceMobileSpeaker";
import { Preview } from "./Preview";
import { ButtonStyled, Wrapper } from "./PreviewModal.styles";

interface PreviewModalProps {
  htmlContent: string[];
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const PreviewModal: FC<PreviewModalProps> = ({ htmlContent, isOpen, setIsOpen }) => {
  const [mobileSelected, setMobileSelected] = useState(false);

  const onCloseHandler = () => {
    setIsOpen(false);
    setMobileSelected(false);
  };

  return (
    <Wrapper>
      <Modal
        isOpen={isOpen}
        size="L"
        title={
          <Stack justifyContent="center" gap="M" flex="1">
            <ButtonStyled name="action" mode="contained" onClick={() => setMobileSelected(false)} isActive={!mobileSelected} isDesktop>
              <DesktopIcon />
            </ButtonStyled>
            <ButtonStyled name="action" mode="contained" onClick={() => setMobileSelected(true)} isActive={mobileSelected}>
              <DeviceMobileSpeaker />
            </ButtonStyled>
          </Stack>
        }
        onClose={onCloseHandler}
      >
        <Preview htmlContent={htmlContent} mobileSelected={mobileSelected} isFromReportList={false} />
      </Modal>
    </Wrapper>
  );
};
