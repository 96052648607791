import { createApi } from "@reduxjs/toolkit/query/react";
import { UserModel } from "../../models/UserModel";
import { baseQuery } from "../../utilities/baseQuery";

export interface Interaction {
  entity_id: string;
  entity_type: 'item' | 'report';
  viewed: boolean;
  flagged: boolean;
};

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQuery(process.env.REACT_APP_MYSACCESS_BASE_URL),
  endpoints: (builder) => ({
    getUser: builder.query<UserModel, string>({
      query: (userId) => ({
        url: `/users/byId/${userId}`,
      }),
    }),
    setInteractions: builder.mutation<Interaction, Interaction & { userId: string }>({
      query: ({ userId, entity_id, entity_type, viewed, flagged }) => ({
        url: `/ric/users/${userId}/interactions`,
        method: "PATCH",
        body: {
          entity_id,
          entity_type,
          viewed,
          flagged,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetUserQuery,
  useSetInteractionsMutation
} = userApi;
