import React from "react";
import { Modal } from "@secuis/ccp-react-components";

type RedirectModalProps = {
    isOpen: boolean;
    setIsOpen: (status: boolean) => void;
    buttonTitle: string;
    redirectTo: () => void;
    title: string;
    children: JSX.Element;
};

export const RedirectModal = ({ isOpen, setIsOpen, buttonTitle, redirectTo, title, children }: RedirectModalProps) => (
    <Modal
        isOpen={isOpen}
        size="L"
        title={title}
        actions={[
            {
              children: buttonTitle,
              onClick: () => redirectTo(),
            }]}
        onClose={() => setIsOpen(false)}>
            {children}
    </Modal>
  );
