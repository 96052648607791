import { Palette } from "@secuis/ccp-react-components";
import React from "react";

const SeenIcon = ({ isSeen = true }) => (
  <svg data-testid="seen-icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5Z"
      fill={isSeen ? Palette.Navy700 : Palette.Purple400}
    />
  </svg>
);

export default SeenIcon;
