import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";

// Consider using namespaces once it makes sense: https://www.i18next.com/principles/namespaces

enum LANGUAGE_CODES {
  DUTCH = "nl",
  ENGLISH = "en",
  POLISH = "pl",
  NORWEGIAN_BOKMAL = "nb",
  SWEDISH = "sv",
  ES = "es",
  FR = "fr",
  DE = "de",
  FRBE = "fr-be",
  NLBE = "nl-be",
  CS = "cs",
  HR = "hr",
  RO = "ro",
  SK = "sk",
  SR = "sr",
}

const LANGUAGES_LIST = Object.values(LANGUAGE_CODES);

const defaultLanguage = LANGUAGE_CODES.ENGLISH;

const i18NextOptions = {
  appNameForLocalization: "riawareness",
  localizationStorage: window.localStorage,
  cachedLocalizationPrefix: "i18next_riawareness_res_",
  timestampInCacheProp: "i18nStamp",
};

const i18nextInit = (lng = defaultLanguage, path = "") => {
  const localPath = path || process.env.REACT_APP_MYSACCESS_BASE_URL;
  return i18next
    .use(detector)
    .use(initReactI18next)
    .use(ChainedBackend)
    .init(
      {
        debug: process.env.NODE_ENV === "development",
        fallbackLng: lng,

        interpolation: {
          escapeValue: false,
        },
        //Note: we're using ''(empty string) as default namespace, while starting to use namespaces the useAppTranslationSync appHook needs to be modified
        defaultNS: "",
        ns: [""],
        load: "languageOnly",
        lng: lng,
        supportedLngs: LANGUAGES_LIST,
        backend: {
          backends: [
            LocalStorageBackend, // primary backend, local storage serving as a cache,
            HttpBackend,
          ],
          backendOptions: [
            {
              prefix: i18NextOptions.cachedLocalizationPrefix,
              expirationTime: 31 * 24 * 60 * 60 * 1000, //31 days of cache,
              store: i18NextOptions.localizationStorage,
            },
            {
              loadPath: localPath + "/localization/translations/" + i18NextOptions.appNameForLocalization + "/{{lng}}/{{ns}}?format=json",
            },
          ],
        },
      },
      (err, t) => {
        if (err) {
          console.warn("Translation error:", err);
        }
      }
    );
};

export { i18nextInit, i18next, defaultLanguage, LANGUAGE_CODES, i18NextOptions };
