import { createObjectURL } from "./objectUrlUtility";

export const base64toBlob = (base64, contentType) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

export const downloadFile = (result, fileType = "pdf", mimeType = "application/pdf") => {
  if (!result || fileType === "" || mimeType === "") return;

  const blob = base64toBlob(result.data, mimeType);
  const link = document.createElement("a");
  link.href = createObjectURL(blob);
  link.download = result.file_name || "download." + fileType;
  link.click();
};
