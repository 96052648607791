import { Breakpoints, Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const DraftReport = styled(Stack).attrs({
  direction: "column",
})`
  width: 252px;
  height: 174px;
  margin: ${Spacing.S}px;
  padding: ${Spacing.S}px;
  background-color: ${Palette.Navy750};
  justify-content: space-between;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${Palette.Navy800};
      cursor: pointer;
    }
  }

  @media (max-width: ${Breakpoints.S}) {
    width: 100%;
    justify-content: unset;
    margin: 0px;
    margin-right: ${Spacing.XS}px;
    margin-bottom: ${Spacing.XS}px;
  }
`;

export const DraftsWrapper = styled.div`
  max-height: 436px;
  overflow-y: auto;
  margin-right: ${Spacing.M}px;

  @media (max-width: ${Breakpoints.S}) {
    max-height: 200px;
    margin-right: 0px;
    overflow-x: hidden;
  }
`;

export const Title = styled(Text)`
  display: flex;
  flex-direction: column;
  flex: 5;
  overflow: hidden;
  text-overflow: ellipsis;
`;