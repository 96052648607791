import { templateMapping } from "../config/templates";
import { SECTION_STATUS } from "../models/SectionModel";

export const getInitSectionsList = (schedules) => {
  if (!schedules) return {};
  return schedules
    .map((e) => {
      const sections = e.sections.map((element) => ({ name: element.name, order: element.order, status: SECTION_STATUS.EMPTY }));
      const eleme = {};
      eleme[e.name] = sections;

      return eleme;
    })
    .reduce((acc, e) => ({ ...acc, ...e }), {});
};
export const getColor = (status) => {
  if (status === SECTION_STATUS.READY) return "low";
  if (status === SECTION_STATUS.APPROVED) return "success";
  return "neutral";
};

export const getSectionStatus = (section): SECTION_STATUS => {
  let status = SECTION_STATUS.ONGOING;

  if (section.is_approved && section.is_done) {
    status = SECTION_STATUS.APPROVED;
  }
  if (section.is_done && !section.is_approved) {
    status = SECTION_STATUS.READY;
  }
  return status;
};

export const getTemplate = (reportType, sectionName, templates = templateMapping): null | string => {
  const template = templates[reportType];
  if (!template) return null;

  return templates[reportType][sectionName];
};

export const getFinalSections = (initSections, sections, report_type) => {
  return (
    initSections?.map((e) => {
      const section = sections?.data?.find((s) => s.name === e.name);
      if (section) {
        const status = getSectionStatus(section);
        return { ...section, status };
      } else {
        const content = getTemplate(report_type, e.name);
        return { ...e, content };
      }
    }) ?? []
  );
};
