import { Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Content = styled.div`
  display: grid;
  grid-template-columns: 6fr 4fr;

  gap: ${Spacing.M}px;
  margin-top: ${Spacing.M}px;
`;
