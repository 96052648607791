import { useEffect, useState } from "react";
import { HtmlDiffer } from 'html-differ';
import { getReportTitle } from "../utilities/titleUtils";
import { areTagsChanged, findStringDifference, mergeArrays } from "../utilities/utils";
import { useSearchParam } from "./useSearchParam";
import { useTags } from "./useTags";
import { useTagsAndRegions } from "./useTagsAndRegions";
import { isDateFromFuture } from "../utilities/dateUtils";
import { useSchedule } from "./useSchedule";

const getObjectClone = (object) => JSON.parse(JSON.stringify(object));

export const useReportDataSections = ({ i18n }) => {
  const htmlDiffer = new HtmlDiffer();
  const type = useSearchParam("type");
  const id = useSearchParam("id");
  const reportType = type || "INTREP";

  const { initRegions } = useTagsAndRegions();
  const { getSchedule } = useSchedule();
  const { tags, setTags } = useTags();

  const [areTagsEmpty, setAreTagsEmpty] = useState(false);
  const [savedReport, setSavedReport] = useState<string>(id);
  const [reportCreation, setReportCreation] = useState(new Date());
  const [scheduleDate, setScheduleDate] = useState(null);
  const [uploadedScheduleDate, setUploadedScheduleDate] = useState(null);
  const [attachmentId, setAttachmentId] = useState(null);
  const [savedTags, setSavedTags] = useState(JSON.parse(JSON.stringify(initRegions)));
  const [sectionId, setSectionId] = useState(null);
  const [htmlContent, setHtmlContent] = useState("");
  const [uploadedHtmlContent, setUploadedHtmlContent] = useState("");
  const [initPrefix, setInitPrefix] = useState("");
  const [title, setTitle] = useState("");
  const [uploadedTitle, setUploadedTitle] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [isReportChanged, setIsReportChanged] = useState(false);
  const [isContentChanged, setIsContentChanged] = useState(false);
  const [isScheduleDateFromFuture, setIsScheduleDateFromFuture] = useState(true);

  const setUploadedData = (scheduleDate, title) => {
    setUploadedScheduleDate(scheduleDate);
    setUploadedTitle(title);
  };

  const setInitSectionData = (tags, content) => {
    setSavedTags(getObjectClone(tags?.length > 0 ? mergeArrays(initRegions, tags) : initRegions));
    setTags(getObjectClone(tags?.length > 0 ? mergeArrays(initRegions, tags) : initRegions));
    setHtmlContent(content || "");
    setUploadedHtmlContent(content);
  };

  const setUploadedDataSectionData = (tags, content) => {
    if (tags) {
      setSavedTags(JSON.parse(JSON.stringify(tags)));
    }
    setUploadedHtmlContent(content);
  };

  const setInitSavedReportData = (reportData) => {
    setSavedReport(reportData.report_id);
    setInitPrefix(findStringDifference(reportData.title, getReportTitle(reportData.report_type, new Date(reportData.schedule), i18n.language)));
    setReportCreation(new Date(reportData.created));
    setScheduleDate(new Date(reportData.schedule));
    setTitle(reportData.title);
    setUploadedData(new Date(reportData.schedule), reportData.title);
    setAreTagsEmpty(reportData?.tags?.length === 0);

    if (reportData.attachment_ids.length > 0) {
      setAttachmentId(reportData.attachment_ids[0]);
    }
  };


  useEffect(() => {
    const isContentEqual = htmlDiffer.isEqual(htmlContent, uploadedHtmlContent);
    setIsContentChanged(!isContentEqual);
    const tagsChanged = areTagsChanged(tags, savedTags);
    setIsChanged(!isContentEqual || tagsChanged);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlContent, uploadedHtmlContent, tags, savedTags]);

  useEffect(() => {
    const isScheduleChanged = scheduleDate?.getTime() !== uploadedScheduleDate?.getTime();
    const isTitleChanged = title !== uploadedTitle;
    setIsReportChanged(isScheduleChanged || isTitleChanged);
  }, [scheduleDate, uploadedScheduleDate, title, uploadedTitle]);

  useEffect(() => {
    if (getSchedule(reportType)) {
      setTitle(getReportTitle(reportType, scheduleDate, i18n.language));
    }
  }, [scheduleDate, reportType]);

  useEffect(() => {
    if (scheduleDate) {
      setIsScheduleDateFromFuture(isDateFromFuture(scheduleDate));
    }
  }, [scheduleDate]);

  return {
    reportCreation,
    scheduleDate,
    setScheduleDate,
    tags,
    setTags,
    savedTags,
    sectionId,
    setSectionId,
    reportType,
    htmlContent,
    setHtmlContent,
    uploadedHtmlContent,
    areTagsEmpty,
    initPrefix,
    title,
    setTitle,
    uploadedScheduleDate,
    isChanged,
    setUploadedData,
    savedReport,
    setSavedReport,
    setInitSavedReportData,
    id,
    type,
    attachmentId,
    isContentChanged,
    isScheduleDateFromFuture,
    setSavedTags,
    setUploadedDataSectionData,
    setInitSectionData,
    isReportChanged,
  };
};
