import { IconType } from "@secuis/ccp-react-components";

export enum NotificationTypes {
  Information = "neutral",
  Confirmation = "success",
  Warning = "low",
  Error = "high",
}
interface ToastAction {
  label: string;
  onClick: () => void;
}

export interface NotificationMessage {
  icon?: IconType;
  title?: string;
  message?: string;
  type: NotificationTypes;
  actions?: [ToastAction] | [ToastAction, ToastAction];
}

export interface Notification extends NotificationMessage {
  id: string;
  toBeAdded: boolean;
  toBeRemoved: boolean;
}
