import { Input } from "@secuis/ccp-react-components";
import React, { useEffect, useState } from "react";
import { getReportTitle } from "../../../utilities/titleUtils";
import { useTranslation } from "react-i18next";

export const ReportTitle = ({ setTitle, type, scheduleDate, lang, initPrefix }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(initPrefix);

  const [isEmpty, setIsEmpty] = useState(false);

  const [prefix, setPrefix] = useState(getReportTitle(type, scheduleDate, lang));

  useEffect(() => {
    const reportTitle = getReportTitle(type, scheduleDate, lang);
    setPrefix(reportTitle);
    setTitle(`${reportTitle}${inputValue}`);
  }, [type, scheduleDate, inputValue, lang]);

  useEffect(() => {
    setInputValue(initPrefix);
  }, [initPrefix]);

  useEffect(() => {
    setIsEmpty(`${prefix}${inputValue}` === "");
  }, [inputValue, prefix]);

  const handleInputChange = (event) => {
    let value = "";
    if (event.target.value.length >= prefix.length) {
      value = event.target.value.substring(prefix.length);
    }

    setInputValue(value);
    setTitle(`${prefix}${value}`);
  };

  return (
    <div>
      <Input
        data-testid="ReportTitleInput"
        maxLength={100}
        value={`${prefix}${inputValue}`}
        onChange={handleInputChange}
        invalid={isEmpty}
        error={isEmpty ? t("input.error.emptyTitle") : ""}
      ></Input>
    </div>
  );
};
