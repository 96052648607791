import { Palette } from "@secuis/ccp-react-components";
import React from "react";

const DeviceMobileSpeaker = () => (
  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg" name="DeviceMobileSpeaker">
    <path
      d="M8 0H2C1.60218 0 1.22064 0.158035 0.93934 0.43934C0.658035 0.720644 0.5 1.10218 0.5 1.5V12.5C0.5 12.8978 0.658035 13.2794 0.93934 13.5607C1.22064 13.842 1.60218 14 2 14H8C8.39782 14 8.77936 13.842 9.06066 13.5607C9.34196 13.2794 9.5 12.8978 9.5 12.5V1.5C9.5 1.10218 9.34196 0.720644 9.06066 0.43934C8.77936 0.158035 8.39782 0 8 0ZM8.5 12.5C8.5 12.6326 8.44732 12.7598 8.35355 12.8536C8.25979 12.9473 8.13261 13 8 13H2C1.86739 13 1.74021 12.9473 1.64645 12.8536C1.55268 12.7598 1.5 12.6326 1.5 12.5V1.5C1.5 1.36739 1.55268 1.24021 1.64645 1.14645C1.74021 1.05268 1.86739 1 2 1H8C8.13261 1 8.25979 1.05268 8.35355 1.14645C8.44732 1.24021 8.5 1.36739 8.5 1.5V12.5Z"
      fill={Palette.Gray100}
    />
  </svg>
);

export default DeviceMobileSpeaker;
