import { Region } from "../models/RegionModel";
import i18n from "i18next";

type RegionKeys = "name" | "region" | "short.region";

export const getTranslatedTag = (elem, keyName: RegionKeys = "name") => {
  const key = `tag.${elem.replace(/ /g, "_")}.${keyName}`;
  return i18n.exists(key) ? i18n.t(key) : elem.replace(/_/g, " ");
};

interface GetRegionsArrayInput {
  region: string;
  tagName: string;
}

export const getRegionsArray = (inputArray: GetRegionsArrayInput[]): Region[] =>
  inputArray.reduce((result, item) => {
    const existingRegion = result.find((entry) => entry.region === item.region);

    if (!existingRegion) {
      result.push({ region: item.region, categories: [] });
    }

    return result;
  }, []);
