import React from "react";
import FlaggedIcon from "../../../../assets/icons/FlaggedIcon";
import { useAppSelector } from "../../../../store";
import { Wrapper } from "./FlaggedItem.styles";
import { Report } from "../../../../models/ReportModel";
import { useSetInteractionsMutation } from "../../../../store/user/UserApi";
import { selectReportUserInteractions } from "../../../../store/reports/ReportsSelectors";
import { useUserData } from "../../../../hooks/useUserData";

interface FlaggedItemProps {
  report: Report;
  isMobileFlow?: boolean;
};

export const FlaggedItem = ({ report: { report_id }, isMobileFlow }: FlaggedItemProps) => {
  const { userId } = useUserData();
  const { viewed, flagged } = useAppSelector(selectReportUserInteractions(report_id))
  const [setInteractions] = useSetInteractionsMutation();

  const onClickHandler = (e) => {
    if (isMobileFlow) {
      e.stopPropagation();
    }
    setInteractions({ userId, entity_id: report_id, entity_type: 'report', viewed, flagged: !flagged });
  };

  return (
    <Wrapper onClick={onClickHandler}>
      <FlaggedIcon flagged={flagged} />
    </Wrapper>
  );
};
