import { ButtonText, Stack } from "@secuis/ccp-react-components";
import React, { useContext, useState } from "react";
import { deleteReport } from "../../../../utilities/userUtils";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import { NotificationsContext } from "../../../../context/notifications/NotificationsContext";
import { NotificationTypes } from "../../../../context/notifications/NotificationMessage";
import { useTranslation } from "react-i18next";

export const DeleteAttachment = ({ userToken, url, resetFile }) => {
  const { t } = useTranslation();
  const { toast } = useContext(NotificationsContext);
  const [isOpen, setIsOpen] = useState(false);

  const performDelete = async () => {
    try {
      await deleteReport(userToken, url);
      resetFile();
      toast({ title: t("Reports.deleteAttachmentNotification"), type: NotificationTypes.Confirmation, icon: "Success" });
    } catch (e) {
      toast({ title: t("settings.notification.failed"), type: NotificationTypes.Error, icon: "Error" });
    }
  };
  return (
    <Stack justifyContent="flex-end">
      <ButtonText icon="Delete" onClick={() => setIsOpen(true)} />
      <ConfirmationModal isOpen={isOpen} setIsOpen={setIsOpen} action={performDelete} title={t("Reports.deleteAttachment")} />
    </Stack>
  );
};
