import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectErrors } from "../store/app/AppSelectors";
import { NotificationsContext } from "../context/notifications/NotificationsContext";
import { NotificationTypes } from "../context/notifications/NotificationMessage";
import { ErrorModel } from "../models/ErrorModel";
import { clearErrors } from "../store/app/AppSlice";
import { useAppDispatch } from "../store";

export const useAppErrorNotification = () => {
  const { toast } = useContext(NotificationsContext);
  const errors = useSelector(selectErrors);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (errors?.length > 0) {
      try {
        (errors as unknown as Array<ErrorModel>).forEach((error) => {
          toast({ title: t(error.title), type: NotificationTypes.Error, icon: "Error" });
        });
      } finally {
        dispatch(clearErrors());
      }
    }
  }, [errors, dispatch, toast, t]);
};
