// TODO: this file will be removed once we start using RTK Query
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchReportsFromBe } from "../../utilities/reportUtils";

export interface FetchReportsParams {
  userToken: string;
  page: number;
  url: string;
  lang: string;
}

export const fetchReportsThunk = createAsyncThunk("reports/fetchReports", async (params: FetchReportsParams & { couldCreateReport: boolean }) => {
  return await fetchReportsFromBe({ ...params });
});
