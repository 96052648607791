import { createSlice } from '@reduxjs/toolkit'
import { FetchStatus, Report } from "../../models/ReportModel";
import { fetchDraftsThunk } from './DraftsThunkActions';

export type DraftsState = {
  data: Report[];
  status: FetchStatus;
};

const initialState: DraftsState = {
  data: [],
  status: 'idle'
};

export const draftsSlice = createSlice({
  name: 'drafts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDraftsThunk.pending, (state, action) => {
        state.status = action.meta.requestStatus
      })
      .addCase(fetchDraftsThunk.rejected, (state, action) => {
        state.status = action.meta.requestStatus
      })
      .addCase(fetchDraftsThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = action.meta.requestStatus;
      })
  }
});
