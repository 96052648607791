import { createGlobalStyle } from "styled-components/macro";
import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";

export const SpecificStyles = createGlobalStyle`
     a {
        text-decoration: none;
        outline: none;
    }

    .navigationLink, .activeNavigationLink {
        display: flex;
        align-items: center;
        margin: ${Spacing.M}px 0;
        width: 100%;
        padding-left: ${Spacing.M}px;
        color: ${Palette.Gray100};

        flex-direction: column;
        padding-left: 0;
        margin: ${Spacing.M}px 0;

        svg {
            margin-right: 0;
        }
 
        @media (max-width: ${Breakpoints.XS}) {
            width: 100%;
            margin: 0;

            svg {
                margin-bottom: ${Spacing.XS}px;
            }
        }
    }

    .activeNavigationLink {
        width: 100%;
        color: ${Palette.Purple300};
        border-left: 2px solid ${Palette.Purple300};
        padding-left: calc(${Spacing.M}px - 2px);

        svg {
            color: ${Palette.Purple300};
        }
 
        padding-left: 0;        

        @media (max-width: ${Breakpoints.XS}) {
            border-left: none;
        }
    }
`;
