import React, { FC } from "react";
import { useUser } from "../../../store/user/UserHooks";
import { UserInfoText } from "./styled";

interface UserNameProps {
  userId: string;
}

export const UserName: FC<UserNameProps> = ({ userId }) => {
  const { userName } = useUser(userId);

  return (
    <UserInfoText small color="secondary" style={{ overflow: "hidden" }} title={userName}>
      {userName}
    </UserInfoText>
  );
};
