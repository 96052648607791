import { ButtonIcon, Headline, OverlayCentered, Stack, Subtitle } from "@secuis/ccp-react-components";
import React, { FC, HTMLAttributes, PropsWithChildren, ReactNode } from "react";
import { Content, Wrapper } from "./ModalExtraLarge.styles";

interface ModalExtraLargeProps extends PropsWithChildren<Omit<HTMLAttributes<HTMLDivElement>, 'title'>> {
  /**
   * Flag indicating whether modal is rendered or not.
   */
  isOpen: boolean;
  /**
   * Title text.
   */
  title?: string | ReactNode;
  /**
   * Optional subtitle text.
   */
  subtitle?: string;
  /**
   * Callback function executed when clicking on X icon.
   */
  onClose: () => void;
}

const ModalExtraLarge: FC<ModalExtraLargeProps> = ({ isOpen, onClose, title, subtitle, children, ...rest }) => {
  const isTitleString = typeof title === "string";

  return isOpen ? (
    <OverlayCentered>
      <Wrapper {...rest} role="dialog">
        <Stack direction="column" gap="XS" mb="S">
          <Stack justifyContent={title ? "space-between" : "flex-end"} alignItems={isTitleString ? "center" : "flex-start"}>
            {title && <>{isTitleString ? <Headline bold>{title}</Headline> : title}</>}
            <ButtonIcon mode="stateless" icon="Close" iconSize="L" onClick={onClose} />
          </Stack>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Stack>
        <Content>{children}</Content>
      </Wrapper>
    </OverlayCentered>
  ) : null;
};

export default ModalExtraLarge;
