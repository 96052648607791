import React from "react";
import { type SVGProps } from "react";

export const InternalIcon = ({ ...rest }: SVGProps<SVGSVGElement>) => (
  <svg name="internalIcon" width="24" height="24" {...rest} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.25 21H4.5C3.675 21 3 20.325 3 19.5V3.75C3 3.3375 3.3375 3 3.75 3C4.1625 3 4.5 3.3375 4.5 3.75V19.5H20.25C20.6625 19.5 21 19.8375 21 20.25C21 20.6625 20.6625 21 20.25 21Z"
      fill="#FD566C"
    />
    <path
      d="M8.24813 16.5L17.2481 16.5C17.6606 16.5 17.9981 16.1625 17.9981 15.75C17.9981 15.3375 17.6606 15 17.2481 15L10.0556 15L20.7806 4.2825C21.0731 3.99 21.0731 3.5175 20.7806 3.225C20.6306 3.075 20.4431 3 20.2481 3C20.0531 3 19.8656 3.075 19.7156 3.2175L8.99813 13.9425L8.99813 6.75C8.99813 6.3375 8.66062 6 8.24813 6C7.83562 6 7.49813 6.3375 7.49813 6.75L7.49813 15.75C7.49813 16.1625 7.83563 16.5 8.24813 16.5Z"
      fill="#FD566C"
    />
  </svg>
);
