import { Palette } from "@secuis/ccp-react-components";
import React from "react";

const FlaggedIcon = ({ flagged }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" name="FlaggedIcon">
    {flagged ? (
      <>
        <rect width="32" height="32" rx="16" fill={Palette.Red400} />
        <path
          d="M9.375 24.125C9.375 24.6773 9.82272 25.125 10.375 25.125C10.9273 25.125 11.375 24.6773 11.375 24.125L9.375 24.125ZM11.375 24.125L11.375 7.87891L9.375 7.87891L9.375 24.125L11.375 24.125Z"
          fill={Palette.White}
        />
        <path d="M18.8309 12.6439L20.1085 15.625H10.75V8.875H20.1085L18.8309 11.8561L18.662 12.25L18.8309 12.6439Z" stroke={Palette.White} strokeWidth="2" />
      </>
    ) : (
      <>
        <rect x="1" y="1" width="30" height="30" rx="15" stroke={Palette.Navy600} strokeWidth="2" />
        <path
          d="M9.375 24.1244C9.375 24.6767 9.82272 25.1244 10.375 25.1244C10.9273 25.1244 11.375 24.6767 11.375 24.1244L9.375 24.1244ZM11.375 24.1244L11.375 7.87305L9.375 7.87305L9.375 24.1244L11.375 24.1244Z"
          fill={Palette.Navy400}
        />
        <path d="M18.8309 12.6439L20.1085 15.625H10.75V8.875H20.1085L18.8309 11.8561L18.662 12.25L18.8309 12.6439Z" stroke={Palette.Navy400} strokeWidth="2" />
      </>
    )}
  </svg>
);

export default FlaggedIcon;
