import { useStore } from "react-context-hook";

export const useUserData = () => {
  const [userData, setUserData] = useStore("userData", {
    name: "",
    isEmbedded: true,
    isEmbeddedInMobile: false,
    isUserNameFilled: false,
    roles: [],
    email: "",
    isLoaded: false,
  });

  const [preferences, setPreferences] = useStore("preferences", null);
  const [lang, setLang] = useStore("lang", "");
  const [userId, setUserId] = useStore("userId", "");
  const [userToken, setUserTokenDeprecated] = useStore("userToken", "");

  return {
    userData,
    setUserData,
    userId,
    setUserId,
    userToken,
    setUserTokenDeprecated,
    preferences,
    setPreferences,
    lang,
    setLang,
  };
};
