import { ButtonCompact, Stack, Text } from "@secuis/ccp-react-components";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { WrappedPreview } from "./WrappedPreview";
import { ShareReportModal } from "../List/ReportActions/ShareReportModal";
import { useRoles } from "../../../hooks/useRoles";
import { Report } from "../../../models/ReportModel";
import { Content, Wrapper, TopSection } from "./PreviewReport.styles";

interface PreviewReportProps {
  report: Report;
  isLoading: boolean;
}

export const PreviewReport: FC<PreviewReportProps> = ({ report, isLoading }) => {
  const { t } = useTranslation();
  const { canShareReport } = useRoles();
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

  return (
    <Wrapper>
      <Stack justifyContent="flex-end" mr="XS" mb="XXS">
        <Text uppercase micro color="secondary">
          {t("Reports.previewTitle")}
        </Text>
      </Stack>
      <Content>
        {canShareReport && !!report?.report_id && (
          <TopSection>
            <ButtonCompact icon="Share" mode="outlined" title={t("shareReport.title")} onClick={() => setShareModalOpen(!shareModalOpen)}>
              {t("shareReport.title")}
            </ButtonCompact>
          </TopSection>
        )}
        <WrappedPreview isLoading={isLoading} report={report} />
      </Content>
      <ShareReportModal report={report} shareModalOpened={shareModalOpen} setShareModalOpen={setShareModalOpen} />
    </Wrapper>
  );
};
