import { Badge, Stack, Text } from "@secuis/ccp-react-components";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { getColor } from "../../../../utilities/sectionUtils";
import { SECTION_STATUS } from "../../../../models/SectionModel";
import { useSection } from "../../../../hooks/useSection";

interface DraftStatusProps {
  reportType: string;
  sections?: any;

}

export const DraftStatus: FC<DraftStatusProps> = ({ reportType, sections = null }) => {
  const { t } = useTranslation();
  const { getSections } = useSection();
  const all = getSections(reportType).length;

  const ready = sections !== null ? sections.filter((s) => s.is_approved || s.is_done).length : 0;
  const onGoing = sections !== null ? sections.length : 0;

  let status = "";
  if (ready > 0 && all > 0 && all === ready) {
    status = SECTION_STATUS.READY;
  } else if (all > 0 && onGoing) {
    status = SECTION_STATUS.ONGOING;
  } else {
    status = SECTION_STATUS.EMPTY;
  }

  return (
    <Stack direction="column" alignItems="flex-start">
      <Text small color="secondary" uppercase>
        {t("Reports.draft.status")}
      </Text>
      {status && sections !== null && (
        <Badge label={status} mode="outlined" color={getColor(status)} style={{ opacity: status === SECTION_STATUS.EMPTY ? 0.7 : 1 }} />
      )}
    </Stack>
  );
};
