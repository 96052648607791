import { REPORT_STATUS, type Report } from "../models/ReportModel";
import { DraftsFetchParams } from "../store/drafts/DraftsThunkActions";
import { FetchReportsParams } from "../store/reports/ReportsThunkActions";
import { categorizeDates } from "./dateUtils";
import { FetchSectionsProps } from "./types";
import { getDataFromBE } from "./userUtils";

const filterReportsByStatus = (reports: Report[], couldCreateReport) =>
  couldCreateReport ? reports : reports.filter((report) => report.status === REPORT_STATUS.DELIVERED);

export const fetchReportsFromBe = async ({ userToken, page, url, lang }: FetchReportsParams) => {
  if(!url) {
    return;
  }
  const query = `/reports?${url}&limit=20&&lang=${lang}&&page=${page}`;
  return await getDataFromBE(userToken, query);
};

export const fetchSectionsRequest = async ({ userToken, reportId }: FetchSectionsProps) => {
  const query = `/reports/${reportId}/sections`;
  const fetchedSections = await getDataFromBE(userToken, query);
  return fetchedSections?.data;
};

export const fetchDraftsRequest = async ({ userToken }: DraftsFetchParams) => {
  const query = `/reports?report_status=${REPORT_STATUS.DRAFT}`;
  const fetchedDrafts = await getDataFromBE(userToken, query);
  return fetchedDrafts?.data;
};

export const getPreparedData = ({ couldCreateReport, fetchedReports }) => {
  const dateType = "schedule";
  const reports = filterReportsByStatus(fetchedReports, couldCreateReport);
  const categorizedDates = categorizeDates(reports, dateType);
  return { reports, categorizedDates };
};

export const transformNames = (element) => {
  if (!element) return "";
  return element.toLowerCase().replace(/ /g, "_");
};
