import { ButtonIcon } from "@secuis/ccp-react-components";
import React from "react";
import { Container } from "./FiltersButton.styles";

export interface IFiltersButtonProps {
  isExpanded?: boolean;
  toggleExpanded?: Function;
}

export const FiltersButton: React.FC<IFiltersButtonProps> = ({ isExpanded, toggleExpanded }) => {
  return (
    <Container data-testid="filter-button" onClick={() => toggleExpanded(!isExpanded)}>
      <ButtonIcon icon={isExpanded ? "Close" : "Filter"} iconSize="L" mode="stateless" />
    </Container>
  );
};
