import React, { FC } from "react";
import { ContentElem, Wrapper } from "./PreviewContentElem.styles";

interface PreviewContentElemProps {
  htmlContent: string[];
  $isMobile: boolean;
  isFromReportList: boolean;
}

export const PreviewContentElem: FC<PreviewContentElemProps> = ({ htmlContent, $isMobile, isFromReportList }) => {
  return (
    <Wrapper $isMobile={$isMobile} isFromReportList={isFromReportList}>
      {htmlContent?.length > 0 &&
        htmlContent?.map((html, i) => <ContentElem key={`preview${html[0]}${i}`} dangerouslySetInnerHTML={{ __html: html }} />)}
    </Wrapper>
  );
};
