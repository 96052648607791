import React from "react";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import AuthenticatedApp from "./components/AuthenticatedApp";
import { NotFoundPage } from "./components/NotFoundPage";
import { Reports } from "./components/Reports/List/Reports";
import { BriefsList } from "./components/Briefs/BriefsList";
import { Faq } from "./components/Faq/Faq";
import { Settings } from "./components/Settings/Settings";
import { CreateSection } from "./components/Reports/Create/Sections/CreateSection";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { ItemsList } from "./components/Items/List/ItemsList";
import { CreateItem } from "./components/Items/Create/CreateItem";
import { CreateReportSections } from "./components/Reports/Create/Sections/CreateReportSections";

export const getRouter = ({ couldCreateReport, canSeeBriefs, scrollRef }) => createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AuthenticatedApp scrollRef={scrollRef} />} errorElement={<NotFoundPage />}>
      <Route path="/" element={<Reports />} />
      <Route path="/reports" element={<Reports />} />
      <Route element={<ProtectedRoute hasAccess={canSeeBriefs} />}>
        <Route path="/briefs" element={<BriefsList />} />
      </Route>
      <Route path="/faq" element={<Faq />} />
      <Route path="/settings" element={<Settings />} />
      <Route element={<ProtectedRoute hasAccess={couldCreateReport} />}>
        <Route path="/items" element={<ItemsList />} />
        <Route path="/createItem" element={<CreateItem scrollRef={scrollRef} />} />
        <Route path="/editItem/:itemId" element={<CreateItem scrollRef={scrollRef} />} />
        <Route path="/createReport" element={<CreateReportSections />} />
        <Route path="/createSection" element={<CreateSection />} />
      </Route>
    </Route>
  )
);