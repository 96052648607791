import styled from "styled-components/macro";
import { Spacing } from "@secuis/ccp-react-components";

export const ActionsHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${Spacing.L}px;
  padding: ${Spacing.M}px 0;
  flex-wrap: wrap;
`;
