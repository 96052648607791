import mixpanel from "mixpanel-browser";
import React, { createContext, useEffect, useState } from "react";

interface MixpanelProperties {
  Success?: boolean;
  SiteId?: string;
  ConnectionCountry?: string;
}

export interface TrackingObject {
  track: (name: string, params?: MixpanelProperties) => void;
}

export const TrackingContext = createContext({} as TrackingObject);

export const TrackingProvider = ({ children }: any) => {
  const [mixpanelInitiated, setMixpanelInitiated] = useState<boolean>(false);

  useEffect(() => {
    if (!mixpanelInitiated) {
      mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`, {
        api_host: "https://mxpproxy.mysecuritas.com",
      });

      mixpanel.track("Application Loaded", {});

      setMixpanelInitiated(true);
    }
  }, []);

  function track(name: string, params?: MixpanelProperties) {
    if (!mixpanelInitiated) {
      return;
    }
    if (!params) {
      mixpanel.track(name, {});
    } else {
      mixpanel.track(name, params);
    }
  }

  const value = React.useMemo(
    () => ({
      track,
    }),
    [track]
  );

  return <TrackingContext.Provider value={value}>{children}</TrackingContext.Provider>;
};
