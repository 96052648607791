import styled, { css } from "styled-components/macro";
import { Breakpoints, Stack } from "@secuis/ccp-react-components";

export const ApplicationContainer = styled(Stack)`
  height: 100%;

  @media (max-width: ${Breakpoints.XS}) {
    flex-direction: column-reverse;
  }
`;

interface HomePageContainerProps {
  shouldShowScroll: boolean;
}

export const HomePageContainer = styled(Stack).attrs({
  direction: "column",
})<HomePageContainerProps>`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  scrollbar-width: auto;

  ${(props) =>
    props.shouldShowScroll &&
    css`
      overflow-y: auto;
    `};

  @media (max-width: ${Breakpoints.XS}) {
    height: unset;
    min-height: calc(100% - 95px); // TODO: what is this workaround for?
  }
`;

export const HomePageContent = styled.div`
  height: 100%;
`;

export const Content = styled(Stack).attrs({
  direction: "column",
  padding: ["L", "L", "M"]
})`
  height: 100%;
`;

export const ContentSections = styled(Content)`
  max-width: 1900px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;
