import { Palette, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

interface WrapperProps {
  isDisabled: boolean;
}

export const Wrapper = styled(Stack).attrs({
  p: "M",
})<WrapperProps>`
  background-color: ${Palette.Navy750};
  border-radius: 4px;
  max-width: 1900px;

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          gap: 80px;
        `
      : css`
          justify-content: space-between;
        `}
`;

export const ScheduleContainer = styled(Stack).attrs({
  alignItems: "center",
})`
  // TODO: verify those strange values on the designs
  gap: 11px;
  margin-top: 3px;
`;
