import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import qs from "qs";
import { IRootState } from "../store";

export const baseQuery = (baseUrl = process.env.REACT_APP_API_ENDPOINT) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as IRootState;
      const { userToken } = state.user;

      if (userToken) {
        headers.set("Authorization", `Bearer ${userToken}`);
      }
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");

      return headers;
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
