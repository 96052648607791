import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { keyframes } from "styled-components";
import { ContentProps } from "../../DatePicker/ScheduleTimeComponent.styles";

export const ItemsWrapper = styled.div`
  padding: 40px;
  height: auto;

  @media (max-width: ${Breakpoints.XL}) {
    padding: ${Spacing.M}px;
  }
  @media (max-width: ${Breakpoints.S}) {
    padding: ${Spacing.S}px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

const inAnimation = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
`;

export const Content = styled.div<ContentProps>`
  position: absolute;
  animation: ${inAnimation} 100ms ease-in-out;
  ${({ offsetTop }) => Number.isInteger(offsetTop) && `top: ${offsetTop}px;`}
  ${({ offsetLeft }) => Number.isInteger(offsetLeft) && `left: ${offsetLeft}px;`}
  ${({ offsetRight }) => Number.isInteger(offsetRight) && `right: ${offsetRight}px;`}

  @media (max-width: ${Breakpoints.XS}) {
    top: 0;
    left: 0;
    right: unset;
  }
`;

export const ScheduleContainer = styled(Stack).attrs({
  alignItems: "center",
  mt: "XXS",
})`
  gap: 11px;
`;

export const ItemSaveStatusWrapper = styled.span`
  white-space: nowrap;
`;
