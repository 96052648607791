import { Button, Tooltip } from "@secuis/ccp-react-components";
import React, { useContext, useEffect, useState } from "react";
import { isDateFromFuture } from "../../../../utilities/dateUtils";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import { useUserData } from "../../../../hooks/useUserData";
import { useTranslation } from "react-i18next";
import { NotificationsContext } from "../../../../context/notifications/NotificationsContext";
import { updateReport } from "../../../../utilities/userUtils";
import { NotificationTypes } from "../../../../context/notifications/NotificationMessage";
import { useRoles } from "../../../../hooks/useRoles";

const ApproveSections = ({
  isChanged,
  scheduleDate,
  savedReport,
  isApproved,
  setIsApproved,
  isScheduleDateFromFuture,
  uploadedScheduleDate,
  reportType,
  areSectionsInProgress,
  htmlContent,
  areTagsEmpty,
}) => {
  const { t } = useTranslation();
  const { userId, userToken } = useUserData();
  const { toast } = useContext(NotificationsContext);
  const { canApprove } = useRoles();
  const [isReportEmpty, setIsReportEmpty] = useState(false);
  const [isTooShortNotice, setIsTooShortNotice] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [tooltip, setTooltip] = useState("");

  useEffect(() => {
    if (scheduleDate) {
      setIsTooShortNotice(!isDateFromFuture(scheduleDate));
    }
  }, [scheduleDate]);

  useEffect(() => {
    setIsReportEmpty(!htmlContent?.map((e) => e).join(""));
  }, [htmlContent]);

  useEffect(() => {
    if (areSectionsInProgress) {
      setTooltip(t("Report.approve.tooltipAllsection"));
    } else if (!isScheduleDateFromFuture || isTooShortNotice) {
      setTooltip(t("CreateReport.tooltipScheduleDate"));
    } else if (areTagsEmpty) {
      setTooltip(t("CreateReport.tooltipSelectTags"));
    } else if (isReportEmpty) {
      setTooltip(t("CreateReport.tooltipEmptyContent"));
    } else {
      setTooltip("");
    }
  }, [areSectionsInProgress, isScheduleDateFromFuture, isTooShortNotice, htmlContent, areTagsEmpty, isReportEmpty, t]);

  const approveReport = async () => {
    try {
      const content = {
        report_id: savedReport,
        schedule_time: uploadedScheduleDate.toISOString(),
        approval_user: userId,
      };

      await updateReport(userToken, `/publish`, content, "POST", false);
      toast({
        title: t("CreateReport.toast.approveReport", {
          type: reportType,
        }),
        type: NotificationTypes.Confirmation,
        icon: "Success",
      });
      setIsApproved(true);
    } catch (e) {
      toast({ title: t("CreateReport.toast.failed"), type: NotificationTypes.Error, icon: "Error" });
    }
  };

  return (
    <>
      <Tooltip message={tooltip} place="top" color="purple">
        <Button
          data-testid="ApproveButton"
          mode="contained"
          color="accent"
          disabled={
            !canApprove ||
            !savedReport ||
            !scheduleDate ||
            isChanged ||
            isApproved ||
            !isScheduleDateFromFuture ||
            isTooShortNotice ||
            areSectionsInProgress ||
            areTagsEmpty ||
            isReportEmpty
          }
          onClick={async () => {
            const check = !isDateFromFuture(scheduleDate);
            if (!check) {
              setApproveModal(true);
            }
            setIsTooShortNotice(!isDateFromFuture(scheduleDate));
          }}
        >
          {t("Section.publishReport")}
        </Button>
      </Tooltip>

      <ConfirmationModal isOpen={approveModal} setIsOpen={setApproveModal} title="CreateReport.approveModal.description" action={approveReport} />
    </>
  );
};

export default ApproveSections;
