import { Icon, Text } from "@secuis/ccp-react-components";
import { isSameDay } from "date-fns";
import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getDateWithoutTimezone, getLocalDate } from "../../../utilities/dateUtils";
import { CalendarWrapper } from "../../DatePicker/DateNavigator.styles";
import { ScheduleTimeComponentItem } from "./ScheduleTimeComponentItem";
import { ScheduleContainer } from "./styled";

export const EventDatePicker = () => {
  const { t, i18n } = useTranslation();
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  const { field: startDateField } = useController({ name: "start_date_of_event", rules: { required: true } });
  const { field: endDateField } = useController({ name: "end_date_of_event", rules: { required: true } });

  const startDate = startDateField.value ? getDateWithoutTimezone(startDateField.value) : undefined;
  const endDate = endDateField.value ? getDateWithoutTimezone(endDateField.value) : undefined;

  const [label, setLabel] = useState("");
  const hideCalendar = () => {
    setIsCalendarOpen(false);
  };

  const showCalendar = () => {
    setIsCalendarOpen(true);
  };

  useEffect(() => {
    if (!startDate && !endDate) {
      setLabel("");
    } else if (startDate && endDate) {
      if (isSameDay(startDate, endDate)) {
        setLabel(getLocalDate(startDate, i18n.language, false));
      } else {
        setLabel(getLocalDate(startDate, i18n.language, false) + " - " + getLocalDate(endDate, i18n.language, false));
      }
    } else {
      setLabel(getLocalDate(startDate, i18n.language, false));
    }
  }, [startDate, endDate, i18n.language]);

  return (
    <ScheduleContainer>
      <Icon size="L" variant="Calendar" color="secondary" />
      <Text data-testid="eventDate" bold>
        {label || t("CreateReport.schedule.selectDate")}
      </Text>
      <CalendarWrapper data-testid="DateNavigator-Component">
        <ScheduleTimeComponentItem
          data-testid="DateNavigator-ShowCalendar"
          showCalendar={showCalendar}
          hideCalendar={hideCalendar}
          isOpen={isCalendarOpen}
          startDate={startDate}
          setStartDate={(date) => startDateField.onChange(date.toISOString())}
          setEndDate={(date) => endDateField.onChange(date.toISOString())}
          endDate={endDate}
          lang={i18n.language}
        />
      </CalendarWrapper>
    </ScheduleContainer>
  );
};
