import React, { FC } from "react";
import { PreviewContentElem } from "./PreviewContentElem";
import { Wrapper } from "./Preview.styles";

interface PreviewProps {
  htmlContent: string[];
  mobileSelected: boolean;
  isFromReportList: boolean;
}

export const Preview: FC<PreviewProps> = ({ htmlContent, mobileSelected, isFromReportList }) => (
  <Wrapper data-testid="preview" $isMobile={mobileSelected} isFromReportList={isFromReportList}>
    <PreviewContentElem $isMobile={mobileSelected} isFromReportList={isFromReportList} htmlContent={htmlContent} />
  </Wrapper>
);
