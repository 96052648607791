import { Box, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Wrapper = styled(Box).attrs({
  p: "M",
  mt: "M",
})`
  background-color: ${Palette.Navy750};
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: ${Spacing.L}px;
`;
