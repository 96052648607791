import { Badge, Box, LoadingIndicator, Stack, Text } from "@secuis/ccp-react-components";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { InternalIcon } from "../../../assets/icons/InternalIcon";
import { Item } from "../../../models/ItemModel";
import { useUser } from "../../../store/user/UserHooks";
import { getDateOfEvent } from "../../../utilities/Item.helpers";
import { getColor } from "../../../utilities/sectionUtils";
import { getTranslatedTag } from "../../../utilities/tagsUtils";
import { ItemButtons } from "./ItemButtons";
import { Parameter } from "./Parameter";
import { ItemContainer, ItemOthers, ItemParameters, ItemTags, ParametersWrapper } from "./styled";
import { getLocalDate } from "../../../utilities/dateUtils";

export const TRENDS = {
  uppcomming: {
    icon: "TrendUp",
    color: "success",
  },
  cancel: {
    icon: "TrendDown",
    color: "error",
  },
  ongoing: {
    icon: "TrendMiddle",
    color: "neutral",
  },
};

interface ItemLongProps {
  itemData: Item;
  last: boolean;
}

export const ItemLong: FC<ItemLongProps> = ({ itemData, last }) => {
  const { userName: authorName, isLoading } = useUser(itemData.added_by);
  const { t, i18n } = useTranslation();
  const location = `${itemData?.settlement ?? "-"}, ${itemData?.country ?? "-"} `;

  return (
    <ItemContainer data-testid="long-item-element" selected={false}>
      <ItemParameters>
        <Parameter label={t("Item.dateOfEvent")} icon="Calendar" large item>
          {getDateOfEvent(itemData)}
        </Parameter>
        <Parameter label={t("Items.location")} icon="Location" item>
          {location}
        </Parameter>
        <Parameter label={t("Item.lastUpdated")} icon="Time" item>
          {getLocalDate(itemData.date_last_updated, i18n.language, true)}
        </Parameter>
        <Parameter label={t("NewReport.labelAuthor")} icon="Edit" noWrapper item>
          {isLoading ? (
            <LoadingIndicator size="S" />
          ) : (
            <Text color="white" title={authorName} truncate>
              {authorName}
            </Text>
          )}
        </Parameter>
        <Parameter label={t("Item.repeat")} icon="Status" small item>
          {itemData.recurrence ? "Yes" : "No"}
        </Parameter>
        <Parameter label={t("Item.threatLevel")} icon="Show" medium item>
          {!itemData.threat_level ? " - " : t(`Items.threatLevels.${itemData.threat_level}`)}
        </Parameter>
        <Parameter
          label={t("Reports.draft.status")}
          icon={TRENDS[itemData.event_status]?.icon}
          iconColor={TRENDS[itemData.event_status]?.color}
          medium
          item
        >
          {t(`Items.eventStatus.${itemData.event_status}`)}
        </Parameter>
      </ItemParameters>
      <ItemOthers>
        <ParametersWrapper>
          <Stack flex="1" mb="XS">
            <Parameter label={t("Item.title")} icon="Image" disabled={!itemData.image_url} defWidth={false} truncate={false} item>
              {itemData.title}
            </Parameter>
          </Stack>
          <ItemTags>
            {[...new Set(itemData.threat_risk_categories)].map((tag) => (
              <Badge key={`${itemData.id}-${tag}`} label={getTranslatedTag(tag)} />
            ))}
            {itemData.item_status && (
              <Box marginLeft={"S"}>
                <Badge label={t(`Section.status.${itemData.item_status}`)} mode="outlined" color={getColor(itemData.item_status)} />
              </Box>
            )}
            <Box marginLeft={"L"}>{itemData.is_internal && <InternalIcon />}</Box>
          </ItemTags>
        </ParametersWrapper>
        <ItemButtons id={itemData.id} last={last} status={itemData.item_status} internalOnly={itemData.is_internal} />
      </ItemOthers>
    </ItemContainer>
  );
};
