import { ButtonIcon } from "@secuis/ccp-react-components";
import React from "react";

import { FilterWrapper } from "./FilterMobileButton.styles";

export interface IFiltersButtonProps {
  isExpanded?: boolean;
  toggleExpanded?: Function;
}

export const FilterMobileButton: React.FC<IFiltersButtonProps> = ({ isExpanded, toggleExpanded }) => {
  return (
    <FilterWrapper onClick={() => toggleExpanded(!isExpanded)} data-testid="filter-wrapper">
      <ButtonIcon mode="stateless" icon="Filter" iconSize="L" color="primary" />
    </FilterWrapper>
  );
};
