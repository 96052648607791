import { Background, Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Wrapper = styled(Stack).attrs({
  direction: "column",
})`
  background-color: ${Background.overlay};
  border-radius: 16px;

  width: 1130px;
  height: 100%;
  max-height: calc(100% - ${2 * Spacing.M}px);
  margin: ${Spacing.M}px;
  padding: ${Spacing.M}px;

  @media (max-width: ${Breakpoints.M}) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: unset;
    margin: unset;
    padding: ${Spacing.M}px ${Spacing.S}px ${Spacing.L}px ${Spacing.S}px;
  }
`;

export const Content = styled(Stack).attrs({
    direction: "column"
})`
  flex: 1;
  overflow: auto;

  // we want to show scroll on the outside of the modal

  margin: 0 -${Spacing.M}px;
  padding: 0 ${Spacing.M}px;

  @media (max-width: ${Breakpoints.M}) {
    margin: 0 -${Spacing.S}px;
    padding: 0 ${Spacing.S}px;
  }
`;
