import { Button } from "@secuis/ccp-react-components";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { PreviewModal } from "./PreviewModal";

interface PreviewButtonProps {
  htmlContent: string[];
  title?: string;
  disabled?: boolean;
}

const PreviewButton: FC<PreviewButtonProps> = ({ htmlContent, title = "NewReport.buttonPreview", disabled }) => {
  const { t } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);

  return (
    <>
      <Button disabled={disabled} icon="Risk" mode="contained" color="accent" onClick={() => setShowPreview(true)}>
        {t(title)}
      </Button>
      <PreviewModal htmlContent={htmlContent} isOpen={showPreview} setIsOpen={setShowPreview} />
    </>
  );
};

export default PreviewButton;
