import React from "react";
import { useTranslation } from "react-i18next";
import { useReportDataSections } from "../../../../hooks/useReportDataSections";
import { Section } from "./Section";
import { SectionsListComponent } from "./styled";

export const SectionsList = ({ savedReport, sections }) => {
  const { i18n } = useTranslation();
  const { id, type } = useReportDataSections({ i18n });

  return (
    <SectionsListComponent savedReport={savedReport}>
      {sections?.map((e) => (
        <Section key={e.name} elem={e} isExistingReport={!!savedReport} report_id={id || savedReport} report_type={type}></Section>
      ))}
    </SectionsListComponent>
  );
};
