import { Modal } from "@secuis/ccp-react-components";
import React from "react";
import { useTranslation } from "react-i18next";

interface IConfirmationModalProps { 
  isOpen: boolean;
  setIsOpen: (v) => void;
  action: () => void;
  title: string;
  subtitle?: string;
  yesColor?: "error" | "accent";
};

export const ConfirmationModal = ({ isOpen, setIsOpen, action, title, subtitle, yesColor = "accent" }: IConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      size="S"
      title={t(title)}
      subtitle={subtitle}
      onClose={() => setIsOpen(false)}
      actions={[
        {
          children: t("common.yes"),
          onClick: () => {
            setIsOpen(false);
            action();
          },
          color: yesColor
        },
        {
          children: t("common.no"),
          onClick: () => setIsOpen(false),
        },
      ]}
    >
      <div></div>
    </Modal>
  );
};
