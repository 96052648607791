import { REPORT_STATUS } from "../models/ReportModel";
import { LANGUAGE_KEY } from "../constants/generalSettings";
import { SignalColor } from "@secuis/ccp-react-components";
export const areTagsChanged = (array1, array2) => {
  if (array1?.length !== array2?.length) {
    return false;
  }

  const array1String = JSON.stringify(array1.map((obj) => ({ ...obj, categories: obj.categories.sort() })));
  const array2String = JSON.stringify(array2.map((obj) => ({ ...obj, categories: obj.categories.sort() })));
  return array1String !== array2String;
};
const getName = (elem) => {
  return elem ? ` ${elem}` : "";
};
export const isUserNameFilled = (data) => {
  if (!data?.name) {
    return false;
  }
  const { first, last } = data.name;

  return !!(first && last);
};
export const getUserName = (data) => {
  if (!data) {
    return "-";
  }
  const name = data.name;
  const firstName = name?.first || "";
  const middle = name?.middle || "";
  const lastname = name?.last || "";
  if (firstName || lastname) {
    return `${firstName}${getName(middle)}${getName(lastname)}`;
  }

  return getEmail(data);
};

export const getEmail = (data) => {
  return data?.emails?.[0]?.address || "-";
};

export const getReportColor = (status): SignalColor => {
  switch (status) {
    case REPORT_STATUS.DELIVERED:
      return "success";

    case REPORT_STATUS.PUBLISHED:
      return "high";

    case REPORT_STATUS.DRAFT:
      return "medium";

    default:
      return "success";
  }
};

export const getLanguage = (preferences) => {
  if (!preferences?.[LANGUAGE_KEY]) return "en";
  return preferences[LANGUAGE_KEY];
};

export const getReportContent = (report) => {
  if (!report?.sections?.length) return "";

  return report.sections.sort((a, b) => a.section_order - b.section_order)?.map((section) => section.content || "") || "";
};

export const getSectionsContent = (sections) => {
  return getReportContent({ sections });
};

export const getReportLang = (report) => {
  return report?.sections?.[0]?.lang || "en";
};

export const findStringDifference = (str1, str2) => {
  const maxLength = Math.max(str1.length, str2.length);
  const difference = [];

  for (let i = 0; i < maxLength; i++) {
    if (str1[i] !== str2[i]) {
      difference.push(str1[i]);
    }
  }

  return difference.join("");
};

export const mergeArrays = (arr1, arr2) => {
  if (!arr1 || !arr2) return [];
  const merged = arr1.map((item1) => {
    const matchingItem2 = arr2.find((item2) => item2.region === item1.region);
    return matchingItem2 ? { region: item1.region, categories: matchingItem2.categories } : item1;
  });

  const uniqueItemsFromArray2 = arr2.filter((item2) => !arr1.some((item1) => item1.region === item2.region));
  return merged.concat(uniqueItemsFromArray2);
};