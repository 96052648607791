import { ChipChoice } from "@secuis/ccp-react-components";
import React, { useEffect, useState } from "react";
import { getTranslatedTag } from "../../../../utilities/tagsUtils";

export const TagChip = ({ elem, selectChanged, init }) => {
  const [selected, setSelected] = useState(init);

  useEffect(() => {
    setSelected(init);
  }, [init]);

  return <ChipChoice data-testid={`Tag-${elem}`} label={getTranslatedTag(elem)} selected={selected} onSelect={() => selectChanged(elem, !selected)} />;
};
