import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ItemState {
  isDraft: boolean;
}

const initialState: ItemState = {
  isDraft: false,
};

export const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    setIsDraft: (state, action: PayloadAction<boolean>) => {
      state.isDraft = action.payload;
    },
  },
});

export const itemActions = itemSlice.actions;
