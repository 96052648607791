import { Tab, Tabs, Text } from "@secuis/ccp-react-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTags } from "../../../../hooks/useTags";
import { getTranslatedTag } from "../../../../utilities/tagsUtils";
import { TabComponent } from "./TabComponent";
import { useTagsAndRegions } from "../../../../hooks/useTagsAndRegions";
import { Wrapper } from "./TagsComponent.styles";

export const TagsComponent = ({ initIndex = 0, reportType = "", region = null }) => {
  const { tags } = useTags();
  const { t } = useTranslation();
  const { resetTags } = useTagsAndRegions();
  const [shouldHideAll, setShouldHideAll] = useState(false);

  const tagsFiltered = tags.filter((tag) => (region === "main" ? true : tag.region === region));

  useEffect(() => {
    setShouldHideAll(initIndex === -1 && (reportType === "Global Weekly" || reportType === "Global Daily"));
  }, [initIndex, reportType]);

  useEffect(() => {
    return () => resetTags();
  }, []);

  return (
    <Wrapper hideTabs={initIndex >= 0} shouldHideAll={shouldHideAll}>
      <Text bold>{t("CreateReport.AddTags")}</Text>
      <Tabs value={initIndex === -1 ? 0 : initIndex} onChange={function noRefCheck() {}}>
        {(tagsFiltered as any).map((elem) => (
          <Tab key={elem.region} label={getTranslatedTag(elem.region, "short.region")}>
            <TabComponent initIndex={initIndex} region={elem.region} section={region} />
          </Tab>
        ))}
      </Tabs>
    </Wrapper>
  );
};
