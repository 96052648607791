import { Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const ContactUsDropdown = styled.div`
  margin-top: ${Spacing.M}px;
`;

export const TextareaWrapper = styled.div`
  margin-top: ${Spacing.M}px;
`;

export const FinalStep = styled.div`
  margin-bottom: ${Spacing.L}px;
`;

export const TitleWrapper = styled.div`
  margin-bottom: ${Spacing.XS}px;
`;

export const ModalWrapped = styled.div`
  h3 {
    max-width: 80%;
    word-wrap: break-word;
  }
`;
