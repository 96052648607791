import { Box, Dropdown } from "@secuis/ccp-react-components";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { SettingsValue } from "./Settings";

interface LanguageDropdownProps {
  keyName: string;
  initValue: string;
  updatePreferences: (key: string, content: SettingsValue, value: string | boolean) => Promise<void>;

}

export const LanguageDropdown: FC<LanguageDropdownProps> = ({ keyName, initValue, updatePreferences }) => {
  const langs = ["sv", "nl", "de", "da", "en", "es", "fr"]; // TODO: move this hardcoded list to a config
  const [value, setValue] = useState(initValue);
  const { t } = useTranslation();

  const onChangeHandler = async (value: string) => {
    setValue(value);
    await updatePreferences(keyName, { value, type: "string" }, value);
  }

  return (
    <Box mt="S">
      <Dropdown
        data-testid="dropdown"
        onChange={onChangeHandler}
        options={langs.map((elem) => ({ title: t(`dropdown.language.${elem}`), value: elem }))}
        sheetCancelLabel="Cancel"
        value={value}
      />
    </Box>
  );
};
