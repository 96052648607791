import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { ButtonIcon, Datepicker, Input, Link, Overlay, Spacing, useClickOutside } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { format, getHours, getMinutes, isToday, startOfTomorrow } from "date-fns";
import { UTCDate } from "@date-fns/utc";
import { Content, Wrapper } from "./styled";
import { ScheduleTime, TimeInputs, TimeSeparator } from "../../DatePicker/ScheduleTimeComponent.styles";

interface ScheduleTimeComponentRemindProps {
  showCalendar: () => void;
  hideCalendar: () => void;
  isOpen: boolean;
  align?: "right" | "left";
  setStartDate: (date: Date) => void;
  startDate: Date;
  remindDate: Date;
  setRemindDate: (date: Date) => void;
  lang: string;

}

export const ScheduleTimeComponentRemind: FC<ScheduleTimeComponentRemindProps> = ({
  showCalendar,
  hideCalendar,
  isOpen,
  align = "left",
  setStartDate,
  startDate,
  remindDate,
  setRemindDate,
  lang,
}) => {
  const { t } = useTranslation();
  const [endDate, setEndDate] = useState<Date | null>(startOfTomorrow());
  const elemRef = useRef<HTMLDivElement>(null);
  const [offsetTop, setOffsetTop] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(null);
  const [offsetRight, setOffsetRight] = useState(null);

  const [hours, setHours] = useState("00");
  const [invalidHours, setInvalidHours] = useState(false);
  const [invalidMinutes, setInvalidMinutes] = useState(false);
  const [minutes, setMinutes] = useState("00");

  const cleanupDates = (date) => {
    if (date) {
      setMinutes(format(date, "mm"));
      setHours(format(date, "HH"));
      setInvalidHours(false);
      setInvalidMinutes(false);
      setStartDate(date);
      setEndDate(date);
    }
  };

  const clearValues = () => {
    cleanupDates(remindDate);
    hideCalendar();
  };

  const { nodeRef } = useClickOutside<HTMLDivElement>(clearValues);

  const validateMinutes = (value) => {
    const isMinutesFromPast = isToday(startDate) && getHours(new Date()) === Number(hours) ? getMinutes(new Date()) > Number(value) : false;

    setInvalidMinutes(isNaN(Number(value)) || Number(value) > 59 || isMinutesFromPast);
  };
  const validateHours = (value) => {
    const isHourFromPast = isToday(startDate) ? getHours(new Date()) > Number(value) : false;
    setInvalidHours(isNaN(Number(value)) || Number(value) > 24 || isHourFromPast);
  };
  useEffect(() => {
    if (isOpen) {
      const { bottom, left, right } = elemRef.current.getBoundingClientRect();
      setOffsetTop(Math.round(bottom + Spacing.XXS));
      if (align === "left") {
        setOffsetLeft(Math.round(left));
      } else {
        setOffsetRight(Math.round(window.innerWidth - right));
      }
    }
  }, [isOpen, align]);

  useEffect(() => {
    validateHours(hours);
    if (getHours(new Date()) === Number(hours)) {
      validateMinutes(minutes);
    }
  }, [startDate]);

  useEffect(() => {
    validateMinutes(minutes);
  }, [hours, startDate]);

  const handleHoursChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    setHours(input.value);
    validateHours(input.value);
  };

  const handleMinutesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    setMinutes(input.value);
    validateMinutes(input.value);
  };

  const handleSave = () => {
    const finalDate = new UTCDate(startDate?.getFullYear(), startDate?.getMonth(), startDate?.getDate(), Number(hours), Number(minutes), 0);
    setRemindDate(finalDate);
    cleanupDates(finalDate);
    hideCalendar();
  };
  return (
    <Wrapper>
      <div ref={elemRef}>
        <Link
          data-testid="Reminder"
          onClick={() => {
            if (remindDate) {
              setRemindDate(null);
              cleanupDates(startOfTomorrow());
            } else {
              showCalendar();
            }
          }}
        >
          {remindDate ? t("NewReport.buttonCancel") : t("NewReport.edit")}
        </Link>
      </div>
      {isOpen && (
        <Overlay>
          <Content ref={nodeRef} offsetTop={offsetTop} offsetLeft={offsetLeft} offsetRight={offsetRight}>
            <Datepicker
              mode="singleMonth"
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              blockPastBeforeDays={0}
              hideCalendar={() => {}}
              saveButtonLabel={t("Filter.date")}
              languageCode={lang}
              isDaySelection
            />
            <ScheduleTime>
              <TimeInputs>
                <Input inputMode="numeric" maxLength={2} value={hours.toString()} onChange={handleHoursChange} invalid={invalidHours}></Input>
                <TimeSeparator>:</TimeSeparator>
                <Input inputMode="numeric" maxLength={2} value={minutes.toString()} onChange={handleMinutesChange} invalid={invalidMinutes}></Input>
              </TimeInputs>
              <ButtonIcon
                data-testid="SaveScheduleTime"
                icon="ValidatedCheck"
                mode="contained"
                onClick={handleSave}
                disabled={invalidHours || invalidMinutes}
              />
            </ScheduleTime>
          </Content>
        </Overlay>
      )}
    </Wrapper>
  );
};
