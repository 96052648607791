import { Box, ComboBox, Input, Text, Textarea } from "@secuis/ccp-react-components";
import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store";
import { selectIsDraft } from "../../../store/item/ItemSelectors";
import { selectMetadata } from "../../../store/items/ItemsSelectors";
import { GeolocationInput } from "./GeolocationInput";

const ItemDescription = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { countries } = useAppSelector(selectMetadata);
  const isDraft = useAppSelector(selectIsDraft);
  const imageUrl = useWatch({ name: "image_url" });

  return (
    <>
      {imageUrl && (
        <Controller
          name="image_source_link"
          control={control}
          rules={{ required: !!imageUrl && !isDraft }}
          defaultValue=""
          render={({ field, fieldState: { invalid } }) => (
            <Input invalid={invalid} autoComplete="off" label={t("Items.imageCredits")} placeholder={t("Items.imageCredits")} {...field} />
          )}
        />
      )}
      <Controller
        name="title"
        control={control}
        rules={{ required: true }}
        defaultValue=""
        render={({ field, fieldState: { invalid } }) => (
          <Input invalid={invalid} autoComplete="off" label={t("Items.titleItem")} placeholder={t("Items.titleItem")} {...field} />
        )}
      />
      <Controller
        name="sub_header"
        control={control}
        defaultValue=""
        render={({ field }) => <Input autoComplete="off" label={t("Items.subheader")} placeholder={t("Items.subheader")} {...field} />}
      />
      <Controller
        name="details"
        control={control}
        rules={{ required: !isDraft }}
        defaultValue=""
        render={({ field, fieldState: { invalid } }) => (
          <Textarea invalid={invalid} label={t("Items.itemContent")} placeholder={t("Items.itemContent")} {...field} />
        )}
      />
      <Controller
        name="assessment_or_comment"
        control={control}
        defaultValue=""
        rules={{ required: !isDraft }}
        render={({ field, fieldState: { invalid } }) => (
          <Textarea invalid={invalid} rows={4} label={t("Items.intelligence")} placeholder={t("Items.intelligence")} {...field} />
        )}
      />
      <Controller
        name="advisory"
        control={control}
        defaultValue=""
        render={({ field }) => <Textarea rows={4} label={t("Items.advisory")} placeholder={t("Items.advisory")} {...field} />}
      />
      <Box mt="XS">
        <Text bold>{t("Items.location")}</Text>
      </Box>
      <GeolocationInput />
      <Controller
        name="country"
        control={control}
        defaultValue=""
        rules={{ required: !isDraft }}
        render={({ field, fieldState: { invalid } }) => (
          <ComboBox
            label={t("Items.country")}
            placeholder={t("Items.country")}
            data-testid="typeDropdown"
            options={countries.map(({ name }) => ({ value: name, title: name }))}
            invalid={invalid}
            {...field}
          />
        )}
      />
      <Controller
        name="state_or_province"
        control={control}
        defaultValue=""
        render={({ field }) => <Input autoComplete="off" label={t("Items.state")} placeholder={t("Items.state")} {...field} />}
      />
      <Controller
        name="settlement"
        rules={{ required: !isDraft }}
        control={control}
        defaultValue=""
        render={({ field, fieldState: { invalid } }) => (
          <Input invalid={invalid} autoComplete="nope" label={t("Items.city")} placeholder={t("Items.city")} {...field} />
        )}
      />
    </>
  );
};

export default ItemDescription;
