
import { IRootState } from "../../store";

export const selectBriefs = ({ briefs: { data } }: IRootState) => data;

export const selectBriefsStatus = ({ briefs: { status } }: IRootState) => status;

export const selectBriefsShowMore = ({ briefs: { showMore } }: IRootState) => showMore;

export const selectBriefsPage = ({ briefs: { page } }: IRootState) => page;

export const selectBriefsFilters = ({ briefs: { filters } }: IRootState) => filters;
