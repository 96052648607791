import React, { FC } from "react";
import { Modal } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

interface CancelModalSectionsProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  navigateTo: () => void;
  description?: string;
}

export const CancelModalSections: FC<CancelModalSectionsProps> = ({ isOpen, setIsOpen, navigateTo, description }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      size="S"
      onClose={() => setIsOpen(false)}
      actions={[
        {
          children: t("CreateReport.CancelModal.removeAction"),
          color: "error",
          onClick: () => {
            setIsOpen(false);
            navigateTo();
          },
        },
        {
          children: t("CreateReport.CancelModal.saveAction"),
          onClick: () => setIsOpen(false),
        },
      ]}
    >
      <div>{description ?? t("CreateReport.CancelModal.description")}</div>
    </Modal>
  );
};
