import { CaseReducerActions } from "@reduxjs/toolkit";
import {
  Box,
  Breakpoints,
  Button,
  ButtonText,
  ComboBox,
  Dropdown,
  DropdownCheckbox,
  Headline,
  Input,
  Separator,
  Stack,
  Text,
  useHasMaxWidth,
} from "@secuis/ccp-react-components";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BriefFiltersModel } from "../../../../models/BriefFiltersModel";
import { ItemFiltersModel } from "../../../../models/ItemFiltersModel";
import { ITEM_STATUS } from "../../../../models/ItemModel";
import { IRootState, useAppDispatch, useAppSelector } from "../../../../store";
import { selectBriefsFilters } from "../../../../store/briefs/BriefsSelectors";
import { BriefsState, briefsActions } from "../../../../store/briefs/BriefsSlice";
import { itemsApi } from "../../../../store/items/ItemsApi";
import { selectItemsFilters, selectMetadata } from "../../../../store/items/ItemsSelectors";
import { ItemsState, itemsActions } from "../../../../store/items/ItemsSlice";
import { DateNavigator } from "../../../DatePicker/DateNavigator";
import { FiltersButton } from "./FiltersButton";
import { DropdownWrapper, DropdownWrapperFixed, MainLine, ResetButtonWrapper, StickyWrapper, Wrapper } from "./FiltersContent.styles";
import { briefsApi } from "../../../../store/briefs/BriefsApi";

export interface IFiltersContentProps {
  isExpanded?: boolean;
  toggleExpanded?: Function;
  storeType?: "items" | "briefs";
}
export type CheckItem = {
  [key: string]: boolean;
};

type FilterType = {
  actions: CaseReducerActions<
    {
      resetFilters: (state: BriefsState | ItemsState) => void;
      setFilter: (
        state: BriefsState | ItemsState,
        action: {
          payload: any;
          type: string;
        }
      ) => void;
    },
    "items" | "briefs"
  >;
  filters: (value: IRootState) => ItemFiltersModel | BriefFiltersModel;
  fetch: any;
};

type StoreFiltersType = {
  items: FilterType;
  briefs: FilterType;
};

export const ItemsFiltersContent: React.FC<IFiltersContentProps> = ({ isExpanded, toggleExpanded, storeType = "items" }) => {
  const storeFiltersTypes: StoreFiltersType = {
    items: {
      actions: itemsActions,
      filters: selectItemsFilters,
      fetch: itemsApi.endpoints.getItems.initiate,
    },
    briefs: {
      actions: briefsActions,
      filters: selectBriefsFilters,
      fetch: briefsApi.endpoints.getBriefs.initiate,
    },
  };
  const { t } = useTranslation();
  const isMobile = useHasMaxWidth(Breakpoints.XS);
  const { countries, region_daily, threat_risk_categories, threat_levels, sectors } = useAppSelector(selectMetadata);
  const dispatch = useAppDispatch();
  const filters = useAppSelector(storeFiltersTypes[storeType].filters);

  const [isChanged, setIsChanged] = useState(false);
  const [offset, setOffset] = useState(0);
  const didMount = useRef(false);
  const dateRef = useRef(null);

  useEffect(() => {
    if (didMount.current) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
      didMount.current = true;
    }
  }, [filters]);

  const openHandler = () => {
    const { top } = dateRef.current.getBoundingClientRect();
    setOffset(top);
  };

  const applyFilters = () => {
    dispatch(storeFiltersTypes[storeType].fetch({ page: 1, filters }, { forceRefetch: true }));
    setIsChanged(false);
  };

  const handleBlurOnCountry = (e) => e.target.value === "" && dispatch(storeFiltersTypes[storeType].actions.setFilter({ country: "" }));

  const onResetFilters = () => {
    didMount.current = false;
    dispatch(storeFiltersTypes[storeType].actions.resetFilters());
    dispatch(storeFiltersTypes[storeType].fetch({ page: 1 }, { forceRefetch: true }));
  };

  return (
    <Wrapper isExpanded={isExpanded} isMobile={isMobile}>
      {(!isMobile || (isMobile && isExpanded)) && (
        <StickyWrapper>
          <MainLine>
            <Headline bold>{t("Items.filter.title")}</Headline>
            <FiltersButton isExpanded={isExpanded} toggleExpanded={toggleExpanded} />
          </MainLine>
        </StickyWrapper>
      )}
      {isExpanded && (
        <Stack direction="column" justifyContent="space-between">
          <Separator />
          <DropdownWrapper>
            <Box pb="S" pt="S">
              <Text bold>{t("Filter.freeSearch")}</Text>
            </Box>
            <Input
              autoComplete="off"
              label={t("Filter.freeSearch")}
              placeholder={t("Filter.placeholder.freeSearch")}
              onChange={(event) => dispatch(storeFiltersTypes[storeType].actions.setFilter({ free_search: event.target.value }))}
              value={filters.free_search || ""}
            />
          </DropdownWrapper>
          { storeType === "items" && 
            <DropdownWrapperFixed>
              <Box pb="S" pt="S">
                <Text bold>{t("Filter.status")}</Text>
              </Box>
              <DropdownCheckbox
                onChange={(val) => dispatch(storeFiltersTypes[storeType].actions.setFilter({ item_statuses: val }))}
                options={Object.values(ITEM_STATUS).map((status) => ({ value: status, title: t(`Filter.statuses.${status}`) }))}
                sheetCancelLabel="Cancel"
                values={filters.item_statuses || []}
                placeholder={t("Filter.placeholder.all")}
              />
            </DropdownWrapperFixed>
          }
          <DropdownWrapperFixed>
            <Box pb="S" pt="S">
              <Text bold>{t("Filter.threat_level")}</Text>
            </Box>
            <DropdownCheckbox
              onChange={(val) => dispatch(storeFiltersTypes[storeType].actions.setFilter({ threat_levels: val }))}
              options={threat_levels?.map(({ name }) => ({ value: name, title: t(`Items.threatLevels.${name}`) })) || []}
              sheetCancelLabel="Cancel"
              values={filters.threat_levels || []}
              placeholder={t("Filter.placeholder.all")}
            />
          </DropdownWrapperFixed>
          <DropdownWrapper>
            <Box pb="S" pt="S">
              <Text bold>{t("Items.filter.region")}</Text>
            </Box>
            <Dropdown
              onChange={(val) => dispatch(storeFiltersTypes[storeType].actions.setFilter({ region: val }))}
              options={region_daily.toSorted((a, b) => a.localeCompare(b)).map((region) => ({ value: region, title: t(`tag.${region}.region`) }))}
              value={filters.region}
              placeholder={t("Items.filter.placeholder.region")}
              maxHeight={450}
              sheetCancelLabel="Cancel"
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Box pb="S" pt="S">
              <Text bold>{t("Filter.country")}</Text>
            </Box>
            <ComboBox
              onChange={(val) => dispatch(storeFiltersTypes[storeType].actions.setFilter({ country: val }))}
              options={countries.map(({ name }) => ({ value: name, title: name }))}
              value={filters.country}
              placeholder={t("Items.filter.placeholder.country")}
              onBlur={handleBlurOnCountry}
              maxHeight={isMobile ? 320 : 450}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Box pb="S" pt="S">
              <Text bold>{t("Filter.city")}</Text>
            </Box>
            <Input
              autoComplete="off"
              label={t("Filter.city")}
              placeholder={t("Filter.placeholder.city")}
              onChange={(event) => dispatch(storeFiltersTypes[storeType].actions.setFilter({ settlement: event.target.value }))}
              value={filters.settlement || ""}
            />
          </DropdownWrapper>
          <DropdownWrapperFixed>
            <Box pb="S" pt="S">
              <Text bold>{t("Filter.tagsAndCategories")}</Text>
            </Box>
            <DropdownCheckbox
              onChange={(val) => dispatch(storeFiltersTypes[storeType].actions.setFilter({ threat_risk_categories: val }))}
              options={threat_risk_categories?.map(({ name }) => ({ value: name, title: t(`tag.${name}.name`) })) || []}
              sheetCancelLabel="Cancel"
              values={filters.threat_risk_categories || []}
              placeholder={t("filter.selectCategories")}
            />
          </DropdownWrapperFixed>
          <DropdownWrapperFixed>
            <Box pb="S" pt="S">
              <Text bold>{t("Filter.sector")}</Text>
            </Box>
            <DropdownCheckbox
              onChange={(val) => dispatch(storeFiltersTypes[storeType].actions.setFilter({ sector_specific: val }))}
              options={sectors?.map(({ name }) => ({ value: name, title: name })) || []}
              sheetCancelLabel="Cancel"
              values={filters.sector_specific || []}
              placeholder={t("Filter.sector")}
            />
          </DropdownWrapperFixed>
          <DropdownWrapper ref={dateRef}>
            <Box pb="S" pt="S">
              <Text bold>{t("Filter.date")}</Text>
            </Box>
            <DateNavigator
              offset={offset}
              openHandler={openHandler}
              startDate={filters?.start_date ? new Date(filters.start_date) : null}
              endDate={filters?.end_date ? new Date(filters.end_date) : null}
              placeholder={t("CreateReport.schedule.selectDate")}
              setStartDate={(val) => dispatch(storeFiltersTypes[storeType].actions.setFilter({ start_date: !!val ? format(val, "yyyy-MM-dd") : "" }))}
              setEndDate={(val) => dispatch(storeFiltersTypes[storeType].actions.setFilter({ end_date: !!val ? format(val, "yyyy-MM-dd") : "" }))}
            />
          </DropdownWrapper>
          <ResetButtonWrapper>
            <Button mode="contained" color="accent" disabled={!isChanged} onClick={applyFilters}>
              {t("filter.applyFilter")}
            </Button>
            <ButtonText color="accent" onClick={onResetFilters}>
              {t("Filter.reset")}
            </ButtonText>
          </ResetButtonWrapper>
        </Stack>
      )}
    </Wrapper>
  );
};
