import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserModel } from "../../models/UserModel";
import { userApi } from "./UserApi";

export type UserState = {
  userToken?: string;
  byId: Record<string, UserModel>;
};

export const initialState: UserState = {
  byId: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserToken: (state, action: PayloadAction<string>) => {
      state.userToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
      state.byId[action.meta.arg.originalArgs] = action.payload;
    });
  },
});

export const { setUserToken } = userSlice.actions;
