import { Breakpoints, Icon, IconType, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { CountIndicatorContainer, CountIndicatorItem, NavigationLinkStyled } from "./NavigationLink.styles";
import React from "react";

interface Props {
  label: string;
  to: string;
  icon: IconType;
  isExpanded: boolean;
  counterValue?: number;
  isActive?: boolean;
}

const NavigationLink = ({ label, to, icon, isExpanded, counterValue, isActive = false }: Props) => {
  const isSmallScreen = useHasMaxWidth(Breakpoints.M);
  return (
    <NavigationLinkStyled to={to} $isActive={isActive}>
      <Stack alignItems={isSmallScreen ? "flex-start" : "center"} justifyContent={isSmallScreen ? "flex-end" : "space-between"} gap="XS">
        <Stack direction={isSmallScreen ? "column" : "row"} alignItems="center" gap="XS">
          <Icon variant={icon} size="L" />
          {isExpanded && (
            <Text micro={isSmallScreen} bold>
              {label}
            </Text>
          )}
        </Stack>
        {counterValue > 0 && (
          <CountIndicatorContainer isExpanded={isExpanded} isSmallScreen={isSmallScreen}>
            <CountIndicatorItem
              data-testid="CountIndicatorItem"
              count={counterValue}
              position={isSmallScreen || !isExpanded ? "absolute" : "relative"}
              color="accent"
            />
          </CountIndicatorContainer>
        )}
      </Stack>
    </NavigationLinkStyled>
  );
};

export default NavigationLink;
