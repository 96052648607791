import { ButtonCompact, Icon, LoadingIndicator, Text } from "@secuis/ccp-react-components";
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationTypes } from "../../../context/notifications/NotificationMessage";
import { NotificationsContext } from "../../../context/notifications/NotificationsContext";
import { Item } from "../../../models/ItemModel";
import { useLazyGetItemQuery, usePutImageMutation } from "../../../store/items/ItemsApi";
import { File, Wrapper } from "./ImageUpload.styles";
import { useFormContext } from "react-hook-form";

interface ImageUploadProps {
  item: Item;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ item }) => {
  const {
    formState: { isDirty },
  } = useFormContext();
  const [putImage, { isLoading }] = usePutImageMutation();
  const [fetchItem] = useLazyGetItemQuery();
  const { t } = useTranslation();
  const { toast } = useContext(NotificationsContext);
  const [isDragging, setIsDragging] = useState(false);
  const dragAreaRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const uploadFile = async (file: File) => {
    const binaryArray = await file.arrayBuffer();
    const binaryString = Array.from(new Uint8Array(binaryArray))
      .map((byte) => String.fromCharCode(byte))
      .join("");
    const base64String = btoa(binaryString);
    const typeSuffix = file.type.replace("image/", "");

    putImage({ itemId: item.id, data: base64String, type: typeSuffix })
      .unwrap()
      .then(() => {
        fetchItem(item.id); // refresh item in RTK cache by reloading it
      })
      .catch(() => true);
  };

  const validateFile = async (files: FileList) => {
    if (!files || files.length === 0) {
      toast({
        title: t("Item.imageUpload.validation.emptyFile"),
        type: NotificationTypes.Error,
        icon: "Warning",
      });
      return false;
    }

    if (files.length > 1) {
      toast({
        title: t("Item.imageUpload.validation.tooManyFiles"),
        type: NotificationTypes.Error,
        icon: "Warning",
      });
      return false;
    }

    if (!/^image\//.test(files[0].type)) {
      toast({
        title: t("Item.imageUpload.validation.wrongFileType", { fileName: files[0].name }),
        type: NotificationTypes.Error,
        icon: "Warning",
      });
      return false;
    }

    uploadFile(files[0]);
  };

  const onBrowse = () => {
    inputRef.current?.click();
  };

  const onSelect = (e: ChangeEvent<HTMLInputElement>) => {
    validateFile(e.target.files);
  };

  const onDrop = (e: DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    validateFile(e.dataTransfer.files);
  };

  const onDragEnter = (e: Event) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const onDragLeave = (e: Event) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const onPrevent = (e: Event) => {
    e.preventDefault();
  };

  useEffect(() => {
    if(isDirty) {
      return;
    }
    window.addEventListener("dragover", onPrevent);
    window.addEventListener("drop", onPrevent);
    dragAreaRef.current?.addEventListener("dragover", onDragEnter);
    dragAreaRef.current?.addEventListener("dragenter", onDragEnter);
    dragAreaRef.current?.addEventListener("dragleave", onDragLeave);
    dragAreaRef.current?.addEventListener("drop", onDrop);

    return () => {
      window.removeEventListener("dragover", onPrevent);
      window.removeEventListener("drop", onPrevent);
      dragAreaRef.current?.removeEventListener("dragover", onDragEnter);
      dragAreaRef.current?.removeEventListener("dragenter", onDragEnter);
      dragAreaRef.current?.removeEventListener("dragleave", onDragLeave);
      dragAreaRef.current?.removeEventListener("drop", onDrop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return (
    <Wrapper isDragging={isDragging} ref={dragAreaRef}>
      {isLoading ? (
        <LoadingIndicator size="L" />
      ) : (
        <>
          <Icon variant="Image" color="secondary" />
          <Text small color="secondary">
            {t("Item.imageUpload.description")}
          </Text>
          <ButtonCompact mode="contained" color="accent" onClick={onBrowse} disabled={isDirty}>
            {t("Item.imageUpload.buttonText")}
          </ButtonCompact>
        </>
      )}
      <File ref={inputRef} onChange={onSelect} />
    </Wrapper>
  );
};

export default ImageUpload;
