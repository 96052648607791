import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@secuis/ccp-react-components";
import { ButtonWrapperCancel } from "./styled";
import { CancelModalSections } from "./CancelModalSections";

interface CancelSectionsProps {
  title?: string;
  isContentChanged: boolean;
  navigateTo: () => void;
}

const CancelSections: FC<CancelSectionsProps> = ({ isContentChanged, title = "Section.reportList", navigateTo }) => {
  const { t } = useTranslation();
  const [cancelModal, setCancelModal] = useState(false);

  const onClickHandler = () => {
    if (isContentChanged) {
      setCancelModal(true);
    } else {
      navigateTo();
    }
  };

  return (
    <ButtonWrapperCancel>
      <Button icon="ArrowBack" mode="outlined" onClick={onClickHandler}>
        {t(title)}
      </Button>
      <CancelModalSections isOpen={cancelModal} setIsOpen={setCancelModal} navigateTo={navigateTo} />
    </ButtonWrapperCancel>
  );
};

export default CancelSections;
