import { Button } from "@secuis/ccp-react-components";
import React, { FC, useContext, useState } from "react";
import { deleteReport } from "../../../../utilities/userUtils";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import { NotificationsContext } from "../../../../context/notifications/NotificationsContext";
import { NotificationTypes } from "../../../../context/notifications/NotificationMessage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface DeleteReportProps {
  reportId: string;
  userToken: string;
}

export const DeleteReport: FC<DeleteReportProps> = ({ reportId, userToken }) => {
  const { t } = useTranslation();
  const { toast } = useContext(NotificationsContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const performDelete = async () => {
    try {
      await deleteReport(userToken, `/reports/${reportId}`);

      toast({ title: t("Reports.deleteReportNotfication"), type: NotificationTypes.Confirmation, icon: "Success" });
      navigate("/reports", { replace: true });
    } catch (e) {
      toast({ title: t("settings.notification.failed"), type: NotificationTypes.Error, icon: "Error" });
    }
  };
  return (
    <>
      <div>
        <Button icon="Delete" mode="outlined" color="error" onClick={() => setIsOpen(true)}>
          {t("button.delete")}
        </Button>
      </div>
      <ConfirmationModal isOpen={isOpen} setIsOpen={setIsOpen} action={performDelete} title={t("Reports.deleteReport")} />
    </>
  );
};
