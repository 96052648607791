import React, { useContext, useState } from "react";
import { ButtonText, Checkbox, ChipFilter, EmailRegex, Input, Modal, Stack } from "@secuis/ccp-react-components";
import { useUserData } from "../../../../hooks/useUserData";
import { NotificationsContext } from "../../../../context/notifications/NotificationsContext";
import { NotificationTypes } from "../../../../context/notifications/NotificationMessage";
import { shareReports } from "../../../../utilities/userUtils";
import { type Report } from "../../../../models/ReportModel";
import { useTranslation } from "react-i18next";
import { ChipsStack } from "./styled";

interface ShareReportModalProps {
  report: Report;
  shareModalOpened: boolean;
  setShareModalOpen: (value: boolean) => void;
}

export const ShareReportModal = ({ report, shareModalOpened, setShareModalOpen }: ShareReportModalProps) => {
  const [email, setEmail] = useState<string>("");
  const [receivers, setReceivers] = useState<string[]>([]);
  const [sendEmailToMe, setSendEmailToMe] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userData, userToken, lang } = useUserData();
  const { toast } = useContext(NotificationsContext);
  const { t } = useTranslation();

  const onModalClose = () => {
    setError(null);
    setEmail("");
    setReceivers([]);
    setShareModalOpen(false);
    setSendEmailToMe(false);
  };

  const handleIconClick = () => {
    handleEnter({ key: "Enter" });
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      const reg = new RegExp(EmailRegex);
      if (reg.exec(email)) {
        setReceivers([...receivers, email]);
        setEmail("");
        setError(null);
      } else {
        setError(t("shareReport.email.incorrect"));
      }
    }
  };

  const handleOnBlur = () => {
    setError(null);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const removeEmail = (email) => {
    const newList = receivers.filter((e) => e !== email);
    setReceivers(newList);
  };

  const handleSendEmailToMe = () => {
    setSendEmailToMe(!sendEmailToMe);
  };

  const clearAll = () => {
    setReceivers([]);
  };

  const sendEmails = () => {
    const newList = sendEmailToMe ? [...receivers, userData.email] : [...receivers];
    if (newList.length === 0) {
      toast({ title: t("shareReport.email.emptyList"), type: NotificationTypes.Warning, icon: "Info" });
      return;
    }

    shareReports(userToken, newList, report.report_id, lang)
      .then(() => {
        setShareModalOpen(false);
        setReceivers([]);
        setSendEmailToMe(false);
        toast({ title: t("shareReport.shareSuccess"), type: NotificationTypes.Confirmation, icon: "Success" });
      })
      .catch(() => {
        toast({ title: t("shareReport.shareFailed"), type: NotificationTypes.Error, icon: "Error" });
      });
  };

  return (
    <Modal
      title={t("shareReport.title")}
      size="L"
      isOpen={shareModalOpened}
      onClose={onModalClose}
      actions={[
        {
          children: t("shareReport.title"),
          onClick: sendEmails,
        },
      ]}
    >
      <Stack direction="column" gap="XS">
        <Input
          icon="Add"
          title="Press Enter or click icon to add email"
          onIconClick={handleIconClick}
          error={error}
          placeholder={t("shareReport.email.placeholder")}
          value={email}
          onBlur={handleOnBlur}
          onChange={handleChange}
          onKeyUp={handleEnter}
        />
        <ChipsStack $chipsNumber={receivers.length} gap="XS" flexWrap="wrap" data-testid="stack-chips">
          {receivers.map((email) => (
            <ChipFilter key={email} label={email} onRemove={() => removeEmail(email)} />
          ))}
          {receivers.length > 0 && (
            <Stack alignItems="center">
              <ButtonText onClick={clearAll}>
                {t("shareReport.buttonClearAll")}
              </ButtonText>
            </Stack>
          )}
        </ChipsStack>
        <Checkbox id="sendEmailToMe" label={t("shareReport.emailToMe")} checked={sendEmailToMe} onChange={handleSendEmailToMe} />
      </Stack>
    </Modal>
  );
};
