import { UTCDate } from "@date-fns/utc";
import { Icon, Text } from "@secuis/ccp-react-components";
import { startOfTomorrow } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CalendarWrapper } from "../../DatePicker/DateNavigator.styles";
import { ScheduleTimeComponentRemind } from "./ScheduleTimeComponentRemind";
import { ScheduleContainer } from "./styled";
import { getLocalDate } from "../../../utilities/dateUtils";

export const DataDetailsComponentItemRemind = ({ remindDate, setRemindDate }) => {
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState<Date | null>(startOfTomorrow());
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  const [title, setTitle] = useState("");
  const hideCalendar = () => {
    setIsCalendarOpen(false);
  };

  const showCalendar = () => {
    setIsCalendarOpen(true);
  };

  useEffect(() => {
    if (!remindDate) {
      setTitle("");
    } else {
      const newDate = new UTCDate(remindDate);
      setTitle(getLocalDate(newDate, i18n.language));
    }
  }, [remindDate, i18n.language]);

  return (
    <ScheduleContainer>
      <Icon size="L" variant="Notification" color="secondary"></Icon>
      <Text data-testid="reminderTitle" bold>
        {title || t("Items.noReminder")}
      </Text>

      <CalendarWrapper data-testid="DateNavigator-Component">
        <ScheduleTimeComponentRemind
          data-testid="DateNavigator-ShowCalendar"
          showCalendar={showCalendar}
          hideCalendar={hideCalendar}
          isOpen={isCalendarOpen}
          remindDate={remindDate}
          setRemindDate={setRemindDate}
          lang={i18n.language}
          startDate={startDate}
          setStartDate={setStartDate}
        />
      </CalendarWrapper>
    </ScheduleContainer>
  );
};
