import { Palette, Text, Title } from "@secuis/ccp-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { CustomAccordion, ListContainer } from "./styled";
import { Content } from "../styled";

export const Faq = () => {
  const { t } = useTranslation();
  
  type TranslationItem = {
    title: string;
    content: string;
  };

  return (
    <Content>
      <Title>{t("Faq.title")}</Title>
      <Text>{t("Faq.subtitle")}</Text>
      <ListContainer>
      {
        Object
          .values<TranslationItem>(t("Faq.question", { defaultValue: [], returnObjects: true }))
          .map((question: TranslationItem) => 
            <CustomAccordion
              key={question.title}
              icon="QuestionAskHelp"
              title={question.title}
              color={Palette.Navy300}
            >
              {question.content}
            </CustomAccordion>)
      }
      </ListContainer>
    </Content>
  );
};
