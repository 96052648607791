import { useStore } from "react-context-hook";

export const useSchedule = () => {
  const [schedules, setSchedules] = useStore("schedules", null);

  const getSchedule = (reportType: string) => {
    return schedules?.find((e: any) => e.name.toLowerCase() === reportType.toLowerCase())?.next_schedule;
  };

  const uploadSchedules = (schedules) => {
    setSchedules(schedules);
  };

  return { schedules, uploadSchedules, getSchedule };
};