// TODO: this file will be removed once we start using RTK Query
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDraftsRequest } from "../../utilities/reportUtils";

export interface DraftsFetchParams {
  userToken: string;
}

export const fetchDraftsThunk = createAsyncThunk("drafts/fetchDrafts", async (params: DraftsFetchParams) => {
  return await fetchDraftsRequest({ ...params });
});
