import { GlobalStyles, LoadingIndicator } from "@secuis/ccp-react-components";
import React, { Suspense, useRef } from "react";
import { withStore } from "react-context-hook";
import { RouterProvider } from "react-router-dom";
import AuthGate from "./context/AuthGate";
import { i18nextInit } from "./config/i18next";
import { syncI18nCultures } from "./config/i18nextSync";
import { SpecificStyles } from "./styles/SpecificStyles";
import { useRoles } from "./hooks/useRoles";
import { getRouter } from "./Router";
import { NotificationsProvider } from "./context/notifications/NotificationsContext";

i18nextInit();
syncI18nCultures();

const App: React.FC = () => {
  const scrollRef = useRef(null);
  const { couldCreateReport, canSeeBriefs } = useRoles();
  const router = getRouter({ couldCreateReport, canSeeBriefs, scrollRef });

  return (
    <>
      <SpecificStyles />
      <GlobalStyles />
      <Suspense fallback={<LoadingIndicator size="L" />}>
        <NotificationsProvider>
          <AuthGate>
            <RouterProvider router={router} />
          </AuthGate>
        </NotificationsProvider>
      </Suspense>
    </>
  );
};

export default withStore(App);
