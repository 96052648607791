import React, { forwardRef, useEffect } from "react";
import { Box, Breakpoints, LoadingIndicator, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { getLocal } from "../../../../constants/generalSettings";
import { UserName } from "../UserName";
import { useUserData } from "../../../../hooks/useUserData";
import { useNavigate } from "react-router-dom";
import { ApprovedSections } from "../ApprovedSections";
import { useDraftSections } from "../../../../hooks/useDraftSections";
import { LoadingWrapper } from "../../../Settings/styled";
import { DraftStatus } from "./DraftStatus";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { selectDrafts, selectDraftsStatus } from "../../../../store/drafts/DraftsSelectors";
import { fetchDraftsThunk } from "../../../../store/drafts/DraftsThunkActions";
import { UserInfoText } from "../styled";
import { DraftReport, DraftsWrapper, Title } from "./DraftsList.styles";

type Ref = HTMLDivElement;

export const DraftsList = forwardRef<Ref>((_, ref) => {
  const { t, i18n } = useTranslation();
  const { userToken, lang } = useUserData();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectDraftsStatus);
  const draftReports = useAppSelector(selectDrafts);
  const { draftSections } = useDraftSections();
  const navigate = useNavigate();
  const isMobile = useHasMaxWidth(Breakpoints.XS);

  const handleSelect = (reportType, reportId) => {
    const to = {
      pathname: "/createReport",
      search: new URLSearchParams({ type: reportType, id: reportId }).toString(),
    };
    navigate(to);
  };

  useEffect(() => {
    if (userToken && lang) {
      dispatch(fetchDraftsThunk({ userToken }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, lang]);

  return (
      <Stack ref={ref} flex="1" mt={isMobile ? "S" : "0"}>
        {loading === "pending" && (
          <LoadingWrapper flex="1">
            <LoadingIndicator size="L" />
          </LoadingWrapper>
        )}
        {!!draftReports.length && loading === "fulfilled" && (
          <DraftsWrapper>
            <Box ml="S">
              <Text small color="secondary" uppercase>
                {t("Reports.draft.title")}
              </Text>
            </Box>
            <Stack flexWrap="wrap">
              {draftReports.map((report) => (
                <DraftReport key={report.report_id} onClick={() => handleSelect(report.report_type, report.report_id)}>
                  <Stack>
                    <Title title={report.title}>{report.title.length > 25 ? report.title.slice(0, 25) + "..." : report.title}</Title>
                    <ApprovedSections reportType={report.report_type} sections={draftSections[report.report_id]} />
                  </Stack>
                  <Stack direction="column">
                    <Text small color="secondary" uppercase>
                      {t("Reports.created")}
                    </Text>
                    <Stack>
                      <UserInfoText small data-testid="ReportDate">
                        {new Date(report.created).toLocaleString(i18n.language, { weekday: "short" }) + " "}{" "}
                        {format(new Date(report.created), "d MMM", { locale: getLocal(i18n.language) })}
                      </UserInfoText>
                      <UserInfoText small color="secondary">
                        {format(new Date(report.created), "HH:mm", { locale: getLocal(i18n.language) })}
                      </UserInfoText>
                      <UserName userId={report.last_updated_user} />
                    </Stack>
                  </Stack>
                  <DraftStatus reportType={report.report_type} sections={draftSections[report.report_id]} />
                </DraftReport>
              ))}
            </Stack>
          </DraftsWrapper>
        )}
      </Stack>
  );
});
