import { createApi } from "@reduxjs/toolkit/query/react";
import { Brief } from "../../models/BriefModel";
import { baseQuery } from "../../utilities/baseQuery";
import { FetchItemsMetaResponse, FetchItemsParams } from "../items/ItemsApi";

export const briefsApi = createApi({
  reducerPath: "briefsApi",
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getBriefs: builder.query<{ data: Brief[] } & FetchItemsMetaResponse, FetchItemsParams>({
      query: ({ page, limit = 20, filters }) => ({
        url: "briefs",
        params: {
          page,
          limit,
          ...filters,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetBriefsQuery,
} = briefsApi;
