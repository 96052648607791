import { createSelector } from "@reduxjs/toolkit";
import { type Reports, type FetchStatus } from "../../models/ReportModel";
import { type IRootState } from "../../store";

export const selectReports = ({ reports: { data } }: IRootState): Reports => data;

export const selectReportsStatus = ({ reports: { status } }: IRootState): FetchStatus => status;

export const selectNotSeenReportsCount = createSelector(
  ({
    reports: {
      data: { filteredReports },
    },
  }: IRootState) => filteredReports,
  (filteredReports) => filteredReports.filter(({ user_interaction }) => !user_interaction.viewed).length
);

export const selectPage = ({ reports: { page } }: IRootState): number => page;

export const selectShowMore = ({ reports: { showMore } }: IRootState): boolean => showMore;

export const selectReportUserInteractions = (id: string) => createSelector(
  ({ reports: { data: { filteredReports } } }: IRootState) => filteredReports,
  (filteredReports) => filteredReports.find((report) => report.report_id === id)?.user_interaction
);