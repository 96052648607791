import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { useEffect } from "react";
import { hasTokenExpired, hasTokenHalftimePassed } from "../utilities/TokenUtility";
import { useSearchParam } from "./useSearchParam";
import { useUserData } from "./useUserData";
import { setUserToken } from "../store/user/UserSlice";
import { useAppDispatch, useAppSelector } from "../store";
import { selectUserToken } from "../store/user/UserSelectors";

export const useAuth = () => {
  const { getIdTokenClaims, logout } = useAuth0();
  const { userToken:userTokenDeprecated, setUserTokenDeprecated, setUserId } = useUserData();
  const dispatch = useAppDispatch();
  const userToken = useAppSelector(selectUserToken);

  const t = useSearchParam("t");
  const token = useSearchParam("token");

  const refreshAccessToken = async (oldAccessToken: string) => {
    const endpoint = process.env.REACT_APP_AUTHENTICATION_ENDPOINT_REFRESH_BY_TOKEN;
    const url = new URL(endpoint);
    url.searchParams.append("refresh", "true");

    const response = await fetch(url.toString(), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${oldAccessToken}`,
      },
    });

    if (!response.ok) {
      console.log("Failed to refresh access token");
      return null;
    }

    const data = await response.json();
    const { token } = data;

    dispatch(setUserToken(token));
    setUserTokenDeprecated(token);
  };

  const fetchData = async () => {
    const claims = await getIdTokenClaims();

    const result = await fetch(process.env.REACT_APP_AUTHENTICATION_ENDPOINT, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${claims.__raw}`,
      },
    });

    const resultJson = await result.json();
    const { token } = resultJson;

    dispatch(setUserToken(token));
    setUserTokenDeprecated(token);
  };

  const fetchDataFromToken = async () => {
    const body = JSON.stringify({ token: t || token });

    try {
      const response = await fetch(process.env.REACT_APP_AUTHENTICATION_ENDPOINT_SIGN_BY_TOKEN, {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body,
        method: "POST",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const { token } = data;

      const decodedToken = jwt_decode<JwtPayload & { UserId: string }>(token);

      setUserId(decodedToken?.UserId);
      dispatch(setUserToken(token));
      setUserTokenDeprecated(token);
    } catch (error) {
      logout({ returnTo: window.location.origin });
      console.log(error);
    }
  };
  useEffect(() => {
    if (!userToken || !userTokenDeprecated) {
      (t || token ? fetchDataFromToken() : fetchData()).catch(console.error);
    } else if (hasTokenHalftimePassed(userToken) && !hasTokenExpired(userToken)) {
      refreshAccessToken(userToken);
    }

    // check the token every 30 minutes and refresh it if it will expire soon
    const interval = setInterval(() => {
      if (userToken && hasTokenHalftimePassed(userToken) && !hasTokenExpired(userToken)) {
        refreshAccessToken(userToken);
      }
    }, 30 * 60 * 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIdTokenClaims, logout, setUserId, setUserToken, t, token, userToken, userTokenDeprecated]);

  return userToken;
};
