import { useUserData } from "./useUserData";
import { fetchSectionsRequest } from "../utilities/reportUtils";
import { useStore } from "react-context-hook";
import { useEffect } from "react";
import { selectDrafts } from "../store/drafts/DraftsSelectors";
import { useAppSelector } from "../store";

export const useDraftSections = () => {
  const [draftSections, setDraftSections] = useStore("draftSections", {});
  const draftReports = useAppSelector(selectDrafts);
  const { userToken } = useUserData();

  const getDraftSections = async ({ reportId }) => {
    if(!userToken || !reportId) {
      return;
    }
    try {
      return await fetchSectionsRequest({ userToken, reportId }).then((value) => ({[reportId]: value}));
    } catch(error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    if(draftReports.length > 0) {
      Promise.all(
        draftReports.map(
          ({ report_id }) => getDraftSections({ reportId: report_id })
        )
      ).then(
        (values) => setDraftSections(
          values.reduce((acc, i) => ({...acc, [Object.keys(i)[0]]: i[Object.keys(i)[0]]}), {})
        )
      )
      .catch((error) => console.log(error))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftReports]);

  return { draftSections };
}