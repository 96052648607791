import { AsidePanel, Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components/macro";

export const ContactUsWrapper = styled.div`
  @media (max-width: ${Breakpoints.XS}) {
    width: 96px;
    margin-top: ${Spacing.S}px;
  }
`;

export const Container = styled.nav<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: 3;
  background-color: ${Palette.Navy800};
  justify-content: space-between;
  padding: ${Spacing.L}px ${Spacing.S}px 0px;
  width: ${({ isExpanded }) => (isExpanded ? AsidePanel.XL : AsidePanel.Collapsed)}px;
  padding: ${Spacing.L}px ${Spacing.M}px 0px;

  @media (max-width: ${Breakpoints.M}) {
    width: ${AsidePanel.Collapsed}px;
  }
  @media (max-width: ${Breakpoints.XS}) {
    display: block;
    height: 95px;
    padding: 0;
    width: 100%;
    z-index: 1;

    a {
      width: 96px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: ${Spacing.S}px;
    }
  }
`;

export const ContactUsStack = styled(Stack)`
  text-algin: center;
  margin: 0 auto;
`;
