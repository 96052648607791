import React, { FC, useEffect, useRef, useState } from "react";
import { Datepicker, Link, Overlay, Spacing, useClickOutside } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Content, Wrapper } from "./styled";
import { UTCDate } from "@date-fns/utc";

interface ScheduleTimeComponentItemProps {
  showCalendar: () => void;
  hideCalendar: () => void;
  isOpen: boolean;
  align?: "right" | "left";
  isDaySelection?: boolean;
  startDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  endDate: Date;
  lang: string;
}

export const ScheduleTimeComponentItem: FC<ScheduleTimeComponentItemProps> = ({
  showCalendar,
  hideCalendar,
  isOpen,
  align = "left",
  isDaySelection = false,
  startDate,
  setStartDate,
  setEndDate,
  endDate,
  lang,
}) => {
  const { t } = useTranslation();
  const elemRef = useRef<HTMLDivElement>(null);
  const [offsetTop, setOffsetTop] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(null);
  const [offsetRight, setOffsetRight] = useState(null);
  const { nodeRef } = useClickOutside<HTMLDivElement>(hideCalendar);

  useEffect(() => {
    if (isOpen) {
      const { bottom, left, right } = elemRef.current.getBoundingClientRect();
      setOffsetTop(Math.round(bottom + Spacing.XXS));
      if (align === "left") {
        setOffsetLeft(Math.round(left));
      } else {
        setOffsetRight(Math.round(window.innerWidth - right));
      }
    }
  }, [isOpen, align]);

  return (
    <Wrapper>
      <div ref={elemRef}>
        <Link onClick={showCalendar}>
          {t("NewReport.edit")}
        </Link>
      </div>
      {isOpen && (
        <Overlay>
          <Content ref={nodeRef} offsetTop={offsetTop} offsetLeft={offsetLeft} offsetRight={offsetRight}>
            <Datepicker
              mode="singleMonth"
              startDate={startDate}
              endDate={endDate}
              setStartDate={(date) => setStartDate(new UTCDate(date?.getFullYear(), date?.getMonth(), date?.getDate()))}
              isDaySelection={isDaySelection}
              setEndDate={(date) => setEndDate(new UTCDate(date?.getFullYear(), date?.getMonth(), date?.getDate()))}
              hideCalendar={() => {}}
              saveButtonLabel={t("Filter.date")}
              languageCode={lang}
            />
          </Content>
        </Overlay>
      )}
    </Wrapper>
  );
};
