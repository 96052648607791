import { withAuthenticationRequired } from "@auth0/auth0-react";
import i18next from "i18next";
import React, { createContext, useContext } from "react";
import { useAuth } from "../hooks/UseAuth";
import { useSearchParam } from "../hooks/useSearchParam";
import { useUserData } from "../hooks/useUserData";
interface IAuthContextValue {
  token: string;
}

const AuthContext = createContext({} as IAuthContextValue);

export const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider: React.FC = ({ children }) => {
  const token = useAuth();

  const value = React.useMemo(
    () => ({
      token,
    }),
    [token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthGate: React.FC = ({ children }) => {
  const t = useSearchParam("t");
  const token = useSearchParam("token");
  let WrappedComponent: React.ComponentType = AuthContextProvider;
  const { userToken } = useUserData();
  const location = window.location;
  const noAuthPaths = ["/health", "/login", "/logout", "/callback", "/signup", "/reset-password", "/forgot-password"];

  if (!userToken && !t && !token && !noAuthPaths.includes(location.pathname)) {
    WrappedComponent = withAuthenticationRequired(AuthContextProvider, {
      returnTo: () => {
        return location.pathname;
      },
      loginOptions: {
        language: i18next.language,
      },
    });
  }

  return <WrappedComponent>{children}</WrappedComponent>;
};

export default AuthGate;
