import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 40px;
  height: 100%;

  @media (max-width: ${Breakpoints.XL}) {
    padding: ${Spacing.M}px;
  }
  @media (max-width: ${Breakpoints.S}) {
    padding: ${Spacing.S}px;
  }
`;

export const TitleWrapper = styled(Stack).attrs({
  justifyContent: "space-between",
})`
  @media (max-width: ${Breakpoints.XS}) {
    flex-direction: column;
  }

  div[value="PLACEHOLDER"] {
    display: none;
  }
`;
