import { Spacing } from '@secuis/ccp-react-components';
import styled, { css, keyframes } from 'styled-components';

const toastInRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const ToastWrapper = styled.div<{ toBeAdded: boolean; toBeRemoved: boolean }>`
    ${(props) =>
        props.toBeAdded &&
        css`
            animation: ${toastInRight} 0.3s ease-in-out;
        `}

    ${(props) =>
        props.toBeRemoved &&
        css`
            opacity: 0;
        `}
`;

export const ToastContainer = styled.div`
    position: absolute;
    bottom: 48px;
    z-index: 999999;
    right: 0;
    overflow: hidden;
    max-width: 374px;
    width: 100%;
    white-space: break-spaces;
    padding: ${Spacing.XXS}px ${Spacing.XXS}px ${Spacing.XS}px;

    > * + * {
        margin-top: ${Spacing.XS}px;
    }
`;
