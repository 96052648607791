import { Box, Stack, Text } from "@secuis/ccp-react-components";
import React, { FC, useCallback } from "react";
import { useTags } from "../../../../hooks/useTags";
import { useTagsAndRegions } from "../../../../hooks/useTagsAndRegions";
import { getTranslatedTag } from "../../../../utilities/tagsUtils";
import { TagChip } from "./TagChip";

interface TabComponentProps {
  region: string;
  initIndex?: number;
  section?: string;
}

export const TabComponent: FC<TabComponentProps> = ({ region, initIndex = 0, section }) => {
  const { tags, setTags, allTagsCategories } = useTags();
  const { tagNames } = useTagsAndRegions();

  const getLeftCategories = (foundCategories, leftCategories, elem) => {
    const checker = section === "main" ? allTagsCategories - 1 : leftCategories.length;
    return checker === 0 ? foundCategories : foundCategories.filter((cat) => cat !== elem);
  };

  const selectChanged = useCallback(
    (elem, selected) => {
      let foundCategories = tags.find((elem) => elem.region === region).categories;
      if (selected) {
        foundCategories.push(elem);
      } else {
        const leftCategories = foundCategories.filter((cat) => cat !== elem);
        tags.find((elem) => elem.region === region).categories = getLeftCategories(foundCategories, leftCategories, elem);
      }
      setTags([...tags]);
    },
    [tags, region]
  );

  return (
    <Box p={initIndex >= 0 ? "0" : "M"}>
      <Box pb="S">
        <Text>{initIndex === -1 ? getTranslatedTag(region, "region") : ""} </Text>
      </Box>
      <Stack flexWrap="wrap" gap="XS">
        {tagNames.map((elem) => (
          <TagChip
            elem={elem}
            key={`elem-${region}-${elem}`}
            init={tags.find((elem) => elem.region === region)?.categories.includes(elem)}
            selectChanged={selectChanged}
          />
        ))}
      </Stack>
    </Box>
  );
};
