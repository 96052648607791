import { Stack } from "@secuis/ccp-react-components";
import React, { FC } from "react";
import { useAppSelector } from "../../../store";
import { selectMetadata } from "../../../store/items/ItemsSelectors";
import { ItemTagChip } from "./ItemTagChip";

interface ItemTagsComponentProps {
  setTagsList: (tagsList: string[]) => void;
  tagsList: string[];
}

export const ItemTagsComponent: FC<ItemTagsComponentProps> = ({ setTagsList, tagsList }) => {
  const { threat_risk_categories } = useAppSelector(selectMetadata);

  const selectChanged = (elem, selected) => {
    if (selected) {
      setTagsList([...tagsList, elem]);
    } else {
      const leftCategories = tagsList.filter((cat) => cat !== elem);
      setTagsList(leftCategories);
    }
  };

  return (
    <Stack flexWrap="wrap" gap="XS">
      {threat_risk_categories.map(({ name }) => (
        <ItemTagChip key={name} elem={name} init={tagsList.includes(name)} selectChanged={selectChanged} />
      ))}
    </Stack>
  );
};
