import { Item } from "../../../models/ItemModel";

export const ITEMS_SOURCE = "items";

export const LOCATION_MARKER = "locationMarker";

export const LOCATION_MARKER_ACTIVE = "locationMarkerActive";

export const MARKERS_LAYER = "markers";

export const LATITUDE_REGEX = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:\d|[1-8]\d)(?:(?:\.\d{1,6})?))$/;

export const LONGITUDE_REGEX = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:\d|[1-9]\d|1[0-7]\d)(?:(?:\.\d{1,6})?))$/;

export const mapToGeoJson = (items: Item[]) =>
  items.map((properties) => ({
    type: "Feature",
    id: properties.id,
    properties,
    geometry: {
      type: "Point",
      coordinates: [properties.geolocation.longitude, properties.geolocation.latitude],
    },
  }));
