import { Box, Button, Stack, Text, Toggle } from "@secuis/ccp-react-components";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useUserData } from "../../../hooks/useUserData";
import { Item } from "../../../models/ItemModel";
import { useAppDispatch } from "../../../store";
import { itemsActions } from "../../../store/items/ItemsSlice";
import { useUser } from "../../../store/user/UserHooks";
import { getLocalDate } from "../../../utilities/dateUtils";
import { Wrapper } from "./CreateItemHeader.styles";
import { DataDetailsComponentItemRemind } from "./DataDetailsComponentItemRemind";
import { EventDatePicker } from "./EventDatePicker";

interface CreateItemHeaderProps {
  fetchedItem: Item;
}

const CreateItemHeader: React.FC<CreateItemHeaderProps> = ({ fetchedItem }) => {
  const { itemId } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { userData } = useUserData();
  const {
    getValues,
    control,
    formState: { isDirty: isFormDirty },
  } = useFormContext();
  const [author, setAuthor] = useState("-");
  const { userName: addedByName } = useUser(fetchedItem?.added_by);
  const { userName: lastUpdatedByName } = useUser(getValues("last_updated_by"));
  const { userName: reviewedByName } = useUser(getValues("reviewed_by"));
  const dateReviewed = getValues("date_reviewed");

  const onPreview = () => {
    const data = getValues();
    const geolocation = { latitude: data.geolocation.latitude, longitude: data.geolocation.longitude }; // geolocation is a nested property on the item but not on the data, therefore we need to build it, to get the most recent changes for preview
    const item = { ...fetchedItem, ...data, geolocation } as unknown as Item;
    dispatch(itemsActions.setPreview({ item, itemId }));
  };

  useEffect(() => {
    if (userData) {
      setAuthor(userData.name);
    }
  }, [userData]);

  return (
    <Wrapper>
      <div>
        <Text uppercase micro color="secondary">
          {t("Items.dateOfEvent")}
        </Text>
        <EventDatePicker />
      </div>
      <div>
        <Text uppercase micro color="secondary">
          {t("Items.itemCreated")}
        </Text>
        <Box mt="XXS">
          <Text bold>{getValues("date_added") ? getLocalDate(new Date(getValues("date_added")), i18n.language) : "-"}</Text>
        </Box>
      </div>
      <div>
        <Text uppercase micro color="secondary">
          {t("Items.author")}
        </Text>
        <Box mt="XXS">
          <Text bold>{itemId ? addedByName : author}</Text>
        </Box>
      </div>
      <div />
      <div>
        <Button mode="contained" onClick={onPreview} disabled={!itemId || isFormDirty}>
          {t("Items.preview")}
        </Button>
      </div>
      <div>
        <Text uppercase micro color="secondary">
          {t("Items.recurrentEvent")}
        </Text>
        <Stack alignItems="center" gap="XS" mt="XXS">
          <Text bold>{t("Items.recurrence")}</Text>
          <Controller
            control={control}
            name="recurrence"
            defaultValue=""
            render={({ field }) => <Toggle id="setRecurrence" checked={field.value} onChange={() => field.onChange(!field.value)} />}
          />
        </Stack>
      </div>
      <div>
        <Text uppercase micro color="secondary">
          {t("Items.remindMe")}
        </Text>
        <Controller
          control={control}
          name="set_reminder"
          render={({ field }) => <DataDetailsComponentItemRemind remindDate={field.value} setRemindDate={field.onChange} />}
        />
      </div>
      <div>
        <Text uppercase micro color="secondary">
          {t("Items.lastEdit")}
        </Text>
        {getValues("date_last_updated") && <Text bold>{getLocalDate(getValues("date_last_updated"), i18n.language)}</Text>}
        <Text bold>{lastUpdatedByName}</Text>
      </div>
      <div>
        <Text uppercase micro color="secondary">
          {t("Items.itemApprover")}
        </Text>
        {dateReviewed && <Text bold>{getLocalDate(dateReviewed, i18n.language)}</Text>}
        <Text bold>{reviewedByName}</Text>
      </div>
    </Wrapper>
  );
};

export default CreateItemHeader;
